import React, { useEffect } from 'react'
import {  useHistory } from "react-router-dom";

// APi
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
import Loader from '../../components/loader/Loader';

function Kreturn() {
    let history = useHistory();
    var url_string = window.location.href; 
    var url = new URL(url_string);
    var order_id = url.searchParams.get("order_id");
    var order_token = url.searchParams.get("order_token");
    useEffect(() => {

        Dataservices.KundaliResponse(queryString.stringify({ order_id, order_token })).then(async (res)=>{
          // console.log(res.data)
        
            if(res.data.status==="Paid")
            {
               history.push(`/view/kundali/${res.data._id}`);
            }
            else{
              history.push({pathname:'/',state:{orderDetail:true,name:"Oops! Something went wrong.",status:"fail",order_id:res.data._id}});
            }
          })
    }, )

    return (
      <Loader show={true} />
    )
}

export default Kreturn