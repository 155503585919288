import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Core components
import PoojaCards from "../../components/Cards/PoojaCards";
import SliderWrapper from "../../components/Slider";

const Epuja = ({ data, slider,title }) => {
  // const [session,setSession]=useState([]);
  const [epooja,setEpooja]=useState([]);

 
  useEffect(() => {
    console.log('e-pooja',data)
    if(data.length)
    {
      let epoojaData=[];
      data.forEach(item => {
        if(item.epooja )
        {
          epoojaData.push(item);
        }
      });
      if(epoojaData.length)
      {
        setEpooja(epoojaData);
      }
    }
  
  }, [data])
  
  return(
  <div className="all_product" style={{backgroundColor:'#f0f0f07d'}}>
    <Container>
      {/* //Seasonal pooja slider  */}
     
{/* all E-pooja */}
      <Row> 

        <Col lg="12">
        </Col>
        {slider ? (
          <Col lg="12">
            <SliderWrapper
              type="single"
              slides={4}
              row={1}
              spacebetween={30}
              colors="#c74a2d"
              mobile="2"
              tablet="2"
              desktop="4"
            >
              {epooja&&epooja.map((item) => (
                <SwiperSlide key={item._id}>
                  <PoojaCards data={item} />
                </SwiperSlide>
              ))}
            </SliderWrapper>
          </Col>
        ) : (
          <>
            {epooja.map((item) => (
              <Col lg="3" key={item._id}>
                <PoojaCards data={item} />
              </Col>
            ))}
          </>
        )}
      </Row>
      {/* ALL POOJA Slider */}
      {/*  */}
         {(window.location.pathname === '/')?(<div className="d-flex justify-content-center">
          <Link className="btn btn-amber btn-rounded px-4 text-white mb-2 mx-md-2 mb-md-0 nav-link d-inline-block" 
                    to="/all-pooja"
                    style={{fontWeight:'500'}}
                  >
                View all Our Pooja
         </Link>
      </div>):''}
    </Container>
  </div>)
};
export default Epuja;
