import React from "react";
import { saveAs } from 'file-saver';
import "../assets/scss/vps.css"
import { Row } from "reactstrap";
import { BASEURL } from "../services/http-common";
// import Dataservices from "../services/requestApi";

const VPS = (props) => {
    // const [pdf, setAllPDF] = useState([])
    // const getAllPDF = useCallback(async (e) => {
    //     try {
    //       const res = await Dataservices.getAllPdf();
    //       console.log(res.data.data);
    //       setAllPDF(res.data.data);
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }, []);
    //   useEffect(() => {
    //     getAllPDF();
    //   }, [getAllPDF]);
    return (
        <>
        <div className="container-fluid">
            {/* <Row>
                <Col xl="12">
                    <div className="heading">
                        <h1>
                            {props.title ? (
                                <>
                                    <span>{props.title}</span>
                                </>
                            ) : (
                                <>
                                    <span>Sanatan Dharama</span>
                                </>
                            )}
                        </h1>
                    </div>
                </Col>
            </Row> */}
            <Row>
              <div className="col-xl-12">
                  <div className="card">
                        <div className="card-body">
                            {/* table */}
                            <div className="table-responsive" style={{display: "flex",
                    justifyContent: "center"}}>
                                <table className="table table-hover border text-center">
                                    <thead>
                                    <tr>
                                        <th> S.No</th>
                                        <th> Type</th>
                                        <th> {props.title} Pdf</th>
                                        <th> Downlod/view</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                        {props.data.map((item, index) => {
                            return (
                                <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.type}</td>
                                <td>{item.pdf_name}</td>
                                <td>
                                    <button className="btn btn-success p-1"
                                     onClick={(e) => saveAs(`${BASEURL.ENDPOINT_URL}public${item.pdf.slice(25)}`,`${item.pdf_name}`) }
                                    >Download</button>
                                    <a
                                    href={`${BASEURL.ENDPOINT_URL}public${item.pdf.slice(25)}`}
                                    target="_blank"
                                    className="m-2"
                                    rel="noreferrer"
                                    >
                                    <button className="btn btn-primary p-1 mt-2 mt-md-0">View</button>
                                    </a>
                                </td>
                                </tr>
                            );
                        })}
                        </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
              </div>

                {/* <Col xl="12">
                    <div className="card ">
                <div className="table"style={{
                    display: "flex",
                    justifyContent: "center"
                    }}
                 >
                    <div className="table-responsive">
                    <table>
                        <thead className="tablehead">
                        <tr>
                            <th> S.No</th>
                            <th> Name</th>
                            <th> {props.title} Pdf</th>
                            <th> Downlod/view</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.data.map((item, index) => {
                            return (
                                <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.pdf}</td>
                                <td>
                                    <button className="btn btn-success p-1">Download</button>
                                    <a
                                    href={item.pdf}
                                    target="_blank"
                                    className="m-2"
                                    rel="noreferrer"
                                    >
                                    <button className="btn btn-danger p-1">View</button>
                                    </a>
                                </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
                </Col> */}
            </Row>
        </div>
        </>
    );
}

export default VPS;