import React, { useEffect, useState } from "react";
import Headimage from "../../components/HeadImage/Headimage";
import WrapContainer from "../../components/container";
import HeaderBreadcrumb from "../../components/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import DataService from "../../services/requestApi";
import queryString from "query-string";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Radio } from "antd";
import { ToastContainer, toast } from 'react-toastify';
// import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import "./Kundali.css"
import Loader from "../../components/loader";
import jsPDF from 'jspdf'
import Logo from "../../assets/img/logo.png";
import moment from 'moment';
import SVG from 'react-inlinesvg';
import { PDFDownloadLink } from "@react-pdf/renderer";
import Interweave from 'interweave';
import GetKundali from "./GetKundali";
import whatsappLogo from '../../assets/img/whatsapp.png';




const ViewKundali = () => {

    let { id } = useParams();
    let history = useHistory();

    const [kundali, setKundali] = useState("");

    const getKundali = async () => {
        const response = await DataService.getKundali(id);
        if (response.data.resCode === 200) {
            let data = response.data.data;
            data["kundali"] = JSON.parse(data["kundali"]);
            setKundali(data);
        }
        else {
            history.goBack();
        }
    }
    const generatePDF = async () => {
        var doc = new jsPDF('p', 'pt', 'a4');
        doc.html(document.querySelector("#kundaliCard"), {
            callback: function (pdf) {
                pdf.save('kundali.pdf');
            }
        });
    }
    useEffect(() => {
        getKundali();
    }, [])
    useEffect(() => {
        console.log('check kundali', kundali);
    }, [kundali])

    const showDegree = (degree) => {
        let castDegree = degree.toString();
        let [ degree1, degree2 ] = castDegree.split(".");

        return degree1+'°'+degree2.slice(0, 4)
    }


    return (
        kundali ? <>

            <WrapContainer>
                <ToastContainer />

                <HeaderBreadcrumb title="kundali" />
                <Headimage title="Kundali" />
               {/* <div className="container-fluid desc ">
                    <div className="card im">
                        <div className="card-body">
                            <p>
                                Pujya Pandit G Kundali | Janam Kundali Online - जन्म कुंडली
                                Janam Kundli also known as Janampatri or Birth chart or Kundli
                                Chart or Natal Chart, depicts an individual’s life based on the
                                12 houses. Kundali, Vedic charts, graphical representation of
                                positions of houses and planets are prepared based on the date,
                                time and place of birth of an individual.
                            </p>
                        </div>
                    </div>
                </div>*/}
                <div className="container-fluid container" >
                    <div className="row" id="">
                       {/* <button type="button" name="" id="" className="btn btn-primary btn-lg btn-block" onClick={generatePDF}>Generate pdf</button> */}
                        <div className="card shadow border-0 my-4 w-100">
                            
                            <div className="card-body mx-4" id="kundaliCard">
                                <div className="row border">
                                    <div className="col-12 d-flex align-items-center kundaliCard-header">
                                        <img
                                            src={Logo}
                                            style={{
                                                width: "200px",
                                                top: "5px",
                                                zIndex: "9"
                                            }}
                                            alt="Logo"
                                        />
                            <p class="kundali_title" style={{"margin":"auto","marginRight":"36%","textAlign":"center","fontWeight":"500","fontSize":"50px","color":"#fff","fontFamily":"'Alegreya Sans', sans-serif"}}>Vedic Kundali </p>
                                    </div>
                                    <div className="col-12">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Main Details/मुख्य विवरण </caption>

                                            <tr>
                                                <td colSpan={2}><span className="text-secondary">Name: </span> <span>{kundali.contact_name}</span></td>
                                                {kundali.kundali.advancedPanchang&&<td><span className="text-secondary">Ritu : </span> <span>{kundali.kundali.advancedPanchang.ritu}</span></td>}
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Birth Date: </span> <span>{moment(kundali.Date_of_Birth).format('D/MM/YYYY')}</span></td>
                                                <td><span className="text-secondary">Birth Time: </span> <span>{kundali.Time_of_Birth}</span></td>
                                                <td><span className="text-secondary">Birth Place: </span> <span>{kundali.BirthPlace}</span></td>
                                            </tr>
                                            {kundali.kundali.birthDetails!=null&&<tr>
                                                <td><span className="text-secondary">Latitude: </span> <span>{showDegree(kundali.kundali.birthDetails.latitude)}</span></td>
                                                <td><span className="text-secondary">Longitude: </span> <span>{showDegree(kundali.kundali.birthDetails.longitude)}</span></td>
                                                <td><span className="text-secondary">TimeZone: </span> <span>{kundali.kundali.birthDetails.timezone}</span></td>
                                            </tr>}
                                            
                                            {kundali.kundali.birthDetails!=null&& kundali.kundali.advancedPanchang&&<tr>
                                                <td ><span className="text-secondary">Ayanamsha Degree: </span> <span>{showDegree(kundali.kundali.birthDetails.ayanamsha)}</span></td>
                                                <td ><span className="text-secondary">Day: </span> <span>{kundali.kundali.advancedPanchang.day}</span></td>
                                                <td ><span className="text-secondary">Paksha: </span> <span>{kundali.kundali.advancedPanchang.paksha}</span></td>
                                            </tr>}
                                          
                                            {kundali.kundali.advancedPanchang&&<tr>
                                                <td><span className="text-secondary">Tithi Name: </span> <span>{kundali.kundali.advancedPanchang.tithi.details.tithi_name}</span></td>
                                                <td><span className="text-secondary">Tithi Number: </span> <span>{kundali.kundali.advancedPanchang.tithi.details.tithi_number}</span></td>
                                                <td><span className="text-secondary">Special Tithi: </span> <span>{kundali.kundali.advancedPanchang.tithi.details.special}</span></td>
                                            </tr>}
                                           {kundali.kundali.advancedPanchang&&<tr>
                                               <td><span className="text-secondary">Vikram Samvat: </span> <span>{kundali.kundali.advancedPanchang.vikram_samvat}</span></td>
                                                <td><span className="text-secondary">Vikram Samvat Name: </span> <span>{kundali.kundali.advancedPanchang.vkram_samvat_name}</span></td>
                        
                                            </tr>}*
                                            {kundali.kundali.advancedPanchang&&<tr>
                                                <td><span className="text-secondary">Tithi End Time(HH:MM:SS): </span> <span>{kundali.kundali.advancedPanchang.tithi.end_time.hour+':'+kundali.kundali.advancedPanchang.tithi.end_time.minute+':'+kundali.kundali.advancedPanchang.tithi.end_time.second}</span></td>
                                                <td><span className="text-secondary">Hindu Maah (Adhik Status): </span> <span>{kundali.kundali.advancedPanchang.hindu_maah.adhik_status?'Yes':'No'}</span></td>
                                                <td><span className="text-secondary">Hindu Maah (Purnimanta): </span> <span>{kundali.kundali.advancedPanchang.hindu_maah.purnimanta}</span></td>
                                            </tr>}
                                            {kundali.kundali.advancedPanchang&&<tr>
                                                <td colSpan={3}><span className="text-secondary">Hindu Maah (Amanta): </span> <span>{kundali.kundali.advancedPanchang.hindu_maah.amanta}</span></td>
                                            </tr>}          
                                            
                                        </table>
                                    </div>
                                    {kundali.kundali.astroDetails!=null&&<div className="col-12">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Astro Details/अवकहड़ा चक्र </caption>
                                            <tr>
                                                <td colSpan={3}>
                                                    <span className="text-secondary">This section provides your basic astrological details along with panchang descriptions at the time of your birth.</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Ascendant: </span> <span>{kundali.kundali.astroDetails.ascendant}</span></td>
                                                <td><span className="text-secondary">Sign: </span> <span>{kundali.kundali.astroDetails.sign}</span></td>
                                                <td><span className="text-secondary">Sign Lord: </span> <span>{kundali.kundali.astroDetails.SignLord}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Naksahtra: </span> <span>{kundali.kundali.astroDetails.Naksahtra}</span></td>
                                                <td><span className="text-secondary">Naksahtra Lord: </span> <span>{kundali.kundali.astroDetails.NaksahtraLord}</span></td>
                                                <td><span className="text-secondary">Charan: </span> <span>{kundali.kundali.astroDetails.Charan}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Karan: </span> <span>{kundali.kundali.astroDetails.Karan}</span></td>
                                                <td><span className="text-secondary">Yog: </span> <span>{kundali.kundali.astroDetails.Yog}</span></td>
                                                <td><span className="text-secondary">Varna: </span> <span>{kundali.kundali.astroDetails.Varna}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Vashya: </span> <span>{kundali.kundali.astroDetails.Vashya}</span></td>
                                                <td><span className="text-secondary">Yoni: </span> <span>{kundali.kundali.astroDetails.Yoni}</span></td>
                                                <td><span className="text-secondary">Gan: </span> <span>{kundali.kundali.astroDetails.Gan}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Nadi: </span> <span>{kundali.kundali.astroDetails.Nadi}</span></td>
                                                <td><span className="text-secondary">Yunja: </span> <span>{kundali.kundali.astroDetails.yunja}</span></td>
                                                <td><span className="text-secondary">Tatva: </span> <span>{kundali.kundali.astroDetails.tatva}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Name Alphabet: </span> <span>{kundali.kundali.astroDetails.name_alphabet}</span></td>
                                                <td colSpan={2}><span className="text-secondary">Paya (Nak Based): </span> <span>{kundali.kundali.astroDetails.paya}</span></td>
                                            </tr>
                                        </table>
                                    </div>}
                                    {kundali.kundali.ghatChakra!=null&&<div className="col-12">
                                        <div className="table-responsive">
                                            <table className="my-3" >
                                                <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Ghat chakra/घात चक्र</caption>
                                                <tr>
                                                    <td><span className="text-secondary">Day: </span> <span>{kundali.kundali.ghatChakra.day}</span></td>
                                                    <td><span className="text-secondary">Karan: </span> <span>{kundali.kundali.ghatChakra.karan}</span></td>
                                                    <td><span className="text-secondary">Month: </span> <span>{kundali.kundali.ghatChakra.month}</span></td>
                                                    <td><span className="text-secondary">Moon: </span> <span>{kundali.kundali.ghatChakra.moon}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className="text-secondary">Nakshatra: </span> <span>{kundali.kundali.ghatChakra.nakshatra}</span></td>
                                                    <td><span className="text-secondary">Pahar: </span> <span>{kundali.kundali.ghatChakra.pahar}</span></td>
                                                    <td><span className="text-secondary">Tithi: </span> <span>{kundali.kundali.ghatChakra.tithi}</span></td>
                                                    <td><span className="text-secondary">Yog: </span> <span>{kundali.kundali.ghatChakra.yog}</span></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>}
                                        {kundali.kundali.horoChartImageD1!=null&&kundali.kundali.horoChartImageD9!=null&&<div className="col-12">
                                    <div className="table-responsive">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Kundali Chart/कुंडली </caption>
                                                <tr id="firstChart"> 
                                                <div className="row p-3">
                                                    <div className="col-lg-6">
                                                        <span className="text-secondary d-block pl-2">लग्न कुण्डली: </span>
                                                        <SVG src={kundali.kundali.horoChartImageD1.svg.replace('\'', "")} />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <span className="text-secondary d-block pl-2">नवमांश कुण्डली: </span>
                                                        <SVG src={kundali.kundali.horoChartImageD9.svg.replace('\'', "")} />
                                                    </div>
                                                </div>
                                                </tr>
                                        </table>
                                    </div>
                                    </div>}
                                     {kundali.kundali.horoChartD1!=null&&<div className="col-12">
                                    <div className="table-responsive">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Planets Report 1/ग्रह विवेचन 1</caption>
                                            {kundali.kundali.horoChartD1.map((chart)=>(
                                                <tr>
                                                    <td><span className="text-secondary">Sign Name: </span> <span>{chart.sign_name}</span></td>
                                                    <td><span className="text-secondary">Sign: </span> <span>{chart.sign}</span></td>
                                                    <td><span className="text-secondary">Planet: </span> <span>{chart.planet.join()}</span></td>
                                                    <td><span className="text-secondary">Planet Small: </span> <span>{chart.planet_small.join()}</span></td>
                                                    {/*<td><span className="text-secondary">Planet Degree: </span> <span>{chart.planet_degree.join()}</span></td>*/}
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                    </div>}
                                    {kundali.kundali.horoChartD9!=null&&<div className="col-12">
                                    <div className="table-responsive">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Planet Report 2/ग्रह विवेचन 2</caption>
                                            {kundali.kundali.horoChartD9.map((chart)=>(
                                                <tr>
                                                    <td><span className="text-secondary">Sign Name: </span> <span>{chart.sign_name}</span></td>
                                                    <td><span className="text-secondary">Sign: </span> <span>{chart.sign}</span></td>
                                                    <td><span className="text-secondary">Planet: </span> <span>{chart.planet.join()}</span></td>
                                                    <td><span className="text-secondary">Planet Small: </span> <span>{chart.planet_small.join()}</span></td>
                                                   {/* <td><span className="text-secondary">Planet Degree: </span> <span>{chart.planet_degree.join()}</span></td>*/}
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                    </div>}
                                
                                    {kundali.kundali.currentVdasha!=null&&<div className="col-12">
                                    <div className="table-responsive">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Current Vdasha/वर्तमान विंशोतरी दशा</caption>
                                            <tr>
                                                <th>Major</th>
                                                <td><span className="text-secondary">Start: </span> <span>{kundali.kundali.currentVdasha.major.start}</span></td>
                                                <td><span className="text-secondary">End: </span> <span>{kundali.kundali.currentVdasha.major.end}</span></td>
                                                <td><span className="text-secondary">Planet: </span> <span>{kundali.kundali.currentVdasha.major.planet}</span></td>
                                                <td><span className="text-secondary">Planet Id: </span> <span>{kundali.kundali.currentVdasha.major.planet_id}</span></td>
                                                
                                            </tr>
                                            <tr>
                                                <th>Minor</th>
                                                <td><span className="text-secondary">Start: </span> <span>{kundali.kundali.currentVdasha.minor.start}</span></td>
                                                <td><span className="text-secondary">End: </span> <span>{kundali.kundali.currentVdasha.minor.end}</span></td>
                                                <td><span className="text-secondary">Planet: </span> <span>{kundali.kundali.currentVdasha.minor.planet}</span></td>
                                                <td><span className="text-secondary">Planet Id: </span> <span>{kundali.kundali.currentVdasha.minor.planet_id}</span></td>
                                                
                                            </tr>
                                            <tr>
                                                <th>Sub Minor</th>
                                                <td><span className="text-secondary">Start: </span> <span>{kundali.kundali.currentVdasha.sub_minor.start}</span></td>
                                                <td><span className="text-secondary">End: </span> <span>{kundali.kundali.currentVdasha.sub_minor.end}</span></td>
                                                <td><span className="text-secondary">Planet: </span> <span>{kundali.kundali.currentVdasha.sub_minor.planet}</span></td>
                                                <td><span className="text-secondary">Planet Id: </span> <span>{kundali.kundali.currentVdasha.sub_minor.planet_id}</span></td>
                                                
                                            </tr>
                                            <tr>
                                                <th>Sub Sub Minor</th>
                                                <td><span className="text-secondary">Start: </span> <span>{kundali.kundali.currentVdasha.sub_sub_minor.start}</span></td>
                                                <td><span className="text-secondary">End: </span> <span>{kundali.kundali.currentVdasha.sub_sub_minor.end}</span></td>
                                                <td><span className="text-secondary">Planet: </span> <span>{kundali.kundali.currentVdasha.sub_sub_minor.planet}</span></td>
                                                <td><span className="text-secondary">Planet Id: </span> <span>{kundali.kundali.currentVdasha.sub_sub_minor.planet_id}</span></td>
                                                
                                            </tr>
                                            <tr>
                                                <th>Sub Sub Sub Minor</th>
                                                 <td><span className="text-secondary">Start: </span> <span>{kundali.kundali.currentVdasha.sub_sub_sub_minor.start}</span></td>
                                                <td><span className="text-secondary">End: </span> <span>{kundali.kundali.currentVdasha.sub_sub_sub_minor.end}</span></td>
                                                <td><span className="text-secondary">Planet: </span> <span>{kundali.kundali.currentVdasha.sub_sub_sub_minor.planet}</span></td>
                                                <td><span className="text-secondary">Planet Id: </span> <span>{kundali.kundali.currentVdasha.sub_sub_sub_minor.planet_id}</span></td>
                                               
                                            </tr>
                                            
                                        </table>
                                    </div>
                                    </div>}
                                    {kundali.kundali.majorVdasha!=null&&<div className="col-12">
                                    <div className="table-responsive">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Major Vdasha/ विंशोतरी महादशा</caption>
                                            {kundali.kundali.majorVdasha.map((Vdasha,key)=>(
                                            <tr key={key} >
                                                 <td><span className="text-secondary">Planet: </span> <span>{Vdasha.planet}</span></td>
                                                <td><span className="text-secondary">Start: </span> <span>{Vdasha.start}</span></td>
                                                <td><span className="text-secondary">End: </span> <span>{Vdasha.end}</span></td>
                                            </tr>))}
                            
                                        </table>
                                    </div>
                                    </div>}
                                    
                                     {kundali.kundali.planets!=null&&<div className="col-12">
                                        <div className="table-responsive">
                                            <table className="my-3" >
                                                <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Planetary Position/ग्रह स्थति</caption>
                                                {kundali.kundali.planets.map((planet)=>(
                                                    <tr>
                                                        <td><span className="text-secondary">Name: </span> <span>{planet.name}</span></td>
                                                        <td><span className="text-secondary">Nakshatra: </span> <span>{planet.nakshatra}</span></td>
                                                        <td><span className="text-secondary">Nakshatra Lord: </span> <span>{planet.nakshatraLord}</span></td>
                                                        <td><span className="text-secondary">Nakshatra Pad: </span> <span>{planet.nakshatra_pad}</span></td>
                                                        <td><span className="text-secondary">House: </span> <span>{planet.house}</span></td>
                                                        <td><span className="text-secondary">IsRetro: </span> <span>{planet.isRetro=='true'?'Yes':'No'}</span></td>
                                                        <td><span className="text-secondary">is Planet Set: </span> <span>{planet.is_planet_set?'Yes':'No'}</span></td>
                                                        <td><span className="text-secondary">NormDegree: </span> <span>{planet.normDegree.toFixed(4)}</span></td>
                                                        <td><span className="text-secondary">FullDegree: </span> <span>{planet.fullDegree.toFixed(4)}</span></td>
                                                        <td><span className="text-secondary">Planet Awastha: </span> <span>{planet.planet_awastha}</span></td>
                                                        <td><span className="text-secondary">Sign: </span> <span>{planet.sign}</span></td>
                                                        <td><span className="text-secondary">SignLord: </span> <span>{planet.signLord}</span></td>
                                                        <td><span className="text-secondary">Speed: </span> <span>{planet.speed}</span></td>
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    </div>}
                                    {kundali.kundali.manglik!=null&&<div className="col-12">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Manglik Details/मंगल दोष</caption>
                                                <tr>
                                                    <td><span className="text-secondary">Is Present: </span> <span>{kundali.kundali.manglik.is_present?'Yes':'No'}</span></td>
                                                    <td><span className="text-secondary">Manglik Status: </span> <span>{kundali.kundali.manglik.manglik_status}</span></td>
                                                    <td><span className="text-secondary"> Is Mars Manglik Cancelled: </span> <span>{kundali.kundali.manglik.is_mars_manglik_cancelled?'Yes':'No'}</span></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}><span className="text-secondary">Manglik Report: </span> <span>{kundali.kundali.manglik.manglik_report}</span></td>
                                                </tr>
                                                <tr>
                                                    <td><span className="text-secondary">Percentage Manglik After Cancellation: </span> <span>{kundali.kundali.manglik.percentage_manglik_after_cancellation}</span></td>
                                                    <td colSpan={2}><span className="text-secondary">Percentage Manglik Present: </span> <span>{kundali.kundali.manglik.percentage_manglik_present}</span></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <span className="text-secondary">Manglik Present Rule: </span>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <span>Based On Aspect</span>
                                                                <ul>
                                                                    {kundali.kundali.manglik.manglik_present_rule.based_on_aspect.map((item)=>(
                                                                        <li>{item}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <span>Based On House</span>
                                                                <ul>
                                                                    {kundali.kundali.manglik.manglik_present_rule.based_on_house.map((item)=>(
                                                                        <li>{item}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                             <span className="text-secondary">Manglik Cancel Rule: </span>
                                                            <ul>
                                                                {kundali.kundali.manglik.manglik_cancel_rule.map((item)=>(
                                                                    <li>{item}</li>
                                                                ))}
                                                            </ul>
                                                    </td>
                                                </tr>
                                        </table>
                                    </div>}
                                    {kundali.kundali.kalsarpaDetails!=null&&<div className="col-12">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Kalsarpa Details/कालसर्प दोष</caption>
                                                {kundali.kundali.kalsarpaDetails.present==false?<tr><td>{kundali.kundali.kalsarpaDetails.one_line}</td></tr>:
                                                <><tr>
                                                    <td><span className="text-secondary">Name: </span> <span>{kundali.kundali.kalsarpaDetails.name}</span></td>
                                                    <td><span className="text-secondary">Type: </span> <span>{kundali.kundali.kalsarpaDetails.type}</span></td>
                                                   
                                                    <td><span className="text-secondary">House Id: </span> <span>{kundali.kundali.kalsarpaDetails.report.house_id}</span></td>
                                                </tr>
                                                <tr>
                                                 <td colSpan={3}><span className="text-secondary">Short Desc: </span> <span>{kundali.kundali.kalsarpaDetails.one_line}</span></td></tr>
                                                <tr><td colSpan={3}><span className="text-secondary">Report: </span> <span>
                                         <Interweave content={kundali.kundali.kalsarpaDetails.report.report} />
                                                </span></td></tr>
                                                </>}
                                        </table>
                                    </div>}
                                    {kundali.kundali.pitraDoshaReport!=null&&<div className="col-12">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Pitra Dosha Report/पितृ दोष </caption>
                                            <tr>
                                                <td colSpan={2}><span className="text-secondary">What is Pitra Dosha: </span> <span>{kundali.kundali.pitraDoshaReport.what_is_pitri_dosha}</span></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}><span className="text-secondary">Conclusion: </span> <span>{kundali.kundali.pitraDoshaReport.conclusion}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Rules Matched: </span>
                                                 <ul>
                                                    {kundali.kundali.pitraDoshaReport.rules_matched.map((item,index)=>(
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                 </ul>
                                                </td>
                                                <td><span className="text-secondary">Is Pitra Dosha Present: </span> <span>{kundali.kundali.pitraDoshaReport.is_pitri_dosha_present?'Yes':'No'}</span></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <span className="text-secondary">Remedies: </span>
                                                            <ul>
                                                                {kundali.kundali.pitraDoshaReport.remedies!=null&&kundali.kundali.pitraDoshaReport.remedies.map((item,index)=>(
                                                                    <li key={index}>{item}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <span className="text-secondary">Effects: </span>
                                                            <ul>
                                                                {kundali.kundali.pitraDoshaReport.Effects!=null&&kundali.kundali.pitraDoshaReport.Effects.map((item,index)=>(
                                                                    <li key={index}>{item}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>}
                                     {kundali.kundali.sadhesatiCurrentStatus!=null&&<div className="col-12">
                                        <div className="table-responsive">
                                            <table className="my-3" >
                                                <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Sadhesati Current Status/शनि साढ़ेसाती</caption>
                                                    <tr>
                                                        <td colSpan={4}><span className="text-secondary">What is Sadhesati : </span> <span>{kundali.kundali.sadhesatiCurrentStatus.what_is_sadhesati}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="text-secondary">Sadhesati Status: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.sadhesati_status?'Present':'Not Present'}</span></td>
                                                        <td><span className="text-secondary">Saturn Sign: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.saturn_sign}</span></td>
                                                        <td><span className="text-secondary">Moon Sign: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.moon_sign}</span></td>
                                                        <td><span className="text-secondary">Consideration Date: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.consideration_date}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="text-secondary">Is Saturn Retrograde: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.is_saturn_retrograde?'Yes':'No'}</span></td>
                                                        <td colSpan={3}><span className="text-secondary">Is Undergoing Sadhesati: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.is_undergoing_sadhesati}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}><span className="text-secondary">Sadhesati Phase: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.sadhesati_phase}</span></td>
                                                        <td><span className="text-secondary">Start Date: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.start_date}</span></td>
                                                        <td><span className="text-secondary">End Date: </span> <span>{kundali.kundali.sadhesatiCurrentStatus.end_date}</span></td>
                                                    </tr>
                                            </table>
                                        </div>
                                   {kundali.kundali.numeroTable!=null&&<div className="col-12">
                                    <div className="table-responsive">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>Numero Table/अंक ज्योतिष </caption>
                                            <tr>
                                                <td><span className="text-secondary">Name: </span> <span>{kundali.kundali.numeroTable.name}</span></td>
                                                <td><span className="text-secondary">Name Number: </span> <span>{kundali.kundali.numeroTable.name_number}</span></td>
                                                <td><span className="text-secondary">Date: </span> <span>{kundali.kundali.numeroTable.date}</span></td>
                                                <td><span className="text-secondary">Destiny Number: </span> <span>{kundali.kundali.numeroTable.destiny_number}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Evil Number: </span> <span>{kundali.kundali.numeroTable.evil_num}</span></td>
                                                <td><span className="text-secondary">Fav Color: </span> <span>{kundali.kundali.numeroTable.fav_color}</span></td>
                                                <td><span className="text-secondary">Fav Day: </span> <span>{kundali.kundali.numeroTable.fav_day}</span></td>
                                                <td><span className="text-secondary">Fav God: </span> <span>{kundali.kundali.numeroTable.fav_god}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Fav Mantra: </span> <span>{kundali.kundali.numeroTable.fav_mantra}</span></td>
                                                <td><span className="text-secondary">Fav Metal: </span> <span>{kundali.kundali.numeroTable.fav_metal}</span></td>
                                                <td><span className="text-secondary">Fav Stone: </span> <span>{kundali.kundali.numeroTable.fav_stone}</span></td>
                                                <td><span className="text-secondary">Fav Sub Stone: </span> <span>{kundali.kundali.numeroTable.fav_substone}</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="text-secondary">Friendly Num: </span> <span>{kundali.kundali.numeroTable.friendly_num}</span></td>
                                                <td><span className="text-secondary">Neutral Num: </span> <span>{kundali.kundali.numeroTable.neutral_num}</span></td>
                                                <td><span className="text-secondary">Radical Num: </span> <span>{kundali.kundali.numeroTable.radical_num}</span></td>
                                                <td><span className="text-secondary">Radical Ruler: </span> <span>{kundali.kundali.numeroTable.radical_ruler}</span></td>
                                            </tr>
                                        </table>
                                    </div>
                                    
                                    {kundali.kundali.generalAscendantReport!=null&&<div className="col-12">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>General Ascendant Report/लग्न फलादेश</caption>
                                            <tr>
                                                <td colSpan={3}><span className="text-secondary" style={{background: "	#ffefd5",color:"#fffafa"}}>Ascendant: </span> <span style={{background: "		#ffdab9"}}>{kundali.kundali.generalAscendantReport.asc_report.ascendant}</span></td>
                                            </tr>
                                            
                                            <tr>
                                                <td><span className="text-secondary">Report: </span> <span>{kundali.kundali.generalAscendantReport.asc_report.report}</span></td>
                                            </tr>
                                        </table>
                                    </div>}
                                    <div className="col-12">
                                        <table className="my-3" >
                                            <caption style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>General House Report/ ग्रह फलादेश </caption>
                                    {kundali.kundali.generalHouseReport!=null&&Object.keys(kundali.kundali.generalHouseReport).map((key)=>(<>
                                            <tr>
                                                <td colSpan={3}><span className="text-secondary" style={{background: "	#ffefd5",color:"#fffafa"}}>Planet: </span> <span style={{background: "		#ffdab9"}}>{kundali.kundali.generalHouseReport[key].planet}</span></td>
                                            </tr>
                                            
                                            <tr>
                                                <td> <span>
                                                <span className="text-secondary">House Report: </span>
                                                <Interweave content={kundali.kundali.generalHouseReport[key].house_report} /></span></td>
                                            </tr>
                                            </>
                                    ))}
                                    </table>
                                    </div>
                                   
                                    
                                    
                                    </div>}
                                    
                                   </div>}
                                </div>
                                
                            </div>
                        </div>
                        <PDFDownloadLink className="w-100" document={<GetKundali kundali={kundali} />} fileName="Vedic-Kundali">
                        {({loading})=>(loading? <Loader/> : <button className="btn btn-primary btn-lg btn-block">Get Pdf</button>)}
                        </PDFDownloadLink>
                   <div class="card border-0" style={{width:"100%", height:"40%"}}>
                                        <div className="card-header" style={{background: "radial-gradient( circle 581.9px at 10% 20%,  rgba(224,116,10,1) 0%, rgba(230,244,245,1) 85.5% )",color:"#fffafa"}}>
                                             <h6 style={{textAlign:"center"}}>Click Here to Ask Our PujyaPanditG <a href="https://wa.me/9610800108" target="_blank">
                                            <img src={whatsappLogo} alt="whatsapp" width={50} />
                    </a></h6>
                                        </div>
                       </div>

                    </div>
                </div>
                {/*<div className="container-fluid desc ">
                    <div className="card im">
                        <div className="card-body">
                            <p>
                                What Is Kundli?
                                <br />
                                Kundali is an astrological chart prepared on the basis of the
                                exact date of birth and time, and place of birth of an
                                individual. This chart determines the positioning of various
                                signs, planets, the Sun and the Moon. Kundli date of birth and
                                time also reveals the astrological aspects and significant
                                information of a newborn child. Done by an expert astrologer,
                                Kundli creation is not a simple task. The astrologer will
                                determine your Kundli through the local time and place of birth
                                to calculate the rising sign and ascending status of the
                                individual, or native. The chart provides an insight into a
                                person’s personality, his/her present and the future. A Kundli
                                prediction report consists of detailed Kundali analysis that can
                                foretell the good and bad times, or phases, for the native to
                                act accordingly.
                                <br />
                                Kundli matching can also be done to find the
                                best match for marriage.
                                <br />
                                Apart from this, with the help of Janam
                                kundali , one may understand the planetary transits in different
                                signs over the day, month and year. The positions of the planets
                                in your Kundali showcase various events and possibilities. Thus,
                                by looking at the Janma kundli and the planetary view therein,
                                an astrologer predicts the future of a native. This is done
                                using time tested principles of Vedic Astrology.
                                <br />
                                Online Kundali-
                                Its Benefits Nowadays, creating kundli online and free Janam
                                Kundali analysis is a common and very popular means of getting
                                future predictions. An online Kundali report proves very
                                reliable and helpful when prepared by learned and experienced
                                astrologers, who can predict your future and traits accurately.
                                <br />
                                Online Kundali making can be beneficial in many ways:
                                Pujyapanditg With the help of a Kundli, an astrologer can
                                predict your future in the most accurate way. According to the
                                positioning of planets in your Kundli, you can pick the most
                                suitable career choice, one which is in harmony with your
                                personality. It can give you detailed information about your
                                personality traits, relationships, marriage, career, finances
                                and other aspects of life.
                                <br />
                                It can help find your lucky gemstone,
                                lucky colours and lucky numbers that aid your success and
                                happiness in various aspects of life. A Kundli not only gives
                                information about your future, but can also help find remedies
                                to decrease the depth of the problems.
                                <br />
                                Kundli highlights the
                                doshas such as Mangal Dosha and Kaal Sarp Dosh, so that these
                                doshas can be resolved with the
                                Dosha Nivaran Puja.
                                <br />
                                A Kundli
                                also foretells the favorable and unfavorable timing in life. You
                                can find your strengths and weaknesses to help become a better
                                person.
                                <br />
                                A Natal Chart showcases and warns you about the diseases
                                and adversities that are likely to affect you in the future.
                                Check your Kundli online for Free on <a href="https://pujyapanditg.com/ " >Pujyapanditg.com</a>
                            </p>
                        </div>
                    </div>
                </div>*/}

            </WrapContainer>
        </> : <Loader />
    );
};

export default ViewKundali;
