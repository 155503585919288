import React, { useEffect, useState } from "react";
import HeaderBreadcrumb from "../../components/BreadCrumb";
import WrapContainer from "../../components/container";
import "./E.css";
import ThirdSection from "./ThirdSection";

// Core components

import Epuja from "./Epuja";
import Headimage from "../../components/HeadImage/Headimage";
// APi
import Dataservices from "../../services/requestApi";
const EpoojaBook = (props) => {
  const [pooja, setPooja] = useState([]);
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    const getData = async (e) => {
      try {
        const poojaRes = await Dataservices.GetEpooja();
        if (mounted) setPooja(poojaRes.data.data);
        // console.log(poojaRes);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      getData();
      setMounted(false);
    };
  }, [mounted]);
  return (
    <WrapContainer>
      <HeaderBreadcrumb title="E-Pooja" />
      <Headimage title="E-pooja and services" />
      <Epuja data={pooja} slider={false} title="E-pooja and services" />
      <div className="service-details">
      <div className="container">
        <h2> Why E-Puja </h2>
        <p> pujyapanditg is an online platform providing Sacred, Hassle-free E-Puja Services around the globe. Perform Vedic rituals with qualified Pandits from the safety of your home. Puja is performed Live over an interactive Zoom / Google Meet session. </p>
        <div className="row">
          <div className="col-md-4 mb-3">
            <img className="img_5" src={require("../../assets/img/book pandit icon.png")} style={{width:"50%"}} alt="book a service" />
            <h6> info @<br />pujyapanditg.com </h6>
          </div>
          <div className="col-md-4">
            <img className="img_6 " src={require("../../assets/img/6.png")}style={{width:"28%",marginLeft:'8rem'}} alt="pay advance" />
            <h6> Pay<br />Booking Advance </h6>
          </div>
          <div className="col-md-4">
            <img className="img_7" src={require("../../assets/img/7.png")} style={{width:"40%"}} alt="experience rituals" />
            <h6> Experience the<br />Divine Rituals </h6>
          </div>
        </div>
      </div>
    </div>
{/* third section */}
<ThirdSection />


    <div className=" service-advantage">
  <div className="container">
    <h2> Pujyapanditg Advantage </h2>
    <div className="row padding">
      <div className="col-md-6">
        <ul>
          <li>
            Online, Organized and Authentic.
          </li>
          <li>
            Best Pandits, Purohits and Aacharyas.
          </li>
          <li>
            Verified Matha educated Purohits with 10-25 years of Vedic experience.
          </li>
        </ul>
      </div>
      <div className="col-md-6">
        <ul>
          <li>
            Book from anywhere in the world as per your local time zone.
          </li>
          <li>
            Superior Customer Experience, 24/7 Support.
          </li>
        </ul>
      </div>
    </div>
    <a href="#" target="_blank" className="text-white">
      <button> Read more </button>
    </a>
  </div>
</div>


    </WrapContainer>
  );
};
export default EpoojaBook;