import { InputNumber } from "antd";
import React from "react";
import { Link,useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import BuyNow from "../../pages/BuyNow";
import Dataservices from "../../services/requestApi";
import queryString from "query-string";
import {BASEURL} from '../../services/http-common';
const CartCard = ({ data, remove }) => {
  // console.log(data);
  const history = useHistory();
  const Buy=()=>{
    history.push("/buy-now/" + data._id);
  }
  const getQuan=async (quantity)=>{
    // console.log(data._id,quantity)
    try {
      // const data1=new FormData();
      // data1.append("quantity",quantity);
      const data1={quantity:quantity};
      const res = await Dataservices.CartUpdate(data._id,queryString.stringify(data1));
      if (res.data.status_code === 200) {
        window.location.reload(true);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    Boolean(data.product!=null)&&<div className="cart_info">
      <Row>
        <Col lg="3">
          <div className="pro_detail">
            <div className="pro_thumb">
              {console.log(data)}
              <Link to={`/product/${data.product._id}`}>
                <img
                  src={`${BASEURL.ENDPOINT_URL}${data.product.image[0].image_link}`}
                  className="img-fluid"
                  alt={data.product.name}
                />
              </Link>
            </div>
          </div>
        </Col>
        <Col lg="9">
          <div className="pro_detail">
            <h5 className="pro_brand">{data.brand}</h5>
            <h4 className="pro_title">
              <Link to={`/product/${data.id}`}>
                {data.product.name}
              </Link>
            </h4>
            <p className="pro_material">Material: {data.product.material}</p>
            <h4 className="pro_price">
              ₹ {data.product.price} <span>₹ {data.product.mrp}</span>
            </h4>
            <div className="pro_quantity mb-2">
              <InputNumber id="quantity"
                          defaultValue={data.quantity}
                          className="quantity" onChange={(val)=>getQuan(val)} status="warning" />
            </div>
            <div>
            <Button color="amber" className="btn-rounded shadow text-white" onClick={Buy} style={{ padding:'5px 15px' }}>Buy Now</Button>
            </div>
            <div className="pro_action">
              <Button
                color="danger"
                className="btn-rounded"
                onClick={(e) => remove(e, data._id)}
              >
                <span className="lnr lnr-cross"></span>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CartCard;