import React from 'react'
import css from "./third.module.css";

const ThirdSection = () => {
  return (
    <div className="container  third col-md-9 mb-xs-24 side-right snipcss-ACDXr mt-3">
  <main id="main" className>
    <article className="post-snippet mb64 content post-1515 post type-post status-publish format-standard has-post-thumbnail hentry category-e-puja category-rituals category-Pujyapanditg category-traditions tag-e-puja tag-epuja tag-online-epuja tag-online-puja tag-online-puja-service tag-onlinepujaservice snip-article">
      <header className="nolist" >
        <h1 className="post-title text-center text-bold">
          Book Pandit for Online E-Puja Ceremony
        </h1>
        {/* <ul className="post-meta"style={{marginLeft:'13%'}}>
          <li>
            <i className="fa fa-user">
            </i>
            <span className>
              <a href="https://www.pujyapanditg.com/" className="snip-a">
                Pujyapanditg Desk
              </a>
            </span>
          </li>
          <li>
            <i className="fa fa-calendar">
            </i>
            <span className>
              <time className="published">
                1 June 2021
              </time>
              <time className="updated">
                17 March 2022
              </time>
            </span>
          </li>
          <li>
            <i className="fa fa-folder-open-o">
            </i>
            <span className>
              <a href="https://www.pujyapanditg.com/" className="snip-a">
                E-Puja
              </a>
            </span>
          </li>
        </ul> */}
      </header>
      <div className="entry-content" >
        <p style={{textAlign: 'center'}} className>
          <img className={css.aligncenter} src={require("../../assets/img/thirdSectionPooja.jpg")} width={810} height={450} sizes="(max-width: 810px) 100vw, 810px" />
        </p>
        <h4 style={{textAlign: 'left'}} className>
          <span style={{color: '#ff6600'}} className>
            <strong>
              Online E-Puja Ceremony
            </strong>
          </span>
        </h4>
        <p className>
          Thinking about performing an&nbsp; E-Puja but not sure how to go about it? Wondering how to get the right Purohit by community, language and region specifications based on your customs and rituals? Well, look no further as we got you covered!
        </p>
        <p className>
          At Pujyapanditg.com, we understand the importance of staying in touch with one’s roots and present you with our Online 
          <span style={{color: '#ff6600'}} className>
            <b>
              E-Puja Services
            </b>
          </span>
          . We leverage technology the ‘Smart’ way and conduct Vedic Online E-Puja ceremonies with your active participation. Yes, you can now perform an E-Puja ceremony from the comfort and safety of your home anywhere in the world! That too as per the best muhurats in your timezone. Book a Pandit / Purohit online in your preferred language today.
        </p>
        <p className>
          Participate in Vedic E-Pujas that are performed systematically with Authentic rituals and customs. Our Pandits are highly proficient, educated in Vedas from renowned Mathas in India, who will guide you in every step over a video call. Everything here is tailor made to suit your needs – all you need to have is an active internet connection, a laptop or a smartphone along with some household Puja items. We host the Puja ceremony online, you’ll join the Puja from your location and our Pandit Ji conducts the entire Puja with your participation.
        </p>
        <p className>
          We arrange for the complete Puja setup including the Puja samagri. As per the guidance of our Pandit Ji, you take Sankalp for the Puja and chant the mantras with him during the rituals. Our Purohit will guide you step by step over a Live Zoom / Google Meet Video call. Pandit Ji ensures that all the Vidhi-Vidhans are performed accurately. A simple checklist of household items is shared in advance with you and a reminder is sent a day before the rituals.
        </p>
        <p className>
          &nbsp;
        </p>
        <p className>
          <img className={`${css.aligncenter} wp-image-1517 snip-img`} src={require("../../assets/img/pandit2.png")} width={304} height={304} sizes="(max-width: 304px) 100vw, 304px" />
        </p>
        <p className>
          You may book an E-Puja for any of below Puja requirements –
        </p>
        <ul className>
          <li>
            <span style={{color: '#ff6600'}} className>
              Ganapati Puja and Homa
            </span>
            – Dissolution of obstacles, bringing good fortune in one’s personal and professional life and purification of the place and minds of the devotees.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Griha Pravesh Puja
            </span>
            – This Puja is performed to ensure peace and harmony in the new house. Also it is believed to drive away negativities from the building / premises.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Satyanarayan Puja
            </span>
            – This puja is performed immediately after or on an auspicious occasion like a marriage or moving into a new house. It is also performed to achieve success in life or for general well being.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Sundarkand Path
            </span>
            – To appease Lord Hanuman 
            <span className>
              <span className>
                for mental strength and confidence, for completion of any pending tasks.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Hanuman Chalisa Path
            </span>
            – Helps a devotee get complete focus over one’s mind and body. This Puja is performed to get rid of all the fear and invoking the blessings of Lord Hanuman.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Durga Sapthashathi Path
            </span>
            – Performing this path helps us overcome our troubles and sufferings. This puja also liberates one from evil eyes, fears, curses and brings positivity in one’s life.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Gand Mool Nakshanti Shanti Puja
            </span>
            – Helps to get rid of the negative effects of the Moola Nakshatras.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Namakaran Puja / Naming ceremony
            </span>
            – This ceremony is conducted not just for formally naming the child but also to seek blessings from various Gods and to initiate the child into the religion.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Birthday Puja
            </span>
            – A Vedic Puja performed on one’s Birthday results in great all round positive effect and helps one to have a successful year ahead.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Remedy Pujas / Graha Dosha Nivaran Pujas
            </span>
            – The Vedic and astrology scriptures prescribe various Pujas to ward off the negative influence of the planets and to improve the benefic effects.You can perform a&nbsp;remedial Puja/Havan as suggested by an astrologer&nbsp;according to proper vedic rituals through SmartPuja.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Kal Sarp Dosha Nivaran Puja
            </span>
            – This dosha occurs if all the planets come between Rahu and Ketu in one’s horoscope. One faces lots of hardships due to this dosha. By performing this Puja, all the negative effects are nullified.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Aditya Hrudaya Parayana
            </span>
            – Lord Rama
            <span className>
              <span className>
                recited Aditya Hridaya Stotra before
                <b>
                </b>
                killing evil King Ravana and achieved Victory. This Puja helps devotee gain confidence to conquer over one’s problems.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Lalitha Sahasranamam
            </span>
            – Performed to 
            <span className>
              <span className>
                prevent premature death and provide a long and contended healthy life.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Vishnu Sahasranama
            </span>
            – 
            <span className>
              <span className>
                Assists in removing obstacles, diseases, suffering and helps attain Moksha. Removes all evils like jealousy, greed and cleanses the mind.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Dhanvanthari Japam
            </span>
            – H
            <span className>
              <span className>
                elps in improving the vitality and energy levels in humans. It also helps attain the natural state of well-being. This mantra can remove the mental fears and all kinds of afflictions thereby promoting a clear vision.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Rudra Parayana
            </span>
            – 
            <span className>
              <span className>
                Rudra drives away the negative energy, removes Ignorance and helps us to realise our true selves. Provides a protective shield against diseases, evil etc.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Durga Suktham
            </span>
            – 
            <span className>
              <span className>
                Durga Suktam praises and describes Lord Agni and his role as a boatman who ferries us towards enlightenment or mukti.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Gayatri Japam
            </span>
            – Maa Gayatri will protect your body,make your intellect shine and improve your power of speech.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Veda Parayanam
            </span>
            – T
            <span className>
              <span className>
                o vanquish one’s enemies and protect one from harmful things, negative chants, evil eye etc.
                <br />
              </span>
            </span>
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Mrityunjaya Puja and Japam
            </span>
            – It helps to recover from critical and miserable health condition.
          </li>
          <li>
            <span style={{color: '#ff6600'}} className>
              Havan / Homas
            </span>
            – For good health, wealth, prosperity, overcoming obstacles etc.
          </li>
        </ul>
       
        <h4 dir="ltr" className>
          <span style={{color: '#ff6600'}} className>
            <b>
              Booking Process:&nbsp;
            </b>
          </span>
        </h4>
        <div dir="ltr">
          <div dir="ltr">
            In order to book the online E-Puja,&nbsp;we’ll need some basic information like –
          </div>
          <div dir="ltr">
          </div>
          <div dir="ltr">
            Full Name :
          </div>
          <div dir="ltr">
            Mobile Number :
          </div>
          <div dir="ltr">
            Email Address :
          </div>
          <div dir="ltr">
            Puja Variant Name and Date of&nbsp;Puja&nbsp;:
            <br />
            Local Address :
          </div>
        </div>
        <div dir="ltr">
        </div>
        <div dir="ltr">
          <div dir="ltr">
            <p className>
              Once the Puja&nbsp;is booked, you’ll receive an email and sms with the booking&nbsp;details.&nbsp; Our team will contact you within 24 hours and discuss the Puja details along with the Puja payment link sent over payment gateway. The booking is confirmed once the Puja payment is done online and processed with sharing of the video link (Zoom call / Google Meet etc) for the Puja. On the date of Puja you simply need to click on the meeting link and join the Puja ceremony.
            </p>
          </div>
        </div>
        <div dir="ltr">
          <div dir="ltr">
          </div>
          <div dir="ltr" style={{textAlign: 'left'}}>
            We’ll also send you an email with the list of household items (like Plates, Spoons, Sweet / Prasad, Deepak, Napkins etc.) that you’ll need to keep ready before the start of the&nbsp;Puja.
          </div>
          <div dir="ltr" style={{textAlign: 'left'}}>
          </div>
          <div dir="ltr" style={{textAlign: 'center'}}>
          </div>
          <div dir="ltr" style={{textAlign: 'center'}}>
          </div>
          <div dir="ltr" style={{textAlign: 'center'}}>
            Please click on the below link and book the ceremony of your choice!
          </div>
          <h2 dir="ltr" style={{textAlign: 'center'}} className>
            <span style={{color: '#ff6600'}} className>
              <a style={{color: '#ff6600'}} href="https://www.Pujyapanditg.com/EpoojaBook" className>
                BOOK NOW!
              </a>
            </span>
          </h2>
          <p style={{textAlign: 'center'}} className>
            Call us on 
            <span style={{color: '#ff6600'}} className>
              <strong>
                +91-
              </strong>
              <strong>
              9610800108
              </strong>
            </span>
            or WhatsApp @ 
            <span style={{color: '#ff6600'}} className>
              <strong>
                +91-9610800108
              </strong>
            </span>
            for more details.
            <br />
            <strong>
              Vedic E-Puja Ceremony| One-Stop solution | Truly Hassle-Free
            </strong>
          </p>
        </div>
        <p style={{textAlign: 'left'}} className>
          <a href="http://www.Pujyapanditg.com" className>
            <span style={{color: '#ff6600'}} className>
              <strong>
                Pujyapanditg 
              </strong>
            </span>
          </a>
          has a team of Qualified, Knowledgeable and Experienced Pandits who perform Pujas as per your community, language and region specifications. We take care of the entire process, right from booking and assigning the right Pandit, with best Muhurat, Puja Items, Puja Samagri&nbsp; etc. All you need to do is to book the service, sit back and relax while we work on ensuring that you have a satisfying and divine Puja experience.
        </p>
        <div className={`${css.social_sharing} ${css.ss_social_sharing}`}>
          <a rel="external nofollow" className={css.ss_button_facebook} href="https://www.facebook.com/PujyapanditG">
            Share on Facebook
          </a>
          <a rel="external nofollow" className={css.ss_button_twitter} href="https://twitter.com/pujyapandit?s=08">
            Share on Twitter
          </a>
          {/* <a rel="external nofollow" className="ss-button-linkedin" href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.Pujyapanditg.com%2Fblog%2Fonline-e-puja-ceremony%2F&title=Book+Pandit+for+Online+E-Puja+Ceremony">
            Share on Linkedin
          </a> */}
        </div>
        <hr />
      </div>
    </article>
    {/* <div className="author-bio" >
      <div className="row">
        <div className="col-sm-2">
          <div className>
            <img src="https://secure.gravatar.com/avatar/d70f6b7d305e6199dee5346da66e9d63?s=100&d=mm&r=g" className="avatar avatar-100 photo snip-img" height={100} width={100} />
          </div>
        </div>
        <div className="col-sm-10">
          <b className>
            Pujyapanditg Desk
          </b>
          <p className>
          </p>
          <ul className="list-inline social-list author-social">
            <li>
              <a href="https://twitter.com/pujyapandit?s=08" className>
                <i className="fa fa-twitter">
                </i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/PujyapanditG" className>
                <i className="fa fa-facebook">
                </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> */}
    
  </main>
  {/* #main */}
</div>

  )
}

export default ThirdSection
