
//  export const data = [
//     // {
//     //   id: 1,
//     //   title: "Silver",
//     //   Ctitle: "Silver package contains these facilities",
//     //   listData: [
//     //     "basic astrological details",
//     //     "Lagna & Moon chart ",
//     //     "Avakahada Chakra",
//     //     " Vimsottari dasha advance ",
//     //     "Pdf Of Kundli will be send via Mail ",
//     //   ],
//     //   Price: "Free",
//     //   mrp: "500",
//     //   Image: require("../../../assets/img/Artboard 2.png"),
//     //   headImage: require("../../../assets/img/Silver.png"),
//     // },
    
    
//   ];

 export  const golden=[
{
      id: 2,
      title: "Vedic Kundali",
      Ctitle: "Providing these Facilities in Kundali",
      listData: [
        "Advance Kundali & Lagna Chart",
        "Ashtakvarga",
        "Horoscope Charts",
        "Mangalik & Kaalsarp Dosha",
        "Suggestions and Remedies",
        "Life Reports (Prediction)",
        "Job & carrier analysis ",
        "Vimshottari Dasha",
        "Shani Sade Saati Dasha",
        "PDF & Whatsapp Support",
      ],
      description:
        '"basic astrological details","Lagna & Moon chart ", "Avakahada Chakra"," Vimsottari dasha advance ", "Kaal sarpa yoga analysis ","   Manglik Dosa calculations", " Job and carrier analysis ","Pdf Of Kundli will be send via Mail ", "Whatsapp support "',
      Price: "500",
      mrp: "1100",
      coupon: true,
      Image: require("../../../assets/img/Artboard 3.png"),
      headImage: require("../../../assets/img/gold.png"),
    },
  ]

//   export const diamond=[
//     // {
//     //   id: 3,
//     //   title: "Diamond",
//     //   Ctitle: "Diamond package contains these facilities",
//     //   listData: [
//     //     "Premium reports and prediction Basic astrological details ",
//     //     "basic astrological details",
//     //     "Lagna & Moon chart ",
//     //     "Avakahada Chakra",
//     //     " Vimsottari dasha advance ",
//     //     "Kaal sarpa yoga analysis ",
//     //     "Manglik Dosa calculations",
//     //     "Job and carrier analysis ",
//     //     "Love marriage reports",
//     //     "Matching predictions",
//     //     "Bhava chart and chalit charts",
//     //     "Handmade ( हस्तलिखित) Kundli will be send via courier and Pdf at Email ",
//     //     " Call & Whatsapp support ",
//     //   ],
//     //   Price: "1100",
//     //   mrp: "2100",
//     //   coupon: true,
//     //   Image: require("../../../assets/img/Artboard 1.png"),
//     //   headImage: require("../../../assets/img/Diamond.png"),
//     // },
//   ]
  