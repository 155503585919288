import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useLocation,useHistory } from 'react-router-dom'


// Custom Components
import CartCard from "../Cards/CartCard";

// APi
import Dataservices from "../../services/requestApi";
const CartAll = ({ data }) => {
  let history = useHistory();
  const [total_amount, setTotalAmount] = useState("");
  // const [quantity,setQuantity]=useState();
  useEffect(() => {
    const getTotalAmount = () => {
      let Data = data;
      var amount = 0;
      for (let item of Data) {
        if(item.product!=null){
        amount += ~~item.product.price * item.quantity;
        }
      }
      setTotalAmount(amount);
    };
    getTotalAmount();
  }, [data]);
  const RemoveCart = async (e, id) => {
    e.preventDefault();
    try {
      const res = await Dataservices.CartDelete(id);
      if (res.data.status_code === 200) {
        window.location.reload(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
 const buyAll= async ()=>{
    try {
      history.push({pathname:'/buy-now/cartAll',state:{cartAll:true}});
    } catch (error) {
      
    }
 }

  return (
    <Container fluid>
      <Row>
        <Col lg="7">
          <div className="all_cart">
            <div className="heading">
              <h1>
                My <span>Cart ({data && data.length})</span>
              </h1>
            </div>
            {data &&
              data.map((item,key) => (
                <CartCard data={item} key={key} remove={RemoveCart} />
              ))}
          </div>
        </Col>
        <Col lg="5">
          <div className="all_cart price_details ">
            <div className="heading">
              <h1>Price Details</h1>
            </div>
            <div className="checkout_details">
              <div className="lists">
                <span>Price ({data && data.length} items)</span>
                <span className="text-success"> ₹ {total_amount}</span>
              </div>
            </div>
            <div className="total_amount text-right mb-4">
              <h5 className="font-weight-bold text-muted">
                Total Amount :{" "}
                <span className="ml-3 text-dark"> ₹ {total_amount}</span>
              </h5>
            </div>
            <Button color="amber" block size="lg" className="text-white" onClick={buyAll}>
              Place Order
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default CartAll;