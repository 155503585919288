import http from "./http-common";

class DataService {
  // Login
  TFA_Login(data) {
    return http.get("user/login/tfa?" + data);
  }

  Login(data) {
    return http.get("user/login?" + data);
  }

  // Payment Gateway Services
  PurchaseItem(data) {
    return http.get("razorpay/order?" + data);
  }
  PurchaseCapture(id, data) {
    return http.post("razorpay/capture/" + id, data);
  }
  FetchPayment(id) {
    return http.get("razorpay/fetch/" + id);
  }
  Kundali(data) {
    return http.post("kundali/order", data);
  }
  // Register
  Register(data) {
    return http.post("user/signup", data);
  }
  RegisterVerify(data) {
    return http.post("user/verify", data);
  }
  KundaliResponse(data) {
    return http.post("Kundali/deposit", data);
  }
  // User Profile Update
  ProfileUpdate(data) {
    return http.patch("user/profile", data);
  }
  GetProfileData() {
    return http.get("user/user_data");
  }
  GetAllPoojaOrder() {
    return http.get("puja/order/all");
  }

  GetProductBySlug(slug) {
    return http.get("product/slug/" + slug);
  }
  GetArticleBySlug(slug) {
    return http.get("article/slug/" + slug);
  }
  GetPoojaBySlug(slug) {
    return http.get("puja/slug/" + slug);
  }

  RegisterAsPandit(data) {
    return http.post("pandit/signup", data);
  }
  // Product Services
  GetBannerAll(type) {
    return http.get("banner/type/" + type);
  }

  // Product Services
  GetProductAll(page, pageSize) {
    return http.get(`product/all?page=${page}&pageSize=${pageSize}`);
  }
  GetProductById(id) {
    return http.get("product/id/" + id);
  }

  // Article Services
  GetArticleAll(page, pageSize) {
    return http.get(`article/all?page=${page}&pageSize=${pageSize}`);
  }
  GetArticleById(id) {
    return http.get("article/id/" + id);
  }

  // Pooja Services
  GetPoojaAll(page, pageSize) {
    return http.get(`puja/all?page=${page}&pageSize=${pageSize}`);
  }
  GetTrendingPooja(page, pageSize) {
    return http.get(`puja/trending/all?page=${page}&pageSize=${pageSize}`);
  }
  GetEpooja() {
    return http.get("puja/getall/Epooja");
  }
  GetPoojaById(id) {
    return http.get("puja/id/" + id);
  }
  GetPoojaPackagesByPoojaId(id) {
    return http.get("puja_packages/puja/" + id);
  }
  BookPooja(data) {
    return http.post("puja/order/create", data);
  }
  BookPoojaUpdate(data) {
    return http.post("puja/order/deposit", data);
  }
  //order services
  OrderDetails(data) {
    return http.post("product/order/create", data);
  }

  // Wishlist API Services
  GetWishlistAll() {
    return http.get("user/wishlist/all");
  }
  GetCartAll() {
    return http.get("user/cart/all");
  }
  GetCartItem(id) {
    return http.get(`user/cart/item/${id}`)
  }
  CartCreate(data) {
    return http.post("user/cart/create", data);
  }
  CartUpdate(id, data) {
    return http.patch("user/cart/update/" + id, data);
  }
  forgotPassword(data) {
    return http.post("user/password/forgot", data);
  }
  CartDelete(id) {
    return http.delete("user/cart/delete/" + id);
  }
  CartBuy(data) {
    return http.post("user/cart/buy/", data);
  }
  // cashfree order api
  OrderResponse(data) {
    return http.post("product/order/deposit", data);
  }
  sortedProudcts() {
    return http.get("product/sort");
  }
  getAllPdf() {
    return http.get(`/puja_pdf/all`);
  }
  getAllReview(Id) {
    return http.get(`product/reviw/all/${Id}`);
  }
  createReview(id, data) {
    return http.post(`product/reviw/${id}`, data);
  }
  feedbackCreate(data) {
    return http.post(`feedback/create`, data);
  }
  getPop() {
    return http.get(`/pop_up/all`);
  }
  PostComment(data, id) {
    return http.patch(`article/update/${id}/comment`, data);
  }
  PostLike(id) {
    return http.patch(`article/update/${id}/like`);
  }
  PostDislike(id) {
    return http.patch(`article/update/${id}/dislike`);
  }
  getAllOrder() {
    return http.get("product/order/all");
  }
  globalSearch(search) {
    return http.get("/filter/search/" + search);
  }

  getAllKundali() {
    return http.get("/kundali/user");
  }

  getKundali(id) {
    return http.get(`/kundali/${id}`);
  }

  getPanchang(panchangDate) {
    return http.get(`/astrology/panchang/${panchangDate}`);
  }
  
  getMatching(data) {
    return http.post(`/astrology/matching`, data);
  }

  updateAddress(Id,data) {
    console.log(Id,data);
    return http.patch(`/user/address/update/${Id}`, data);
  }
  
  DeleteAddress(id) {
    return http.delete(`/user/address/delete/${id}`);
  }
}

export default new DataService();
