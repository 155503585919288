import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { SwiperSlide } from "swiper/react";

// Core components
import ProductCards from "../../Cards/ProductCard";
import SliderWrapper from "../../Slider";
import { Button, Spinner } from "reactstrap";
import { Link} from "react-router-dom";
import Dataservices from "../../../services/requestApi";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";


const AllProduct = ({ data, slider, wishlist, title, totalResults,fetchMoreData }) => {

  const [products, setProducts] = useState([]);
  const [latest, setLatest] = useState([]);
  const [category, setCategory] = useState([]);
  const [activeCat,setActiveCat]= useState('All');
  const Filterdata = (CateData) => {
    if (CateData === 'All') {
      setProducts(data);
    }
    else {
      const filterproduct = data.filter((product) => {
        return product.category.category === CateData;
      })
      setProducts(filterproduct);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (data) {
      setProducts(data);
      // setLatest(data);
      let Category = [];
      Category.push('All');
      data.forEach((item) => {

        if (!Category.includes(item.category.category)) {
          Category.push(item.category.category);
        }
      })
      setCategory(Category);
      setLatest(data)
      // let filterLatest=data;
      const getLatest = await Dataservices.sortedProudcts()
      setLatest(getLatest.data);
    }
  }, [data])


  return (
 
  <div className="all_product" >
    <Container>
      <Row className="justify-content-center">
        <Col xl="12">
          <div className="heading">
            <h1>
              {title ? (
                <>
                  {/* <span>{title}</span> */}
                </>
              ) : (
                <>
                  <Link to={"/all-product"}>
                    <span style={{ color: 'black' }}>Our&nbsp;</span><span>Products</span></Link>
                </>
              )}
            </h1>
          </div>
        </Col>
        <Col xl="12" className="mb-4">
          {(window.location.pathname === '/all-product') ?
            category && category.map((item, index) => (<Button key={Math.random(index + 1) * 520}
              className={`btn-rounded mr-2 mb-2 ${(activeCat===item)?'text-white':''}`}
              outline={(activeCat===item)?false:true}
              color="amber"
              onClick={() =>{Filterdata(item);setActiveCat(item);}}
            >
              {item}
            </Button>
            )) : false}
        </Col>

        {slider ? (
          (window.location.pathname=== '/all-product') ?
         <InfiniteScroll style={{ overflow: 'unset' }}
              dataLength={products.length}
              next={fetchMoreData}
              hasMore={products.length !== totalResults}
              scrollThreshold={0.6}
              loader={
              <div className="container d-flex align-items-center justify-content-center my-5">
              <Spinner />
                </div>}>
            <Col lg="12" className="row">
              {products.map((item) => (
                <Col lg="3" key={item._id}>
                  <SwiperSlide>
                    <ProductCards data={item} wishlist={wishlist} soldOut={item.quantity?false:true}
                     />
                  </SwiperSlide>
                </Col>
              ))}
            </Col> 
            </InfiniteScroll>
            : (window.location.pathname === '/') ? <Col lg="12">
              <h4 style={{ fontFamily: "'Alegreya Sans', sans-serif", fontWeight: '600' }}>Latest Products</h4>
              <SliderWrapper
                type="single"
                slides={4}
                row={1}
                spacebetween={30}
                colors="#c74a2d"
                mobile="2"
                tablet="2"
                desktop="4"
              >
                {latest && latest.map((item, index) => (
                  <SwiperSlide key={item._id}>
                    <ProductCards data={item} wishlist={wishlist} soldOut={item.quantity?false:true} />
                  </SwiperSlide>
                ))}
                
              </SliderWrapper>
              {category && category.map((item, index) => (<Button key={Math.random(index + 1) * 520}
                className={`btn-rounded mr-2 mb-2 ${(activeCat===item)?'text-white':''}`}
                outline={(activeCat===item)?false:true}
                color="amber"
                onClick={() =>{Filterdata(item);setActiveCat(item);}}
              >
                {item}
              </Button>
              ))}
              <SliderWrapper
                type="single"
                slides={4}
                row={1}
                spacebetween={30}
                colors="#c74a2d"
                mobile="2"
                tablet="2"
                desktop="4"
              >
                {products && products.map((item) => (
                  <SwiperSlide key={item._id}>
                    <ProductCards data={item} wishlist={wishlist} soldOut={item.quantity?false:true} />
                  </SwiperSlide>
                ))}
              </SliderWrapper>
            </Col> : false
        ) : (
          <>
            {data.map((item, index) => (
              <Col lg="3" key={Math.random(index + 1) * 520}>
                <ProductCards data={item} wishlist={wishlist} />
              </Col>
            ))}
          </>
        )}




      </Row>
     {(window.location.pathname === '/')?(<div className="d-flex justify-content-center">
          <Link className="btn btn-amber btn-rounded px-4 text-white mb-2 mx-md-2 mb-md-0 nav-link d-inline-block" 
                    to="/all-product"
                    style={{fontWeight:'500'}}
                  >
                View all Our Products
         </Link>
      </div>):''}
    </Container>
  </div>
  );
}
export default AllProduct;
