import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

// Core components
import HeaderBreadcrumb from "../components/BreadCrumb";
import WrapContainer from "../components/container";
import Headimage from "../components/HeadImage/Headimage";
import ReactPlayer from 'react-player'
// APi
import Dataservices from "../services/requestApi";

const About = props => {
  const [, setProduct] = useState([]);
  const [mounted, setMounted] = useState(true);
  const token = sessionStorage.getItem("Authtoken");
  useEffect(
    () => {
      const getData = async e => {
        try {
          if (token) {
            const productRes = await Dataservices.GetCartAll();
            if (mounted) {
              setProduct(productRes.data.data);
            }
          }
        } catch (e) {
          console.log(e);
        }
      };
      getData();
      return () => {
        getData();
        setMounted(false);
      };
    },
    [mounted, token]
  );
  return (
    <div className="ashis">
      <WrapContainer>
        <HeaderBreadcrumb title="About" />
        <Headimage title="About Us" />
        <div
          className="about_us"
          style={{ padding: "10px", margin: "15px", opacity: 0.8 }}
        >
          <Container style={{ paddingTop: "60px" }}>
            <Row className="align-items-center">
              <Col lg="8">
                <div className="abt_content">
                  <h3>About Us</h3>
                  <p>
                    पंडितजी डॉट कॉम हम पंडित जी डॉट कॉम एक ऐसा मंच है जो पूरे
                    भारतवर्ष के धार्मिक लोगो को ज्योतिष कुंडली भाग्य व वैदिक
                    विधि से पूजा सम्पन्न करवाने के लिए ऑनलाइन मंच प्रदान करता
                    है। यहां हम आपके लिए अनुभवी विद्वान आचार्य शास्त्री पंडित
                    वर्ग को आपकी विधिवत पूजा के लिए व साथ मे सभी पूजन सामग्री
                    उपलब्ध करवाते है। हमारे यहां प्रत्येक पंडित जी हमारी वर्षो
                    से चली आ रही ऋषि परम्परा से गुरुकुल पाठशाला में पढ़े हुए
                    ज्ञान अर्जित किये हुए है। पंडित जी डॉट कॉम हमारा लक्ष्य है
                    के विद्वान पंडित जी के साथ ही सम्पूर्ण पूजन सामग्री आपको
                    उपलब्ध करवाए जिसे आप बाकी सभी चिंताओं से मुक्त होकर आंनद के
                    साथ पूजा कर पूण्य प्राप्त कर भाग्यशाली बने। पंडित जी डॉट कॉम
                    आपके कामनाओ की पूर्ति के लिए संकल्पित है आज ही बुक करें
                    pujyapanditG.com
                  </p>
                  {/* <a href="#!" className="btn btn-outline-amber btn-rounded">
                Know More
              </a> */}
                </div>
              </Col>
              <Col lg="4">
                <img
                  src="http://pujyapanditg.com/static/media/abt.5dda3a2e846abd4bc113.png"
                  alt="about"
                  className="img-fluid"
                />
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg="7">
                <div className="abt_content">
                  <h3>Who are we?</h3>
                  <p>
                    PujyaPanditG.com is a platform that provides You the
                    religious people of entire India to get worship done by
                    astrology, Kundli Bhagya and Vedic law. Here we provide you
                    for the pooja experienced scholar Acharya Shastri The
                    brainchild of a Acharya Yash , Pujyapanditg was started in
                    2018 to provide authentic astrology ,vastu & pooja services.
                    Pujyapanditg provides accurate predictions and practical
                    solutions for concerns regarding career, business, love, and
                    other areas of life. Operating from across India,
                    pujyapanditg has provided multilingual consultation to more
                    than 1.5 lakh satisfied customers in just a span of a few
                    years. And the number is increasing day after day.
                    Pujyapanditg is an authentic, affordable, and one-stop poja
                    path vastu and astrology destination.
                  </p>
                </div>
              </Col>
              <Col lg="5">
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url='https://www.youtube.com/watch?v=CnGtkB32pG4'
                    width='100%'
                    height='270px'
                  />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg="12">
                <div className="abt_content">
                  <p>
                    The best for pooja ,
                    astrology and user-friendly mobile app that will allow you
                    to instantly connect with Expert shastri Astrologers,
                    acharya Numerologists, and even Vastu Experts any time of
                    the day. The app offers personalized online consultation
                    across the globe on phone to address your concerns in life.
                    The services are provided by over 500+ verified experts who
                    can speak multiple languages. The accuracy of our
                    predictions and personalized consultations best acharya's
                    for pooja remains at the core of our business model.
                    Pujyapanditg is an ideal astrology app for knowing
                    personalized daily, monthly, and yearly horoscope
                    predictions. It also provides personalized matchmaking and
                    compatibility with spouse, love partner, friends,
                    colleagues, and bosses. You can enhance your home and office
                    based on Vastu tips. You can know the Muhurat timings for
                    the inauguration of office or house welcome parties and
                    auspicious functions like Marriage, Mundan, and menny more .
                    <br />
                    Promoting Indian culture as well as keeping in mind the
                    preferences of today's youth, we present to you a wonderful
                    range of stunning garments. Here we make available all the
                    worship materials for you to the experienced scholar Acharya
                    Shastri Pandit class for your duly worship. A team of
                    experienced astrologers and Vastu experts, who have received
                    high astrological education, connects you to the solution of
                    all your related problems. Every Pandit ji in our place has
                    acquired the knowledge studied in Gurukul Pathshala from the
                    Rishi tradition which has been going on for years.
                  </p>
                  <img src="/ppg-malaika.jpeg" alt="Malaika G" className="img-fluid" />
                  <h4>Founder’s Profile</h4>
                  <p>
                    Acharya Yash , the founder and CEO of PujyaPanditg, is a
                    firm believer in astrology. His belief in this Vedic science
                    is deeply embedded in his family value system. The accuracy
                    provided by Astrology always baffled him, and with every
                    accurate prediction, his belief got stronger. This accuracy
                    that Astrology provided always baffled him.
                    <br />
                    A detailed study
                    of these Vedic principles firmed his belief that astrology
                    is deeply rooted in science. According to him, there is a
                    rationale behind everything that astrology decodes.
                    <br />
                    The
                    family is deeply embedded in the value system. The accuracy
                    provided by astrology always amazed him, and with every
                    accurate prediction, his faith grew stronger. This accuracy
                    provided by astrology always amazed him.
                    <br />
                    A detailed study of
                    these Vedic principles reinforced his belief that astrology
                    is deeply rooted in science. According to him, there is a
                    logic behind everything that is decoded by astrology. After
                    completing his studies from Gurukul, he realized that people
                    have a lot of interest in worship, astrology, Vastu,
                    spirituality, but they are not getting that type of service,
                    that too even after investing a lot of time and money.
                    <br />
                    PujyapanditPanditg was launched to make worship lessons, the
                    ancient heritage of astrology accessible to all. With the
                    core mission of providing authentic personalized worship
                    astrological services at affordable prices, PujyaPanditg was
                    launched in 10 February 2018, at Divya Kumbh.
                  </p>
                  <h4>Our Vision</h4>
                  <p>
                    To help people across the globe have access to the ancient
                    wisdom of Vedic pooja path and astrology.
                  </p>
                  <h4>Our Mission</h4>
                  <p>
                    Our goal is to make available all the worship materials to
                    you along with the learned Pandit ji, so that you can be
                    free from all other worries and get lucky by worshiping with
                    joy.
                    <br />
                    Only highly educated astrologers, Vastu experts should
                    solve you so that your desired work can be accomplished.
                  </p>
                  <h4>Pujya pandit G Team</h4>
                  <p>
                    Pujya pandit g believes in offering nothing but the best
                    when it comes to pooja and astrological guidance.
                    <br />
                    We believe
                    that it is our mission to give authentic guidance to those
                    who are in need of astrological advice and pooja path . This
                    is why PujyaPandit g acharya astrologers have been
                    handpicked after a rigorous screening process to onboard
                    them. Bearing in mind the clients’ expectations, a strict
                    and systematic process for certification and validation of
                    the team of astrologers acharaya's pandit's has been
                    created. PujyapanditG.com is determined to fulfill your
                    wishes, book today pujyapanditG.com
                  </p>
                  <p>
                    Title: Pujyapanditg.com Shines Bright: Acharya Lakhan Honored with FSIA Best Astrologer Award by Malaika Arora
                  </p>
                  <br />
                  <p>
                    In a celestial celebration of digital excellence, Pujyapanditg.com's visionary founder and CEO, Acharya Lakhan, has been bestowed with the prestigious FSIA Best Astrologer Award. This illustrious recognition, graced by the radiant presence of Malaika Arora, signifies the fusion of traditional wisdom with the dynamic realm of online astrology.
                  </p>
                  <br />
                  <p>
                    Pujyapanditg.com, a digital sanctuary for seekers of astrological guidance, stands as a beacon of enlightenment in the vast expanse of the internet. Acharya Lakhan's strategic prowess in optimizing the platform with Google astrology keywords has elevated it to prominence, attracting seekers from every corner of the globe.
                  </p>
                  <p>
                    Through the strategic integration of Pujyapanditg.com with trending Google astrology keywords, Acharya Lakhan has facilitated an unparalleled digital journey for seekers, guiding them towards self-discovery and enlightenment. His adept navigation of online platforms has democratized access to astrology, ensuring that seekers can easily connect with celestial insights tailored to their individual journeys.
                  </p>
                  <p>
                    The FSIA Best Astrologer Award, presented by Malaika Arora, serves as a testament to Acharya Lakhan's pioneering efforts in the digital astrology sphere. His unwavering commitment to leveraging Pujyapanditg.com as a transformative tool for enlightenment has not only earned him widespread acclaim but has also empowered countless individuals on their quest for cosmic alignment.
                  </p>
                  <p>
                    In accepting the award, Acharya Lakhan expressed gratitude for the recognition of Pujyapanditg.com's digital endeavors, reaffirming his dedication to guiding seekers towards their celestial destinies. As Malaika Arora commended his innovative approach to astrology, she emphasized the platform's pivotal role in bridging the gap between ancient wisdom and modern technology.
                  </p>
                  <p>
                    With each click, Pujyapanditg.com continues to illuminate the digital landscape, offering seekers a pathway to self-discovery and empowerment. As Acharya Lakhan's digital footprint expands, fueled by the resonance of Google astrology keywords, the platform remains steadfast in its mission to enlighten and uplift humanity in the ever-evolving cosmos of online astrology.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </WrapContainer>
    </div>
  );
};
export default About;
