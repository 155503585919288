import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
// Core components
import AllArticles from "./AllArticles";
import AllPooja from "./AllPooja";
import AllProduct from "./AllProduct";
import Dataservices from "../../../services/requestApi";
import WrapContainer from "../../container";
import HeaderBreadcrumb from "../../BreadCrumb";

const Search = (props) => {
  const location = useLocation();
  const [product, setProduct] = useState([]);
  const [pooja, setPooja] = useState([]);
  const [articles, setArticle] = useState([]);
  const searchValue = location.pathname.split('/')[2];
  useEffect(() => {
    const getData = async () => {
      try {
        const [productRes, poojaRes, articlesRes] = await Promise.all([
          Dataservices.GetProductAll(),
          Dataservices.GetPoojaAll(),
          Dataservices.GetArticleAll(),
        ]);
        setProduct(productRes.data.data);
        setPooja(poojaRes.data.data);
        setArticle(articlesRes.data.data);
      } catch (e) {
        console.log(e);
      }
    };
  getData();
  }, []);
  const productSearch = product.filter(
    (product) =>
      product.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
  );
  const poojaSearch = pooja.filter(
    (pooja) =>
      pooja.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
  );
  const articleSearch = articles.filter(
    (article) =>
      article.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
  );
  return (
    <>
      <WrapContainer>
      <HeaderBreadcrumb title={`Search/${searchValue}`} />
      {productSearch.length > 0 ? (
        <AllProduct data={productSearch}  />

      ) : (
        null
      )}
      {poojaSearch.length > 0 ? (
        <AllPooja data={poojaSearch} />
      ) : (
        null
      )}
      {articleSearch.length > 0 ? (
        <AllArticles data={articleSearch} />
      ) : (
        null
      )}
      </WrapContainer>
    </>
  );
};
export default Search;