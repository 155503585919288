import React, { useState } from "react";
import Headimage from "../../../components/HeadImage/Headimage";
import WrapContainer from "../../../components/container";
import HeaderBreadcrumb from "../../../components/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Row,Col, Collapse } from "reactstrap";
import "@lottiefiles/lottie-player";
import "../../../pages/Kundali/Kundali.css"

// import "./Kundali.css";
// import DataService from "../../services/requestApi";
// import queryString from "query-string";


const KundaliCard = (props) => {
    const [isOpen, setIsOpen] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const token = sessionStorage.getItem("Authtoken");
   const validateCoupon = () => {
    (coupon === process.env.REACT_APP_COUPAN_CODE)?setIsCouponApplied(1):setIsCouponApplied(-1);
  }
  return (
    <div className="all_product">
        <div className="container">
          {/* description */}
          <Row>
          <Col lg="12">
          <div className="heading">
          <h1>
         
                  <Link to={"/all-article"}>
                  {/* <span style={{ color:'black' }}>Our&nbsp;</span> */}
                  <span>Astrology</span></Link>
            </h1>
          </div>
        </Col>
          </Row>
        {/*  {props.data && props.data.map((item,key) => {
            return (
                <Row key={key}>
              <div className="card kun mt-3 mb-3">
                <div className="card-header d-flex">
                  <h3
                    style={{
                      fontSize: "4rem",
                      color: "#e9963e",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {item.title}
                  </h3>
                  <img
                    style={{ height: "50px", width: "50px" }}
                    src={item.headImage}
                    alt="kundali"
                    className="img-fluid mt-md-3 m-2"
                  />
                  {/* <!-- discount section --> */}
                 {/* {item.coupon&&<div className="discount_code mt-3 ml-auto">
                    <div className="card">
                      <div className="card-body p-3">
                        <h6 className="d-flex justify-content-between" onClick={toggle}>Add a discount code (optional) &nbsp; <span><i className="fas fa-chevron-down pt-1" /></span></h6>
                        <Collapse isOpen={isOpen}>
                          <div className="mt-3">
                            <input type="text" name onChange={(e)=>setCoupon(e.target.value)} value={coupon} id="voucher" className="form-control fw-bold mb-0" placeholder="Enter coupon code" />
                            {isCouponApplied&&<small className={`text-${isCouponApplied==1?'success':'danger'} mt-3`}>{isCouponApplied==1?`Hooray!🎉 you save ₹${item.Price/100*90}`:'Invalid Coupon!😵 Try Again'}</small>}
                          </div>
                          <button className="btn btn-primary btn-sm mt-2" onClick={validateCoupon}>Apply</button>
                          </Collapse>
                      </div>
                    </div>
                  </div>}

                  {/* <!-- discount code end --> 
                </div>
                <div className="Card-body col-md-12  ">
                  <div className="kundaliCard">
                  <div className="col-md-4 ">
                    <div className="image-container">
                      <img
                        src={item.Image}
                        alt="kundali"
                        className="img-fluid"
                      />
                    </div>
                    <div className="ml-5 px-5">
                      <h3 style={{ color: "#e9963e" }}>
                        ₹ {item.Price}
                        <span className="text-muted small">
                          {" "}
                          ₹<del> {item.mrp} </del>
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="content">
                      <h3 className=" d-flex" style={{ gap: "10px" }}>
                        <img
                          style={{ height: "50px" }}
                          src={require("../../../assets/img/flag.png")}
                          alt="kundali"
                          className="img-fluid"
                        />
                        <span className=" text">{item.Ctitle}</span>
                      </h3>
                      <ul>
                        {item.listData &&
                          item.listData.map((item, index) => (
                            <li key={index}>
                              <span className="text-bold">{item}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  </div>
                <div className="col-md-12 card-footer">
                  {token ? (
                    <Link to={{pathname:"/kundali", state:{ title :item.title,price:item.Price, giveCoupon: item.coupon} }}>
                  <div
                    className="btn btn-block ml-auto"
                    style={{ backgroundColor: "#e9963e", color: "#fff" }}
                  >
                    Click here to Get the Kundali
                  </div>
                  </Link>
                  ) : (
                    <Link to="/login">
                  <div
                    className="btn btn-block ml-auto"
                    style={{ backgroundColor: "#e9963e", color: "#fff" }}
                  >
                    Click here to Get the Kundali
                  </div>
                  </Link>
                  )}

                </div>
                </div>
              </div>
              </Row>
            );
          })}*/}
          <div className="row mb-5">
                        <div className="col-12 col-sm-6 col-md-4">
                            <Link to="/KundaliCard" className="card border-0 card-purple-blue text-white mb-3 mb-md-0">
                                <div className="card-body d-flex justify-content-between align-items-end">
                                    <div className="card-number mb-auto">
                                        <div className="h3 m-0">Kundali</div>
                                    </div>
                    <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_9fOOD0FOYB.json" background="transparent" speed="2.5" style={{width: '170px', height: '170px'}} loop autoPlay />
                                    
                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4">
                          <Link to="/couple-matching" className="card border-0 card-salmon-pink text-white mb-3 mb-md-0">
                                <div className="card-body d-flex justify-content-between align-items-end">
                                    <div className="card-number mb-auto">
                                        <div className="h3 m-0">Matching</div>
                                    </div>
                                    {/* <div className="card-description text-right"><small>890</small><br /><small>16.40%</small></div> */}
                    <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_9wzu5dlu.json" background="transparent" speed="2.5" style={{width: '170px', height: '170px'}} loop autoPlay />

                                </div>
                            </Link>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4">
                            <Link to="/advanced-panchang" className="card border-0 card-purple-pink text-white mb-3 mb-md-0">
                                <div className="card-body d-flex justify-content-between align-items-end">
                                    <div className="card-number mb-auto">
                                        <div className="h3 m-0">Panchang</div>
                                    </div>
                    <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_ilp95ggh.json" background="transparent" speed="1" style={{width: '170px', height: '170px'}} loop autoPlay />

                                </div>
                            </Link>
                        </div>
<></>

                    </div>
        </div>
        
        {/* </div> */}
        {/* </div> */}
      
    </div>
  );
};
export default KundaliCard;
