import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Core components
import Loader from "./components/loader";
import Search from "./components/sections/Home/Search";
import Cart from "./pages/Cart";
import CheckOut from "./pages/CheckOut";
import About from "./pages/About";
import SanatanDharma from "./pages/SanatanDharama";
import ContactUS from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Refund from "./pages/Refund";
import ShippingPolicy from "./pages/ShippingPolicy";
import Termsandconditions from "./pages/Termsandconditions";
import AllOrder from "./pages/user/AllOrders";
import WishList from "./pages/Wishlist";
import PoojaPath from "./pages/PoojaPath";
import Vedh from "./pages/vedh";
import Astrology from "./pages/Kundali/Astrology";
import Matching from "./pages/Kundali/Matching";

import Stotra from "./pages/stotra"
import BuyNow from './pages/BuyNow';
import Return from "./pages/Return";
import Notify from "./pages/Notify";
import ViewKundali from "./pages/Kundali/ViewKundali";
import AdvancedPanchang from "./pages/Kundali/AdvancedPanchang";
import PoojaReturn from "./pages/PoojaReturn";
import Kundali from "./pages/Kundali/Kundali";
import KundaliCard from "./pages/Kundali/KundaliCard";
import EpoojaBook from "./pages/E-pooja/EpoojaBook";
import Epuja from "./pages/E-pooja/Epuja";
import Kreturn from "./pages/Kundali/Kreturn";
const HomePage = lazy(() => import("./pages/Home"));
const AllProduct = lazy(() => import("./pages/ProductAll"));
const ProductDetails = lazy(() => import("./pages/ProductDetails"));
const AllArticle = lazy(() => import("./pages/ArticleAll"));
const ArticlesDetails = lazy(() => import("./pages/ArticlesDetails"));
const AllPooja = lazy(() => import("./pages/PoojaAll"));
const PoojaDetails = lazy(() => import("./pages/PoojaDetails"));
const RegisterAsPandit = lazy(() => import("./pages/Auth/RegisterAsPandit"));


// Auth
const Login = lazy(() => import("./pages/Auth/Login"));
const Register = lazy(() => import("./pages/Auth/Register"));
const Forgot = lazy(() => import("./pages/Auth/Forgot"));
const Profile = lazy(() => import("./pages/user/Profile"));

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Suspense fallback={<Loader />}>
            <Route path="/" exact render={(props) => <HomePage {...props} />} />
            <Route
              path="/all-product"
              render={(props) => <AllProduct {...props} />}
            />
            <Route
              path="/wishlist"
              render={(props) => <WishList {...props} />}
            />
            <Route path="/cart" render={(props) => <Cart {...props} />} />
            <Route
              path="/product/:id"
              render={(props) => <ProductDetails {...props} />}
            />
            <Route
              path="/all-article"
              render={(props) => <AllArticle {...props} />}
            />
            <Route
              path="/article/:id"
              render={(props) => <ArticlesDetails {...props} />}
            />
            <Route
              path="/all-pooja"
              render={(props) => <AllPooja {...props} />}
            />
            <Route
              path="/pooja/:id"
              render={(props) => <PoojaDetails {...props} />}
            />
            <Route path="/login" render={(props) => <Login {...props} />} />
            <Route
              path="/register"
              render={(props) => <Register {...props} />}
            />
            <Route
              path="/register-as-pandit"
              render={props => <RegisterAsPandit {...props} />}
            />
            <Route
              path="/Forgot-Password"
              render={(props) => <Forgot {...props} />}
            />
            <Route path="/profile" render={(props) => <Profile {...props} />} />

            <Route path="/orders" render={(props) => <AllOrder {...props} />} />
            <Route path="/about" render={(props) => <About {...props} />} />
            <Route path="/STDH" render={(props) => <SanatanDharma {...props} />} />
            <Route path="/ContactUS" render={(props) => <ContactUS {...props} />} />
            <Route path="/PrivacyPolicy" render={(props) => <PrivacyPolicy {...props} />} />
            <Route path="/PoojaPath" render={(props) => <PoojaPath {...props} />} />
            <Route path="/buy-now/:id" render={(props) => <BuyNow {...props} />} />
            <Route path="/Vedh" render={(props) => <Vedh {...props} />} />
            <Route path="/Stotra" render={(props) => <Stotra {...props} />} />
            <Route path="/search/:search" render={(props) => <Search {...props} />} />
            <Route path="/return"  render={() => <Return/>} />
            <Route path="/notify"  render={() => <Notify/>} />
            <Route path="/Pooja_return"  render={() => <PoojaReturn/>} />
            <Route path="/kundali"  render={() => <Kundali/>} />
           <Route path="/EpoojaBook" render={() => <EpoojaBook/>}/>
           <Route path="/Epuja" render={() => <Epuja/>}/>
           <Route path="/KundaliCard" render={() => <KundaliCard/>}/>
                      <Route path="/astrology" render={() => <Astrology/>}/>
           <Route path="/view/kundali/:id" exact render={() => <ViewKundali/>}/>

           <Route path="/Kreturn" render={() => <Kreturn/>}/>
           <Route path="/Termsandconditions" render={() => <Termsandconditions/>}/>
           <Route path="/Refund" render={() => <Refund />} />
           <Route path="/ShippingPolicy" render={() => <ShippingPolicy />} />
           <Route path="/advanced-panchang" render={() => <AdvancedPanchang />} />
           <Route path="/couple-matching" render={() => <Matching />}/>

            <Route
              path="/checkout/:id"
              render={(props) => <CheckOut {...props} />}
            />
          </Suspense>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
