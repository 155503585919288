import React from "react";
import { Col, Container, Row , Spinner} from "reactstrap";
import { SwiperSlide } from "swiper/react";

// Core components
import ArticleCards from "../../Cards/ArticleCard";
import SliderWrapper from "../../Slider";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";


const AllArticles = ({ data, slider,title, totalResults, fetchMoreData }) => (
  <div className="all_product">
    <Container>
      <Row >
        <Col lg="12">
          <div className="heading">
          <h1>
              {title ? (
                <>
                  {/* <span>{title}</span> */}
                </>
              ) : (
                <>
                  <Link to={"/all-article"}>
                  {/* <span style={{ color:'black' }}>Our&nbsp;</span> */}
                  <span>Articles</span></Link>
                </>
              )}
            </h1>
          </div>
        </Col>
        {slider ? (
          <Col lg="12">
            <SliderWrapper
              type="single"
              slides={4}
              row={1}
              spacebetween={30}
              colors="#c74a2d"
              mobile="2"
              tablet="2"
              desktop="4"
            >
              {data.map((item) => (
                <SwiperSlide key={item._id}>
                  <ArticleCards data={item} />
                </SwiperSlide>
              ))}
            </SliderWrapper>
          </Col>
        ) : (
        <div id="scrollableDiv" className="col">
          <InfiniteScroll style={{ overflow: 'unset' }}
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={data.length !== totalResults}
          scrollThreshold={0.6}
          className={`col-12`}
          loader={
          <div className="container d-flex align-items-center justify-content-center my-5">
          <Spinner />
            </div>}>
        <Col className="row">

            {data.map((item) => (
              <Col lg="3"  key={item._id}>
                <ArticleCards data={item} />
              </Col>
            ))}
                    </Col> 

            </InfiniteScroll>
            </div>
        )}
      </Row>
         {(window.location.pathname=== '/')?(<div className="d-flex justify-content-center">
          <Link className="btn btn-amber btn-rounded px-4 text-white mb-2 mx-md-2 mb-md-0 nav-link d-inline-block" 
                    to="/all-article"
                    style={{fontWeight:'500'}}
                  >
                View all Our Articles
         </Link>
      </div>):''}
    </Container>
  </div>
);
export default AllArticles;
