import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

//bootstrap
import { Button, Card, CardBody, CardTitle } from "reactstrap";

// APi
import Dataservices from "../../services/requestApi";
import {BASEURL} from '../../services/http-common';
import queryString from "query-string";
import { message } from "antd";
import outOfStock from "./out-of-stock.png"

const ProductCards = ({ data, wishlist,soldOut }) => {
    
    
    // filter data
    
    
    
    
  const [userdata, setuserData] = useState([]);
  const [isLogedin, setIsLogedin] = useState(false);
  const [mounted, setMounted] = useState(true);
  let history = useHistory();
  useEffect(() => {
    const getData = async () => {
      try {
        const token = sessionStorage.getItem("Authtoken");
        if (token === null) {
          setIsLogedin(false);
        } else if (token) {
          const res = await Dataservices.GetProfileData();
          if (mounted) {
            setuserData(res.data.data);
            setIsLogedin(true);
          }
        }
      } catch (e) {
        console.log(e.error);
      }
    };
    getData();
    return () => {
      // getData();
      setMounted(true);
    };
  }, [mounted]);
  const Wishlist = async (e, id) => {
    e.preventDefault();
    try {
      const Data = {
        user: userdata._id,
        product: id,
        status: 0,
      };
      if (isLogedin) {
        const res = await Dataservices.CartCreate(queryString.stringify(Data));
        console.log("res",res.data);
        if (res.data.status_code === 200) {
          message.success(res.data.message);
          history.push("/wishlist");
        } else {
          message.error(res.data.message);
        }
      } else {
        message.error("You have to login first");
        history.push("/login");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // console.log("data.price",data.price)
  // console.log("data.mrp",data.mrp)
  // console.log("data",data)
  // console.log("data.variants",data.variants)
  // console.log("data.variants[0].price",data.variants[0].price)
  // console.log("data.variants[0].mrp",data.variants[0].mrp)
  
  return (
   
    <Card className="product_cards text-center" style={{borderRadius:'25px',overflow:'hidden  '}}>
      <div className="img_wraped position-relative" style={{height:'200px'}}>
        <img
          className="img-fluid rounded"
          // src={`${BASEURL.ENDPOINT_URL}${data.image[0].image_link}`}
          src={data.image.length>0?`${BASEURL.ENDPOINT_URL}${data.image[0].image_link}`:`${BASEURL.ENDPOINT_URL}${data.image_link}`}
          alt={data.title}
        //   onClick={() => console.log(`${BASEURL.ENDPOINT_URL}`)}
          loading="lazy"
          style={{height:'100%'}}
        />
        {Boolean(soldOut) &&<div className="out_of_stock">
            <img alt="out of stock" src={outOfStock} style={{width:'100px',height:'100px'}}/>
        </div>}
      </div>
      {wishlist && (
        <div className="wishlist">
          <Button onClick={(e) => Wishlist(e, data._id)}>
            <i className="lnr lnr-heart" />
          </Button>
        </div>
      )}
      <CardBody>
        <CardTitle className="text-truncate">{data.name}</CardTitle>
        {data.price && (
          <h5>
            Rs. {data.price} - <del style={{color:'#c74a2d'}}>( Rs. {data.mrp} )</del>
          </h5>
        )}
        <div className="actions">
        <Button
            className="btn-rounded"
            to={`/product/${data.slug}`}
            tag={Link}
            outline
            color="amber"
            disabled={soldOut?true:false}
          >
            Buy Product
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};
export default ProductCards;
