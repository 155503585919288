import React, { useEffect, useState } from "react";
import DummyModal from "../../components/Modal";
import { Button, Col, Input, FormGroup, Row, Form } from "reactstrap";
import { State, City } from 'country-state-city';
import Dataservices from "../../services/requestApi";
import Swal from "sweetalert2";
import queryString from "query-string";

function Modify({ visible, handleModal, add }) {

  const [address, setAddress] = useState({});
  const [cities, setCities] = useState([])

  let states = State.getStatesOfCountry("IN");

  const updatedCities = (stateId) => {
    setCities(City.getCitiesOfState("IN", stateId));
  }

  useEffect(() => {
    console.log(add);
    if(add) {
      setAddress(add);
      updatedCities(add.state)
    }
    return () => {
      setAddress({})
    }
  }, [add])
  
  const showAlert = (icon, title, text) => {
    Swal.fire({
      icon,
      title,
      text,
      confirmButtonText: 'Ok',
    });
  };
  const updateAddress = async (e) => {
    try {
      e.preventDefault();
      const { full_name: name, email, Mobile_number: phone, address:localAdd, city, state, country, pincode, landmark, sector } = address;
      console.log(address);
      if (!name || !email || !phone || !localAdd || !city || !state || !pincode || !landmark || !sector)
        return showAlert('error', 'Error', "Please fill all fields!");

      if (phone.toString().length != 10 || isNaN(phone))
        return showAlert('error', 'Error', "Please enter a valid phone number!");

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        return showAlert('error', 'Error', "Please enter a valid email Id!");
      }

      const indiaPostalCodeRegex = /^\d{6}$/;
      if (!indiaPostalCodeRegex.test(pincode)) {
        return showAlert('error', 'Error', "Please enter 6 digit Pincode!");
      }
      const response = await Dataservices.updateAddress(address._id,queryString.stringify(address));
      if (response.status === 200 && response.data.success) {
        const { data } = response.data;
        const getAuth = sessionStorage.getItem("Authtoken");
        const Auth = JSON.parse(getAuth);
        const { data: user } = Auth;
        user.User_address = data;
        sessionStorage.setItem("Authtoken", JSON.stringify(Auth));
        showAlert('success', 'Success', "Address Updated Successfully!");
        handleModal();
      }
      else {
        showAlert('error', 'Error', "Something went wrong!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (!address) return null;

  return (
    <DummyModal
      visible={visible}
      width="650px"
      title="Modify Your Address!"
      hidefooter={null}
      handlemodal={handleModal}
    >
      <Form onSubmit={updateAddress}>
        <Row className="justify-content-end">
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="fullname">
                Full Name
              </label>
              <Input
                className="form-control-alternative"
                id="fullname"
                placeholder="Enter your full name"
                value={address.full_name}
                type="text"
                required
                onChange={(e) => setAddress({ ...address, full_name: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="email">
                Email
              </label>
              <Input
                className="form-control-alternative"
                id="email"
                placeholder="Enter your email"
                value={address.email}
                type="email"
                required
                onChange={(e) => setAddress({ ...address, email: e.target.value })}
              />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="phone_number">
                Mobile Number
              </label>
              <Input
                className="form-control-alternative"
                id="phone_number"
                placeholder="Enter your mobile number"
                value={address.Mobile_number}
                type="text"
                required
                onChange={(e) => setAddress({ ...address, Mobile_number: e.target.value })}
              />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="address">
                Flat, House no., Building, Company, Apartment
              </label>
              <Input
                className="form-control-alternative"
                id="address"
                placeholder="Enter your address"
                value={address.address}
                type="text"
                required
                onChange={(e) => setAddress({ ...address, address: e.target.value })}
              />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="sector">
                Area, Street, Sector, Village
              </label>
              <Input
                className="form-control-alternative"
                id="sector"
                placeholder="Enter your address"
                value={address.sector}
                type="text"
                required
                onChange={(e) => setAddress({ ...address, sector: e.target.value })}
              />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="landmark">
                Landmark
              </label>
              <Input
                className="form-control-alternative"
                id="landmark"
                placeholder="Enter your address"
                value={address.landmark}
                type="text"
                required
                onChange={(e) => setAddress({ ...address, landmark: e.target.value })}
              />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="state">
                State
              </label>
              <Input
                className="form-control-alternative"
                id="state"
                name="state"
                type="select"
                placeholder="Select a state"
                // value={address.state}
                onChange={(e) => { updatedCities(e.target.value); setAddress({ ...address, state: e.target.value }) }}
              >
                {states.map((state,index) => (
                  <option key={index} selected={address.state === state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="city">
                city
              </label>
              <Input
                className="form-control-alternative"
                id="city"
                name="city"
                placeholder="Select a city"
                // value={address.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
                type="select"
              >
                {cities.map((city, index) => (
                  <option key={index} selected={address.city === city.name} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="pincode">
                Pincode
              </label>
              <Input
                className="form-control-alternative"
                id="pincode"
                placeholder="Enter your pincode"
                value={address.pincode}
                type="text"
                required
                onChange={(e) => setAddress({ ...address, pincode: e.target.value })}
              />
            </FormGroup>
          </Col>

          <Col lg="12" className="d-flex" style={{ justifyContent: 'space-between' }}>
            <FormGroup className="mb-0 w-100">
              <Button
                className="py-2 btn-rounded text-white"
                color="amber"
                type="submit"
                block
              >
                Update
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </DummyModal>
  )
}

export default Modify