import React, { useEffect, useState } from "react";
//css
import "../assets/scss/Contact.css";
// Core components
import HeaderBreadcrumb from "../components/BreadCrumb";
import WrapContainer from "../components/container";
import Headimage from "../components/HeadImage/Headimage";

// APi
import Dataservices from "../services/requestApi";

const ContactUS = (props) => {
  const [, setProduct] = useState([]);
  const [mounted, setMounted] = useState(true);
  const token = sessionStorage.getItem("Authtoken");
  useEffect(() => {
    const getData = async (e) => {
      try {
        if (token) {
          const productRes = await Dataservices.GetCartAll();
          if (mounted) {
            setProduct(productRes.data.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      getData();
      setMounted(false);
    };
  }, [mounted, token]);
  return (
    <div className="Contact">
      <WrapContainer>
        <HeaderBreadcrumb title="Contact Us" />
     <Headimage title="Contact-us" text="Have any queries? We'd love to hear from you"/>

        <section
          className="contact-info ng-scope"
          ng-controller="AppController"
        >
          <div className="container">
            <div className="row">
              <div className="row wrapper px-2 py-2 d-flex col-md-12 p-5 align-items-center h-auto">
              <div className="col-md-6 col-12" ng-init="formData={}">
                <form
                  name="addpackage"
                  ng-submit="create('page/add-contact', formData)"
                  noValidate
                  className="form-cm-styles ng-pristine ng-valid"
                >
                  <h4 className="form-title mb-4 ml-5 ">
                    Fill in the details for the enquiry form.
                  </h4>
                  <div className="form-row">
                    <div className="col-md-10 col-12 m-auto">
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        className="form-control ng-pristine ng-untouched ng-valid ng-empty"
                        ng-model="formData.first_name"
                        placeholder="First name *"
                      />
                    </div>
                    <div className="col-md-10 col-12 m-auto">
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        className="form-control ng-pristine ng-untouched ng-valid ng-empty"
                        ng-model="formData.last_name"
                        placeholder="Last name (optional)"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-10 col-12 m-auto">
                      <input
                        type="text"
                        className="form-control ng-pristine ng-untouched ng-valid ng-empty"
                        id="phone"
                        name="phone"
                        ng-model="formData.phone"
                        placeholder="Phone *"
                      />
                    </div>
                    <div className="col-md-10 col-12 m-auto">
                      <input
                        type="text"
                        className="form-control ng-pristine ng-untouched ng-valid ng-empty"
                        id="email"
                        name="email"
                        ng-model="formData.email"
                        placeholder="Email *"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-10 col-12 m-auto">
                      <textarea
                        name="content"
                        id="content"
                        ng-model="formData.content"
                        className="form-control ng-pristine ng-untouched ng-valid ng-empty"
                        placeholder="Please write message here *"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="form-row mt-3">
                    <div className="col-md-6 col-12 ">
                      <button
                        ng-disabled="inProcess"
                        type="submit"
                        className="text-light mb-4 btn-block Button btn-lg btn-xs-sm"
                      >
                        <span
                          ng-if="!inProcess"
                          className="text-white ng-scope"
                        >
                          Submit
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6 col-12">
                <div className="talk-to-us">
                  <p className="title"> Talk to us </p>
                  <p className="contact-text">
                    Pujya Pandit G is the India's most trusted online platform
                    for Hindu puja service, Vedic Rituals, Religious Ceremonies
                    and Astrology Services. We provide the India's well-known,
                    highly qualified and experienced Shastries and Pandits for
                    you who will come to your house and do the puja. Our
                    services also include online Puja Samagri or Puja Items.
                    Pujya Pandit G provides authentic personalised poja path and
                    astrological services at affordable prices through web , iOS
                    and Android app.
                  </p>
                </div>
              <div className="row">
                  <div className="col-md-3 col-6">
                    <div className="phone">
                      {/*<div className="circle flex-center">
                         <i className="fas fa-phone" />
                      </div>*/}
                      <p className="title"> Phone </p>
                    </div>
                    <p className="text-orange">
                      <a href="tel: +91 9610800108">+91-9610800108</a>
                    </p>
                  </div>
                  <div className="col-md-3 col-6">
                    <div className="email">
                      {/*<div className="circle flex-center">
                        <i className="fas fa-envelope" />
                      </div>*/}
                      <p className="title"> Email </p>
                    </div>
                    <p className="text-orange">
                      <a href="mailto: info@pujyapanditg.com">
                        info@pujyapanditg.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="Address">
                  <p className="title"> Address </p>
                  <p className="contact-text">
                    PPG Astrology Services Pvt. Ltd. <br />
                    Dodhasar, Jaipur, <br />
                    Rajasthan - 303712{" "}
                  </p>
                </div> 
                <div className="social-media">
                  <p className="title"> Social media </p>
                  <ul className="social-media">
                    <a
                      href=" https://www.facebook.com/PujyapanditG/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li>
                        <div className="facebook flex-center">
                          <i className="fab fa-facebook-f" />
                        </div>
                      </li>
                    </a>
                    <a
                      href=" https://twitter.com/pujyapandit?s=08"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li>
                        <div className="twitter flex-center">
                          <i className="fab fa-twitter" />
                        </div>
                      </li>
                    </a>
                    <a
                      href=" https://instagram.com/pujyapandit.g?igshid=YmMyMTA2M2Y="
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li>
                        <div className="instagram flex-center">
                          <i className="fab fa-instagram" />
                        </div>
                      </li>
                    </a>
                    <a
                    href=" https://youtube.com/channel/UCtiWv_IS_PTm68htmn8ge5g"
                    target={'_blank'}
                    rel="noopener noreferrer"
                    >
                      <li>
                        <div className="youtube flex-center">
                          <i className="fab fa-youtube" />
                        </div>
                      </li>
                    </a>
                    <a href="info@pujyapanditg.com"
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <li>
                        <div className="email flex-center">
                          <i className="fas fa-envelope" />
                        </div>
                      </li>
                    </a>

                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </WrapContainer>
    </div>
  );
};
export default ContactUS;