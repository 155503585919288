import React from "react";
//css
import "../assets/scss/Contact.css";
// Core components
import HeaderBreadcrumb from "../components/BreadCrumb";
import WrapContainer from "../components/container";
import Headimage from "../components/HeadImage/Headimage";

// APi
// import Dataservices from "../services/requestApi";

const PrivacyPolicy = () => {
  return (
    <div className="Contact">
      <WrapContainer>
        <HeaderBreadcrumb title="Privacy Policy" />
        <Headimage
          title="Privacy Policy"
        //   text="Have any queries? We'd love to hear from you"
        />
        <div className="container">
          <div className="row">
            <div className="px-2 py-2 d-flex col-lg-12 p-4">
              <div className="card">
                <div className="card-body">
                  <p className="card-text">
                    <h3>PRIVACY POLICY</h3>
                    <p className="text-muted">Effective date: 2022-06-01</p>
                    <h5>1. Introduction</h5>
                    <p>
                      Welcome to Pujyapanditg private limited.Pujyapanditg
                      private limited (“us”, “we”, or “our”) operates
                      PujyaPanditg.com (hereinafter referred to as “Service”).
                      Our Privacy Policy governs your visit to PujyaPanditg.com,
                      and explains how we collect, safeguard and disclose
                      information that results from your use of our Service. We
                      use your data to provide and improve Service. By using
                      Service, you agree to the collection and use of
                      information in accordance with this policy. Unless
                      otherwise defined in this Privacy Policy, the terms used
                      in this Privacy Policy have the same meanings as in our
                      Terms and Conditions. Our Terms and Conditions (“Terms”)
                      govern all use of our Service and together with the
                      Privacy Policy constitutes your agreement with us
                      (“agreement”).
                    </p>
                    <h5>2. Definitions SERVICE </h5>
                    means the PujyaPanditg.com website operated by Pujyapanditg
                    private limited. PERSONAL DATA means data about a living
                    individual who can be identified from those data (or from
                    those and other information either in our possession or
                    likely to come into our possession). USAGE DATA is data
                    collected automatically either generated by the use of
                    Service or from Service infrastructure itself (for example,
                    the duration of a page visit). COOKIES are small files
                    stored on your device (computer or mobile device). DATA
                    CONTROLLER means a natural or legal person who (either alone
                    or jointly or in common with other persons) determines the
                    purposes for which and the manner in which any personal data
                    are, or are to be, processed. For the purpose of this
                    Privacy Policy, we are a Data Controller of your data. DATA
                    PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
                    person who processes the data on behalf of the Data
                    Controller. We may use the services of various Service
                    Providers in order to process your data more effectively.
                    DATA SUBJECT is any living individual who is the subject of
                    Personal Data. THE USER is the individual using our Service.
                    The User corresponds to the Data Subject, who is the subject
                    of Personal Data.
                    <h5 className="mt-1">3. Information Collection and Use</h5>
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                    <h5>4.Types of Data Collected</h5>
                    Personal Data While using our Service, we may ask you to
                    provide us with certain personally identifiable information
                    that can be used to contact or identify you (“Personal
                    Data”). Personally identifiable information may include, but
                    is not limited to:
                    <ul style={{ listStyle: "none" }}>
                      <li>0.1. Email address</li>
                      <li>0.2. First name and last name</li>
                      <li>0.3. Phone number</li>
                      <li>
                        0.4. Address, State, Province, ZIP/Postal code, City
                      </li>
                      <li>0.5. Cookies and Usage Data</li>
                    </ul>
                    <span>
                      We may use your Personal Data to contact you with
                      newsletters, marketing or promotional materials and other
                      information that may be of interest to you. You may opt
                      out of receiving any, or all, of these communications from
                      us by following the unsubscribe link.
                    </span>
                    <h6>Usage Data</h6>
                    <span>
                      We may also collect information that your browser sends
                      whenever you visit our Service or when you access Service
                      by or through any device (“Usage Data”). This Usage Data
                      may include information such as your computer’s Internet
                      Protocol address (e.g. IP address), browser type, browser
                      version, the pages of our Service that you visit, the time
                      and date of your visit, the time spent on those pages,
                      unique device identifiers and other diagnostic data. When
                      you access Service with a device, this Usage Data may
                      include information such as the type of device you use,
                      your device unique ID, the IP address of your device, your
                      device operating system, the type of Internet browser you
                      use, unique device identifiers and other diagnostic data.
                    </span>
                    <h6>Location Data</h6>
                    <span>
                      We may use and store information about your location if
                      you give us permission to do so (“Location Data”). We use
                      this data to provide features of our Service, to improve
                      and customize our Service. You can enable or disable
                      location services when you use our Service at any time by
                      way of your device settings.
                    </span>
                    <h6>Tracking Cookies Data</h6>
                    <span>
                      We use cookies and similar tracking technologies to track
                      the activity on our Service and we hold certain
                      information. Cookies are files with a small amount of data
                      which may include an anonymous unique identifier. Cookies
                      are sent to your browser from a website and stored on your
                      device. Other tracking technologies are also used such as
                      beacons, tags and scripts to collect and track information
                      and to improve and analyze our Service. You can instruct
                      your browser to refuse all cookies or to indicate when a
                      cookie is being sent. However, if you do not accept
                      cookies, you may not be able to use some portions of our
                      Service.
                    </span>
                    <h6>examples of Cookies we use:</h6>
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        0.1. Session Cookies. We use Session Cookies to operate
                        our Service.
                      </li>
                      <li>
                        0.2. Preference Cookies. We use Preference Cookies to
                        remember your preferences and various settings.
                      </li>
                      <li>
                        0.3. Security Cookies. We use Security Cookies for
                        security purposes.
                      </li>
                      <li>
                        0.4. Advertising Cookies. Advertising Cookies are used
                        to serve you with advertisements that may be relevant to
                        you and your interests.
                      </li>
                    </ul>
                    <h6>other data</h6>
                    <span>
                      While using our Service, we may also collect the following
                      information: sex, age, date of birth, place of birth,
                      passport details, citizenship, registration at place of
                      residence and actual address, telephone number (work,
                      mobile), details of documents on education, qualification,
                      professional training, employment agreements, NDA
                      agreements, information on bonuses and compensation,
                      information on marital status, family members, social
                      security (or other taxpayer identification) number, office
                      location and other data.
                    </span>
                    <h5>5.Use of Data</h5>
                    <span>
                      Pujyapanditg private limited uses the collected data for
                      various purposes:
                    </span>
                    <ul style={{ listStyle: "none" }}>
                      <li>0.1. To provide and maintain our Service</li>
                      <li>0.2. To notify you about changes to our Service</li>
                      <li>
                        0.3. To allow you to participate in interactive features
                        of our Service when you choose to do so
                      </li>
                      <li>0.4. To provide customer care and support</li>
                      <li>
                        0.5. To provide analysis or valuable information so that
                        we can improve the Service
                      </li>
                      <li>0.6. To monitor the usage of the Service</li>
                      <li>
                        0.7. To detect, prevent and address technical issues
                      </li>
                      <li>
                        0.8. to fulfil any other purpose for which you provide
                        it;
                      </li>
                      <li>
                        0.9.to carry out our obligations and enforce our rights
                        arising from any contracts entered into between you and
                        us, including for billing and collection;
                      </li>
                      <li>
                        0.10. to provide you with notices about your account
                        and/or subscription, including expiration and renewal
                        notices, email-instructions, etc.;
                      </li>
                      <li>
                        0.11. to provide you with news, special offers and
                        general information about other goods, services and
                        events which we offer that are similar to those that you
                        have already purchased or enquired about unless you have
                        opted not to receive such information;
                      </li>
                      <li>
                        0.12. in any other way we may describe when you provide
                        the information;
                      </li>
                      <li>0.13. for any other purpose with your consent.</li>
                    </ul>
                    <h5>6. Retention of Data</h5>
                    <span>
                      We will retain your Personal Data only for as long as is
                      necessary for the purposes set out in this Privacy Policy.
                      We will retain and use your Personal Data to the extent
                      necessary to comply with our legal obligations (for
                      example, if we are required to retain your data to comply
                      with applicable laws), resolve disputes, and enforce our
                      legal agreements and policies. We will also retain Usage
                      Data for internal analysis purposes. Usage Data is
                      generally retained for a shorter period, except when this
                      data is used to strengthen the security or to improve the
                      functionality of our Service, or we are legally obligated
                      to retain this data for longer time periods.
                    </span>
                    <h5>7. Transfer of Data</h5>
                    <span>
                      Your information, including Personal Data, may be
                      transferred to – and maintained on – computers located
                      outside of your state, province, country or other
                      governmental jurisdiction where the data protection laws
                      may differ from those of your jurisdiction. If you are
                      located outside India and choose to provide information to
                      us, please note that we transfer the data, including
                      Personal Data, to India and process it there. Your consent
                      to this Privacy Policy followed by your submission of such
                      information represents your agreement to that transfer.
                      Pujyapanditg private limited will take all the steps
                      reasonably necessary to ensure that your data is treated
                      securely and in accordance with this Privacy Policy and no
                      transfer of your Personal Data will take place to an
                      organisation or a country unless there are adequate
                      controls in place including the security of your data and
                      other personal information.
                    </span>
                    <h5>8. Disclosure of Data</h5>
                    <span>
                      We may disclose personal information that we collect, or
                      you provide:
                    </span>
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        0.1. Business Transaction.
                        <span>
                          If we or our subsidiaries are involved in a merger,
                          acquisition or asset sale, your Personal Data may be
                          transferred.
                        </span>
                      </li>
                      <li>
                        0.2. Other cases. We may disclose your information also:
                      </li>
                      0.2.1. to our subsidiaries and affiliates;
                      <li>
                        0.2.2. to contractors, service providers, and other
                        third parties we use to support our business;
                      </li>
                      <li>
                        0.2.3. to fulfill the purpose for which you provide it;
                      </li>
                      <li>
                        0.2.4. for the purpose of including your company’s logo
                        on our website;
                      </li>
                      <li>
                        0.2.5. for any other purpose disclosed by us when you
                        provide the information;
                      </li>
                      <li>0.2.6. with your consent in any other cases;</li>
                      <li>
                        0.2.7. if we believe disclosure is necessary or
                        appropriate to protect the rights, property, or safety
                        of the Company, our customers, or others.
                      </li>
                    </ul>
                    <h5>9. Security of Data</h5>
                    <span>
                      The security of your data is important to us but remember
                      that no method of transmission over the Internet or method
                      of electronic storage is 100% secure. While we strive to
                      use commercially acceptable means to protect your Personal
                      Data, we cannot guarantee its absolute security.
                    </span>
                    <h5>
                      10. Your Data Protection Rights Under General Data
                      Protection Regulation (GDPR)
                    </h5>
                    <span>
                      If you are a resident of the European Union (EU) and
                      European Economic Area (EEA), you have certain data
                      protection rights, covered by GDPR. We aim to take
                      reasonable steps to allow you to correct, amend, delete,
                      or limit the use of your Personal Data. If you wish to be
                      informed what Personal Data we hold about you and if you
                      want it to be removed from our systems, please email us at
                      info.pujyapanditg@gmail.com. In certain circumstances, you
                      have the following data protection rights:
                    </span>
                    <span>
                      0.1. the right to access, update or to delete the
                      information we have on you;
                      <br />
                      0.2. the right of rectification. You have the right to
                      have your information rectified if that information is
                      inaccurate or incomplete;
                      <br />
                      0.3. the right to object. You have the right to object to
                      our processing of your Personal Data;
                      <br />
                      0.4. the right of restriction. You have the right to
                      request that we restrict the processing of your personal
                      information;
                      <br />
                      0.5. the right to data portability. You have the right to
                      be provided with a copy of your Personal Data in a
                      structured, machine-readable and commonly used format;
                      <br />
                      0.6. the right to withdraw consent. You also have the
                      right to withdraw your consent at any time where we rely
                      on your consent to process your personal information;
                      <br />
                      Please note that we may ask you to verify your identity
                      before responding to such requests. Please note, we may
                      not able to provide Service without some necessary data.
                      You have the right to complain to a Data Protection
                      Authority about our collection and use of your Personal
                      Data. For more information, please contact your local data
                      protection authority in the European Economic Area (EEA).
                    </span>
                    <h5>
                      11. Your Data Protection Rights under the California
                      Privacy Protection Act (CalOPPA)
                    </h5>
                    <span>
                      CalOPPA is the first state law in the nation to require
                      commercial websites and online services to post a privacy
                      policy. The law’s reach stretches well beyond California
                      to require a person or company in the United States (and
                      conceivable the world) that operates websites collecting
                      personally identifiable information from California
                      consumers to post a conspicuous privacy policy on its
                      website stating exactly the information being collected
                      and those individuals with whom it is being shared, and to
                      comply with this policy. According to CalOPPA we agree to
                      the following:
                    </span>
                    <span>
                      0.1. users can visit our site anonymously;
                      <br />
                      0.2. our Privacy Policy link includes the word “Privacy”,
                      and can easily be found on the home page of our website;
                      <br />
                      0.3. users will be notified of any privacy policy changes
                      on our Privacy Policy Page;
                      <br />
                      0.4. users are able to change their personal information
                      by emailing us at info.pujyapanditg@gmail.com.
                      <br />
                      Our Policy on “Do Not Track” Signals:
                      <br />
                    </span>
                    <span>
                      We honor Do Not Track signals and do not track, plant
                      cookies, or use advertising when a Do Not Track browser
                      mechanism is in place. Do Not Track is a preference you
                      can set in your web browser to inform websites that you do
                      not want to be tracked. You can enable or disable Do Not
                      Track by visiting the Preferences or Settings page of your
                      web browser.
                    </span>
                    <h5>
                      12. Your Data Protection Rights under the California
                      Consumer Privacy Act (CCPA)
                    </h5>
                    <span>
                      If you are a California resident, you are entitled to
                      learn what data we collect about you, ask to delete your
                      data and not to sell (share) it. To exercise your data
                      protection rights, you can make certain requests and ask
                      us:
                      <br />
                      0.1. What personal information we have about you. If you
                      make this request, we will return to you:
                      <br />
                      0.0.1. The categories of personal information we have
                      collected about you.
                      <br />
                      0.0.2. The categories of sources from which we collect
                      your personal information.
                      <br />
                      0.0.3. The business or commercial purpose for collecting
                      or selling your personal information.
                      <br />
                      0.0.4. The categories of third parties with whom we share
                      personal information.
                      <br />
                      0.0.5. The specific pieces of personal information we have
                      collected about you.
                      <br />
                      0.0.6. A list of categories of personal information that
                      we have sold, along with the category of any other company
                      we sold it to. If we have not sold your personal
                      information, we will inform you of that fact.
                      <br />
                      0.0.7. A list of categories of personal information that
                      we have disclosed for a business purpose, along with the
                      category of any other company we shared it with. Please
                      note, you are entitled to ask us to provide you with this
                      information up to two times in a rolling twelve-month
                      period. When you make this request, the information
                      provided may be limited to the personal information we
                      collected about you in the previous 12 months.
                      <br />
                      0.2. To delete your personal information. If you make this
                      request, we will delete the personal information we hold
                      about you as of the date of your request from our records
                      and direct any service providers to do the same. In some
                      cases, deletion may be accomplished through
                      de-identification of the information. If you choose to
                      delete your personal information, you may not be able to
                      use certain functions that require your personal
                      information to operate.
                      <br />
                      <br />
                      0.3. To stop selling your personal information. We don’t
                      sell or rent your personal information to any third
                      parties for any purpose. We do not sell your personal
                      information for monetary consideration. However, under
                      some circumstances, a transfer of personal information to
                      a third party, or within our family of companies, without
                      monetary consideration may be considered a “sale” under
                      California law. You are the only owner of your Personal
                      Data and can request disclosure or deletion at any time.
                      If you submit a request to stop selling your personal
                      information, we will stop making such transfers. Please
                      note, if you ask us to delete or stop selling your data,
                      it may impact your experience with us, and you may not be
                      able to participate in certain programs or membership
                      services which require the usage of your personal
                      information to function. But in no circumstances, we will
                      discriminate against you for exercising your rights. To
                      exercise your California data protection rights described
                      above, please send your request(s) by email:
                      info.pujyapanditg@gmail.com. Your data protection rights,
                      described above, are covered by the CCPA, short for the
                      California Consumer Privacy Act. To find out more, visit
                      the official California Legislative Information website.
                      The CCPA took effect on 01/01/2020.
                    </span>
                    <h5>13. Service Providers</h5>
                    <span>
                      We may employ third party companies and individuals to
                      facilitate our Service (“Service Providers”), provide
                      Service on our behalf, perform Service-related services or
                      assist us in analysing how our Service is used. These
                      third parties have access to your Personal Data only to
                      perform these tasks on our behalf and are obligated not to
                      disclose or use it for any other purpose.
                    </span>
                    <h5>14. Analytics</h5>
                    <span>
                      We may use third-party Service Providers to monitor and
                      analyze the use of our Service.
                    </span>
                    <h5>15. CI/CD tools</h5>
                    <span>
                      We may use third-party Service Providers to automate the
                      development process of our Service.
                    </span>
                    <h5>16. Behavioral Remarketing</h5>
                    <span>
                      We may use remarketing services to advertise on third
                      party websites to you after you visited our Service. We
                      and our third-party vendors use cookies to inform,
                      optimise and serve ads based on your past visits to our
                      Service.
                    </span>
                    <h5>17. Payments</h5>
                    <span>
                      We may provide paid products and/or services within
                      Service. In that case, we use third-party services for
                      payment processing (e.g. payment processors). We will not
                      store or collect your payment card details. That
                      information is provided directly to our third-party
                      payment processors whose use of your personal information
                      is governed by their Privacy Policy. These payment
                      processors adhere to the standards set by PCI-DSS as
                      managed by the PCI Security Standards Council, which is a
                      joint effort of brands like Visa, Mastercard, American
                      Express and Discover. PCI-DSS requirements help ensure the
                      secure handling of payment information.
                    </span>
                    <h5>18. Links to Other Sites</h5>
                    <span>
                      Our Service may contain links to other sites that are not
                      operated by us. If you click a third party link, you will
                      be directed to that third party’s site. We strongly advise
                      you to review the Privacy Policy of every site you visit.
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services. For example, the outlined privacy
                      policy has been made using PolicyMaker.io, a free tool
                      that helps create high-quality legal documents.
                      PolicyMaker’s privacy policy generator is an easy-to-use
                      tool for creating a privacy policy for blog, website,
                      e-commerce store or mobile app.
                    </span>
                    <h5>19. Children’s Privacy</h5>
                    <span>
                      Our Services are not intended for use by children under
                      the age of 18 (“Child” or “Children”). We do not knowingly
                      collect personally identifiable information from Children
                      under 18. If you become aware that a Child has provided us
                      with Personal Data, please contact us. If we become aware
                      that we have collected Personal Data from Children without
                      verification of parental consent, we take steps to remove
                      that information from our servers.
                    </span>
                    <h5>20. Changes to This Privacy Policy</h5>
                    <span>
                      We may update our Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page. We will let you know via email and/or
                      a prominent notice on our Service, prior to the change
                      becoming effective and update “effective date” at the top
                      of this Privacy Policy. You are advised to review this
                      Privacy Policy periodically for any changes. Changes to
                      this Privacy Policy are effective when they are posted on
                      this page.
                    </span>
                    <h5>21. Contact Us</h5>
                    <span>
                      If you have any questions about this Privacy Policy,
                      please contact us by email:{" "}
                      <a href="info.pujyapanditg@gmail.com.">
                        info.pujyapanditg@gmail.com.
                      </a>
                      info.pujyapanditg@gmail.com.
                    </span>
                  </p>
                  <h3>Refund Return & Cancellation Policy</h3>
                  <p>
                    All printable products including Pujyapanditg Kundali &
                    Horoscope, Year Book, Trikal Samhita and Janam Patri are
                    non-returnable
                    <br />
                    1. Cancellations received before 48 hours of the date of
                    service may be eligible to receive a 40% refund of booking
                    amount after deduction of administrative cost determined by
                    the Company for the particular service(s).
                    <br />
                    2. Cancellations received before 24 hours of the date of
                    service may be eligible to receive a 20% refund of booking
                    amount.
                    <br />
                    3. Cancellations received within 24 hours from the service
                    date will not be eligible for a refund under any condition.
                    <br />
                    4. Cancellation request will be only accepted via e-mail.
                    <br />
                    5. If the service(s) was provided as a part of a promotional
                    offer, loyalty scheme or any type of benefits, there will be
                    refund only to the extent of the actual money paid by the
                    registrant.
                    <br />
                    6. All refund requests must be made by the registrant only
                    from the same email which was used at the time of signing up
                    that is stored with the Company. No refund request will be
                    entertained if it is from a different email id.
                    <br />
                    7. Refund requests must include the payment transaction
                    details and other details as specified from time to time
                    along with the and reason for cancellation.
                    <br />
                    8. Refunds will be credited back to the original credit
                    card/ bank account/ other medium used for payment within 15
                    days from the day of cancellation request was received.
                    <br />
                    9. If a transaction was made fraudulently then the Company
                    reserves the rights to cancel any of the services and issue
                    a refund to the source from which the payment was made.
                    <br />
                    10. In case of pandit booking, if the allotted pandit
                    reaches the venue on time but customer decides not to have
                    the puja performed (due to some reasons) , there will be no
                    refund provided.
                    <br />
                    If the customer found product in damaged/defective/broken
                    condition, customer may apply for replacement or refund. For
                    this customer may mail at <a href="info.pujyapanditg@gmail.com.">
                        info.pujyapanditg@gmail.com.
                      </a>
                      within two
                    working days of delivery of product. No refund shall be
                    entertained after expiry of two days from the date of
                    delivery of product. All the products must be returned in
                    the original condition they were received in along with any
                    bills and labels. Customer need to register dispute by
                    sending email to <a href="info.pujyapanditg@gmail.com.">
                        info.pujyapanditg@gmail.com.
                      </a>
                       with images of
                    broken/defective/damaged products within 2 days of delivery.
                    Disputes registered after two days of delivery may not be
                    entertained by sellers. In case of double payment made by
                    mistake against the single order, one payment will be
                    refunded. All refunds will be made after deducting the
                    transaction charges levied by the bank and / or the payment
                    gateway, the cost of shipping or courier charges (back and
                    forth), customs duty and / or any other charges that may
                    have been borne by Pujyapanditg.com in processing and / or
                    delivering the service, as applicable. However, no recovery
                    of above mentioned shall be made if refund is due to
                    damaged/defective/ broken product.
                  </p>
                  <h3>Shipping</h3>
                  <p>
                    Pujyapanditg offer paid shipping services and this shipping
                    shall be done by a third-party logistics company. We may
                    change the third-party logistics company at our will without
                    any notification. If You prefer the product to be shipped by
                    logistics company of Your choice, it should be agreed and
                    paid for, during the transaction and not after placing the
                    order.
                  </p>
                  <p>
                    Delivery could take a little longer for remote locations and
                    shall depend on the third-party logistic company’s policies.
                    Orders are dispatched within 7 working days after necessary
                    time is taken to prepare the order. The Company cannot be
                    held liable if in a case there is a delay in dispatching the
                    order due to an unforeseen circumstance. Delivery shall be
                    based on the type of product that is being purchased.
                    Delivery of all orders will be duly done to the address as
                    mentioned by you at the time of placing the order. Products
                    are not shipped on Sundays, public holidays, or during
                    non-working hours.
                  </p>
                  <p>
                    Users are requested to enter the correct billing address and
                    contact information as it appears on Your bank or credit
                    card statement. Transactions may not go through if the
                    information entered does not match exactly what your bank or
                    credit card provider has on file. Some transactions may also
                    be held for further verification by Our customer experience
                    team. Make sure to also enter the correct shipping address
                    (Street number and name, City, State, and Pin code) as We
                    are not liable for packages that are sent to the wrong
                    address when the address information is not entered
                    correctly.
                  </p>
                  <p>
                    If in a situation, the order has not been delivered to you
                    and has been returned to Us due to Your unavailability, You
                    would be liable to pay the charges for re-delivering the
                    product to You. The Company/ Website does not track the
                    shipment or follow up with the third-party logistics
                    company, We have partnered with, on the status of the
                    shipment.
                  </p>
                  <h3>SERVICE</h3>
                  <p>
                    SERVICE means the PujyaPanditg.com website operated by
                    Pujyapanditg private limited. What we collect We may collect
                    the following information: Name Contact information
                    including email address Demographic information such as
                    address, postcode, preferences and interests Other
                    information relevant to customer surveys and/or offers
                    Personal Data like date of birth, time of birth, place of
                    birth, horoscope What we do with the information we gather
                    We require this information to understand your needs and
                    provide you with a better service, and for the following
                    reasons: Internal record keeping. We may use the information
                    to improve our products and services. We may periodically
                    send promotional emails, sms, whatsapp or other
                    communication apps about new products, special offers or
                    other information which we think you may find interesting
                    using the email a ddress or mobile number which you have
                    provided. From time to time, we may also use your
                    information to contact you for market research purposes. We
                    may contact you by email, phone, fax or mail. We may use the
                    information to customise the website according to your
                    interests. For AI and Machine Learning algorithms to enhance
                    your experience and improve prediction model
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WrapContainer>
    </div>
  );
};

export default PrivacyPolicy;
