import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom'

// Core components
import HeaderBreadcrumb from "../components/BreadCrumb";
import WrapContainer from "../components/container";

// APi
import Dataservices from "../services/requestApi";
import queryString from "query-string";
import { Container, Button } from "reactstrap";
import { Country, State, City } from 'country-state-city';
import Swal from "sweetalert2";
import Modify from "../components/Address/Modify";


const BuyNow = (props) => {
  let history = useHistory();
  const [getproduct, setProduct] = useState();
  const [cartReq, setCartReq] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [cities, setCities] = useState([])
  const [state, setState] = useState();
  const [country] = useState('india');
  const [pincode, setPincode] = useState();
  const [landmark, setLandmark] = useState();
  const [sector, setSector] = useState();
  const [COD, setCod] = useState(false)
  const location = useLocation();
  const cartID = location.pathname.split("/")[2];
  const [mounted] = useState(true);
  const [selectedAdd, setSelectedAdd] = useState(0);
  const token = sessionStorage.getItem("Authtoken");
  const [userdata, setuserData] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleModal = () => {
    setVisible(!visible);
  };

  const showAlert = (icon, title, text) => {
    Swal.fire({
      icon,
      title,
      text,
      confirmButtonText: 'Ok',
    });
  };

  const Pay = (isPreAddress) => {
    if (!isPreAddress) {
      if (!name || !email || !phone || !address || !city || !state || !country || !pincode || !landmark || !sector)
        return showAlert('error', 'Error', "Please fill all fields!");

      if (phone.length != 10 || isNaN(phone))
        return showAlert('error', 'Error', "Please enter a valid phone number!");

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        return showAlert('error', 'Error', "Please enter a valid email Id!");
      }

      const indiaPostalCodeRegex = /^\d{6}$/;
      if (!indiaPostalCodeRegex.test(pincode)) {
        return showAlert('error', 'Error', "Please enter 6 digit Pincode!");
      }
    }
    // e.preventDefault();
    if (cartReq) {
      let formData = {};
      formData.name = isPreAddress ? userdata?.data?.User_address[selectedAdd].full_name : name;
      formData.email = isPreAddress ? userdata?.data?.User_address[selectedAdd].email : email;
      formData.phone = isPreAddress ? userdata?.data?.User_address[selectedAdd].Mobile_number : phone;
      formData.address = isPreAddress ? userdata?.data?.User_address[selectedAdd].address : address;
      formData.city = isPreAddress ? userdata?.data?.User_address[selectedAdd].city : city;
      formData.state = isPreAddress ? userdata?.data?.User_address[selectedAdd].state : state;
      formData.country = isPreAddress ? userdata?.data?.User_address[selectedAdd].country : country;
      formData.pincode = isPreAddress ? userdata?.data?.User_address[selectedAdd].pincode : pincode;
      formData.landmark = isPreAddress ? userdata?.data?.User_address[selectedAdd].landmark : landmark;
      formData.sector = isPreAddress ? userdata?.data?.User_address[selectedAdd].sector : sector;
      formData.COD = COD;

      console.warn(formData);
      Dataservices.CartBuy(queryString.stringify(formData)).then(async res => {
        if (res.data.Payment_status) {
          if (res.data.Payment_status === "Cash on Delivery") {
            // console.log(res.data)
            // const res1 = await Dataservices.GetProductById(res.data.product_id);
            // const product = res1.data.data;
            history.push({ pathname: '/', state: { orderDetail: true, name: "Cart items", status: "success", order_id: res.data._id } });
          }
        }
        else {
          // window.location.href = res.data
          // window.location.href = res.data
          let checkoutOptions = {
            paymentSessionId: res.data.paymentSessionId,
            redirectTarget: "_self" //optional ( _self, _blank, or _top)
          }

          if (typeof window !== 'undefined' && window.Cashfree) {
            const cashfree = window.Cashfree({
              mode: "production" //or production
            });
            cashfree.checkout(checkoutOptions)
          }
        }
      }).catch((e) => {
        console.log(e)
      })

    }
    else {
      const productID = getproduct.product;
      let ProductDetails = getproduct;
      ProductDetails.product = productID;
      if (getproduct.variant_data !== "[]") {
        let variant_data = JSON.parse(getproduct.variant_data);
        ProductDetails.variant_id = variant_data._id;
        ProductDetails.size = variant_data.size;
      }

      ProductDetails.quantity = getproduct.quantity;
      ProductDetails.name = isPreAddress ? userdata?.data?.User_address[selectedAdd].full_name : name;
      ProductDetails.email = isPreAddress ? userdata?.data?.User_address[selectedAdd].email : email;
      ProductDetails.phone = isPreAddress ? userdata?.data?.User_address[selectedAdd].Mobile_number : phone;
      ProductDetails.address = isPreAddress ? userdata?.data?.User_address[selectedAdd].address : address;
      ProductDetails.city = isPreAddress ? userdata?.data?.User_address[selectedAdd].city : city;
      ProductDetails.state = isPreAddress ? userdata?.data?.User_address[selectedAdd].state : state;
      ProductDetails.country = isPreAddress ? userdata?.data?.User_address[selectedAdd].country : country;
      ProductDetails.pincode = isPreAddress ? userdata?.data?.User_address[selectedAdd].pincode : pincode;
      ProductDetails.landmark = isPreAddress ? userdata?.data?.User_address[selectedAdd].landmark : landmark;
      ProductDetails.sector = isPreAddress ? userdata?.data?.User_address[selectedAdd].sector : sector;
      ProductDetails.COD = COD;
      ProductDetails.newAddress = !isPreAddress;

      console.warn(ProductDetails)

      Dataservices.OrderDetails(queryString.stringify(ProductDetails)).then(async res => {
        if (res.data.Payment_status) {
          if (res.data.Payment_status === "Cash on Delivery") {
            // console.log(res.data)
            const res1 = await Dataservices.GetProductById(res.data.product_detiles[0].product_id);
            const product = res1.data.data;
            history.push({ pathname: '/', state: { orderDetail: true, name: product.name, status: "success", order_id: res.data._id } });
          }
        }
        else {
          // window.location.href = res.data
          let checkoutOptions = {
            paymentSessionId: res.data.paymentSessionId,
            redirectTarget: "_self" //optional ( _self, _blank, or _top)
          }

          if (typeof window !== 'undefined' && window.Cashfree) {
            const cashfree = window.Cashfree({
              mode: "production" //or production
            });
            cashfree.checkout(checkoutOptions)
          }

        }
      })
    }
  }

  const getData = async (e) => {
    try {
      if (token) {
        if (mounted) {
          const productRes = await Dataservices.GetCartItem(cartID);
          setProduct(productRes.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const deleteAddress = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await Dataservices.DeleteAddress(id);
          if (res.data.success == true && res.status === 200) {
            const { data } = res.data;
            const getAuth = sessionStorage.getItem("Authtoken");
            const Auth = JSON.parse(getAuth);
            const { data: user } = Auth;
            user.User_address = data;
            setuserData(Auth)
            sessionStorage.setItem("Authtoken", JSON.stringify(Auth));
            showAlert('success', 'Deleted!', res.data.message);
          }
          else {
            showAlert('error', 'Error!', res.data.message);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.cartAll) {
        setCartReq(true)
      }
    }
    if (!(props.location.state)) {
      getData();
    }
    return () => {
      if (!(props.location.state)) {
        getData();
      }
      // setMounted(false);
    };
  }, []);

  useEffect(() => {
    const getAuth = sessionStorage.getItem("Authtoken");
    if (getAuth) setuserData(JSON.parse(getAuth))
  }, [visible])

  let states = State.getStatesOfCountry("IN");

  const updatedCities = (stateId) => {
    setCities(City.getCitiesOfState("IN", stateId));
    console.log(City.getCitiesOfState("IN", stateId))
  }


  return (
    <WrapContainer>
      <HeaderBreadcrumb title="BuyNow" />
      <Container>
        <Modify visible={visible} handleModal={handleModal} add={userdata.data?.User_address[selectedAdd]} />
        {Boolean(userdata?.data?.User_address.length) ? <div className="col border p-4">
          <div className="d-flex justify-content-between">
            <h4 className="card-title text-muted mb-4">Deliver To:</h4>
            <Button onClick={() => setuserData(false)} color="amber" outline className="btn-rounded shadow" style={{ height: '40px' }}>Add New</Button>
          </div>
          <div className="row">
            {userdata.data?.User_address.map((address, key) => {
              return <div key={key} className="col-lg-6 py-2">
                <div onClick={() => setSelectedAdd(key)} className={`card overflow-auto border shadow ${key === selectedAdd ? 'border-primary' : ''}`} >
                  <div className="card-body position-relative">
                    <input className="d-inline-block mr-1" type="radio" name="choosedAdd" checked={key === selectedAdd ? true : false}></input>
                    <h5 className="card-title mb-1 d-inline-block">{address.full_name}</h5>
                    <div className="position-absolute d-inline-block" style={{ right: '1.25rem', top: '1.25rem' }}>
                      <i className="ri-file-edit-line p-1 rounded-circle shadow text-white bg-info mr-1" onClick={() => setVisible(true)} style={{ cursor: 'pointer', fontSize: '1rem' }} />
                      <i className="ri-delete-bin-3-line p-1 rounded-circle shadow text-white bg-danger" onClick={() => deleteAddress(address._id)} style={{ cursor: 'pointer', fontSize: '1rem' }} />
                    </div>
                    <p className="card-text d-flex align-items-center mb-0"><i className="ri-phone-line" style={{ fontSize: '1rem' }} />{address.Mobile_number}</p>
                    <p className="card-text d-flex align-items-center mb-0"><i className="ri-mail-line" style={{ fontSize: '1rem' }}></i>{address.email}</p>
                    <p className="card-text d-flex align-items-center mb-0"><i className="ri-map-pin-line" style={{ fontSize: '1rem' }} />{address.state}, {address.city}({address.pincode})</p>
                    <p className="card-text d-flex align-items-center mb-0"><i className="ri-treasure-map-line" style={{ fontSize: '1rem' }} />{address.address}, {address.sector}, {address.landmark}</p>
                  </div>
                </div>
              </div>
            })}
          </div>
          {((getproduct && getproduct.COD) || cartReq ) && <div
            className="d-flex align-items-center a-checkbox address-ui-widgets-checkbox-view  a-spacing-medium my-3 d-flex align-items-center"
          >
            <input
              id="address-ui-widgets-use-as-my-default"
              type="checkbox"
              name="address-ui-widgets-use-as-my-default"
              value={COD}
              onChange={(e) => setCod(prevState => !prevState)}
            />
            <h6 className="text-muted mb-0 ml-2">Cash On Delivery</h6>
          </div>}
          <button onClick={() => Pay(true)} className="btn btn-info btn-lg btn-block font-weight-bold m-1">
            {COD ? 'Place your Order' : 'Procced to Pay'}
          </button>
        </div> :
          <form id="address-ui-checkout-form" onSubmit={(e) => { e.preventDefault(); Pay(false) }}>
            <div>
              <div className="form" style={{ height: "100%", marginBottom: "21px" }}>
                <div className="a-row p-2 m-2">
                  <div className="a-span6 a-column">
                    <div
                      className="a-spacing-double-large a-row on-imb-scroll-here"
                      id="newShippingAddressFormFromIdentity"
                    >
                      <div
                        className="a-span10 a-column celwidget"
                        id="addres-new"
                        data-csa-c-id="sib6he-n5pqmi-mku69i-dy431e"
                        data-cel-widget="addres-new"
                      >
                        <input
                          type="hidden"
                          name="purchaseId"
                          defaultValue="404-3239921-3657961"
                        />
                        <div
                          id="address-ui-widgets-enterAddressFormContainer"
                          className="a-section"
                        >
                          <h2 className="a-spacing-none" style={{ fontWeight: '700', fontFamily: "'Alegreya Sans', sans-serif" }}>SHIPPING <span style={{ color: '#c74a2d' }}>ADDRESS</span></h2>
                          <div className="a-row">
                            <div className="a-input-text-group a-spacing-medium a-spacing-top-medium">
                              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                <div className="a-section a-spacing-none aok-inline-block pt-4">
                                  <label
                                    htmlFor="address-ui-widgets-enterAddressFullName"
                                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                  >
                                    <span className="a-size-base">
                                      <strong>Full name</strong>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container">
                                <input
                                  type="text"
                                  maxLength={50}
                                  id="address-ui-widgets-enterAddressFullName"
                                  name="address-ui-widgets-enterAddressFullName"
                                  className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                <div className="a-section a-spacing-none aok-inline-block">
                                  <label
                                    htmlFor="address-ui-widgets-enterAddressFullName"
                                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                  >
                                    <span className="a-size-base">
                                      <strong>Email</strong>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container">
                                <input
                                  type="email"
                                  maxLength={50}
                                  id="address-ui-widgets-enterAddressFullName"
                                  name="address-ui-widgets-enterAddressFullName"
                                  className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                <div className="a-section a-spacing-none aok-inline-block">
                                  <label
                                    htmlFor="address-ui-widgets-enterAddressPhoneNumber"
                                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                  >
                                    <span className="a-size-base">
                                      <strong>Mobile number</strong>
                                    </span>
                                  </label>
                                </div>
                                <div className="a-section aok-inline-block address-ui-widgets-desktop-form-right-aligned-popover-trigger">
                                  <span
                                    className="a-declarative"
                                    data-action="a-popover"
                                    data-a-popover='{"inlineContent":"May be used to assist delivery","closeButton":false,"content":"May be used to assist delivery","header":"More information","position":"triggerTop"}'
                                  ></span>
                                </div>
                              </div>
                              <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container">
                                <input
                                  type="text"
                                  maxLength={10}
                                  id="address-ui-widgets-enterAddressPhoneNumber"
                                  name="address-ui-widgets-enterAddressPhoneNumber"
                                  className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                <div className="a-section a-spacing-none aok-inline-block">
                                  <label
                                    htmlFor="address-ui-widgets-enterAddressPostalCode"
                                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                  >
                                    <span className="a-size-base">
                                      <strong>Pincode</strong>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container">
                                <input
                                  type="text"
                                  maxLength={6}
                                  id="address-ui-widgets-enterAddressPostalCode"
                                  placeholder="6 digits [0-9] PIN code"
                                  name="address-ui-widgets-enterAddressPostalCode"
                                  className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                  value={pincode}
                                  onChange={(e) => setPincode(e.target.value)}
                                  required
                                />
                                <div className="a-section a-spacing-none a-spacing-top-micro address-ui-widgets-inline-error-alert">
                                  <div
                                    id="address-ui-widgets-postalcode-error"
                                    className="a-box a-alert-inline a-alert-inline-error aok-hidden"
                                    aria-live="assertive"
                                    role="alert"
                                  >
                                    <div className="a-box-inner a-alert-container">
                                      <i className="a-icon a-icon-alert" />
                                      <div className="a-alert-content">
                                        <div className="a-section"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                <div className="a-section a-spacing-none aok-inline-block">
                                  <label
                                    htmlFor="address-ui-widgets-enterAddressLine1"
                                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                  >
                                    <span className="a-size-base">
                                      <strong>
                                        {" "}
                                        Flat, House no., Building, Company, Apartment
                                      </strong>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div
                                className="a-section a-spacing-base adddress-ui-widgets-form-field-container address-ui-widgets-field-container-fixed-height"
                                style={{ zIndex: 1 }}
                              >
                                <input
                                  type="text"
                                  maxLength={60}
                                  id="address-ui-widgets-enterAddressLine1"
                                  name="address-ui-widgets-enterAddressLine1"
                                  className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                  autoComplete="address-ui-widgets-enterAddressLine1"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                <div className="a-section a-spacing-none aok-inline-block">
                                  <label
                                    htmlFor="address-ui-widgets-enterAddressLine2"
                                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                  >
                                    <span className="a-size-base">
                                      <strong>Area, Street, Sector, Village</strong>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container address-ui-widgets-field-container-fixed-height">
                                <input
                                  type="text"
                                  maxLength={60}
                                  id="address-ui-widgets-enterAddressLine2"
                                  name="address-ui-widgets-enterAddressLine2"
                                  className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                  autoComplete="address-ui-widgets-enterAddressLine2"
                                  value={sector}
                                  onChange={(e) => setSector(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                <div className="a-section a-spacing-none aok-inline-block">
                                  <label
                                    htmlFor="address-ui-widgets-landmark"
                                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                  >
                                    <span className="a-size-base">
                                      <strong>Landmark</strong>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container">
                                <input
                                  type="text"
                                  maxLength={60}
                                  id="address-ui-widgets-landmark"
                                  placeholder="E.g. near apollo hospital"
                                  name="address-ui-widgets-landmark"
                                  className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                  value={landmark}
                                  onChange={(e) => setLandmark(e.target.value)}
                                  required
                                />
                              </div>
                              <div className="a-row address-ui-widgets-mobile-two-column-form-field-view-grid-row">
                                <div className="a-column a-span6 a-span-last">
                                  <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                    <div className="a-section a-spacing-none aok-inline-block">
                                      <label
                                        htmlFor="address-ui-widgets-enterAddressStateOrRegion-dropdown-nativeId"
                                        className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                      >Choose a state</label>
                                    </div>
                                  </div>
                                  <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container">
                                    {/* <span className="a-dropdown-container"> */}
                                    <select
                                      id="state"
                                      name="state"
                                      className="form-control"
                                      style={{ padding: "5px", width: "-webkit-fill-available" }}
                                      placeholder="Choose a state"
                                      onChange={(e) => { updatedCities(e.target.value); setState(e.target.value) }}
                                      required
                                    // options={updatedStates("IN")}
                                    >
                                      <option>Select State</option>
                                      {states.map((state, index) => (<option key={index} value={state.isoCode}>{state.name}</option>))}
                                    </select>
                                    {/* <span
                                    tabIndex={-1}
                                    id="address-ui-widgets-enterAddressStateOrRegion"
                                    data-a-className="address-ui-widgets-desktop-form-field-full-width"
                                    className="a-button a-button-dropdown a-spacing-none  address-ui-widgets-desktop-form-field-full-width"
                                    aria-label="State"
                                    style={{ minWidth: "0.243243%" }}
                                  >
                                    <span className="a-button-inner">
                                      <span
                                        className="a-button-text a-declarative"
                                        data-action="a-dropdown-button"
                                        role="button"
                                        tabIndex={0}
                                        aria-hidden="true"
                                      >
                                        <span className="a-dropdown-prompt"></span>
                                      </span>
                                      <i className="a-icon a-icon-dropdown" />
                                    </span>
                                  </span> */}
                                    {/* </span> */}
                                    {/* <div className="a-section a-spacing-none a-spacing-top-micro address-ui-widgets-inline-error-alert">
                                      <div
                                        id="address-ui-widgets-inline-state-or-region-error"
                                        className="a-box a-alert-inline a-alert-inline-error aok-hidden"
                                        aria-live="assertive"
                                        role="alert"
                                      >
                                        <div className="a-box-inner a-alert-container">
                                          <i className="a-icon a-icon-alert" />
                                          <div className="a-alert-content">
                                            <div className="a-section">
                                              Please enter a state, region or
                                              province.
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="a-column a-span6 mt-3">
                                  <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container">
                                    <div className="a-section a-spacing-none aok-inline-block">
                                      <label
                                        htmlFor="address-ui-widgets-enterAddressCity"
                                        className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap"
                                      >
                                        <span className="a-size-base">
                                          <strong>Town/City</strong>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="a-section a-spacing-none adddress-ui-widgets-form-field-container">
                                    {/* <input
                                      type="text"
                                      maxLength={50}
                                      id="address-ui-widgets-enterAddressCity"
                                      name="address-ui-widgets-enterAddressCity"
                                      className="a-input-text address-ui-widgets-desktop-form-field-full-width addrui-form-text-input"
                                      autoComplete="address-ui-widgets-enterAddressCity"
                                      value={city}
                                      onChange={(e) => setCity(e.target.value)}
                                      required
                                    /> */}
                                    <select
                                      id="city"
                                      name="city"
                                      className="form-control"
                                      // onChange={e => setCity(e.target.value)}
                                      onChange={e => setCity(e.target.value)}
                                      required
                                    >
                                      {cities.map((city, index) => (<option key={index} value={city.id}>{city.name}</option>))}
                                      <option value>Select</option>
                                      {/* options={updatedCities(values.state ? values.state.value : null)} */}
                                      {/* <option value={101}>Jaipur</option> */}
                                    </select>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          {/* <div
                        data-a-input-name="address-ui-widgets-use-as-my-default"
                        className="a-checkbox address-ui-widgets-checkbox-view  a-spacing-medium"
                      >
                        <label htmlFor="address-ui-widgets-use-as-my-default">
                          <input
                            id="address-ui-widgets-use-as-my-default"
                            type="checkbox"
                            name="address-ui-widgets-use-as-my-default"
                            defaultValue="true"
                            style={{ maxWidth: 15 }}
                          />
                          <i className="a-icon a-icon-checkbox" />
                          <span className="a-label a-checkbox-label">
                            <span className="a-size-base">
                              Make this my default address
                            </span>
                          </span>
                        </label>
                      </div> */}
                          {((getproduct && getproduct.COD ) || cartReq) && <div
                            className="a-checkbox address-ui-widgets-checkbox-view  a-spacing-medium mt-2 mb-3 d-flex align-items-center"
                          >
                            <input
                              id="address-ui-widgets-use-as-my-default"
                              type="checkbox"
                              name="address-ui-widgets-use-as-my-default"
                              value={COD}
                              onChange={(e) => setCod(prevState => !prevState)}
                            />
                            <span className="a-label a-checkbox-label ml-2">
                              <span className="a-size-base">
                                Cash On Delivery
                              </span>
                            </span>
                          </div>}
                        </div>
                        <input
                          type="hidden"
                          name="hasWorkingJavascript"
                          defaultValue={1}
                        />
                      </div>
                      <div className="a-span2 a-column a-span-last"></div>
                    </div>
                  </div>
                  <div
                    id="addres-pickup-search"
                    className="celwidget a-span6 a-column a-span-last"
                    data-csa-c-id="59onq6-piz5yx-vtlsp2-g9abl6"
                    data-cel-widget="addres-pickup-search"
                  ></div>
                  <div className="a-section">
                    {/* <div
                className="a-section a-spacing-base address-ui-widgets-aad-heading-container"
                style={{ height: 19 }}
                >
                <div
                  id="address-ui-widgets-addr-details-main-heading"
                  className="a-size-medium aok-float-left"
                  >
                  <h4>
                    <strong>Add delivery instructions</strong>
                  </h4>
                </div>
                <span className="a-letter-space" />
              </div>
              <div
                id="address-ui-widgets-addr-details-text"
                className="a-section a-spacing-base pt-2"
                >
                Preferences are used to plan your delivery. However, shipments can
                sometimes arrive early or later than planned.
                <span className="a-letter-space" />
              </div>
              <div className="a-section a-spacing-none adddress-ui-widgets-form-field-label-container address-ui-widgets-desktop-form-label">
                <div className="a-section a-spacing-none aok-inline-block">
                  <label
                    htmlFor="address-ui-widgets-addr-details-address-type-and-business-hours"
                    className="a-form-label address-ui-widgets-desktop-form-field-full-width a-nowrap pt-3 m-1"
                    >
                    <span className="a-size-base">
                      <strong>Address Type</strong>
                    </span>
                  </label>
                </div>
              </div>
              <div className="a-section a-spacing-base adddress-ui-widgets-form-field-container address-ui-widgets-desktop-form-field">
                <span className="a-dropdown-container">
                  <select
                    name="address-ui-widgets-addr-details-address-type-and-business-hours"
                    autoComplete="off"
                    tabIndex={-1}
                    className="a-native-dropdown mb-2"
                    style={{ padding: "5px", width: "-webkit-fill-available" }}
                    >
                    <option value="OTH" selected="">
                      {" "}
                      Select an Address Type{" "}
                    </option>
                    <option value="RES"> Home (7 am – 9 pm delivery) </option>
                    <option value="COM">
                      {" "}
                      Office/Commercial (10 AM - 6 PM delivery){" "}
                    </option>
                  </select>
                  <span
                    tabIndex={-1}
                    id="address-ui-widgets-addr-details-address-type-and-business-hours"
                    data-a-className="address-ui-widgets-desktop-form-field-full-width"
                    className="a-button a-button-dropdown address-ui-widgets-desktop-form-field-full-width"
                    aria-label=""
                    >
                    <span className="a-button-inner">
                      <span
                        className="a-button-text a-declarative"
                        data-action="a-dropdown-button"
                        role="button"
                        tabIndex={0}
                        aria-hidden="true"
                        >
                        <span className="a-dropdown-prompt pl-2">
                          {" "}
                        </span>
                      </span>
                      <i className="a-icon a-icon-dropdown" />
                    </span>
                  </span>
                </span>
              </div> */}
                  </div>
                  <div className="buton">
                    <span className="a-button-inner">
                      {/* <button
                  className="a-button-input btn btn-success"
                  type="submit"
                  aria-labelledby="address-ui-widgets-form-submit-button-announce"
                  placeholder="Use this address"
                  >
                  {" "}
                  Use this address
                </button> */}
                      <span
                        id="address-ui-widgets-form-submit-button-announce"
                        className="a-button-text"
                        aria-hidden="true"
                      >
                        <button className="btn btn-info m-1">
                          {COD ? 'Place your Order' : 'Procced to Pay'}
                        </button>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>}
      </Container>
    </WrapContainer>
  );
};
export default BuyNow;
