import React from "react";
//css
import "../assets/scss/Contact.css";
// Core components
import HeaderBreadcrumb from "../components/BreadCrumb";
import WrapContainer from "../components/container";
import Headimage from "../components/HeadImage/Headimage";

// APi
// import Dataservices from "../services/requestApi";

const Refund = () => {
  return (
    <div className="Contact">
      <WrapContainer>
        <HeaderBreadcrumb title="REFUND/CANCELLATION POLICY" />
        <Headimage
          title="REFUND/CANCELLATION POLICY"
        //   text="Have any queries? We'd love to hear from you"
        />
        <div className="container">
          <div className="row">
            <div className="px-2 py-2 d-flex col-lg-12 p-4">
              <div className="card">
                <div className="card-body">
                  <div className="card-text">
                    <h3>REFUND/CANCELLATION POLICY</h3>
                    <p className="text-muted">Effective date: 2022-11-12</p>
                  </div>
                  <h3>Refund Return & Cancellation Policy</h3>
                  <p>
                    All printable products including Pujyapanditg Kundali &
                    Horoscope, Year Book, Trikal Samhita and Janam Patri are
                    non-returnable
                    <br />
                    1. Cancellations received before 48 hours of the date of
                    service may be eligible to receive a 40% refund of booking
                    amount after deduction of administrative cost determined by
                    the Company for the particular service(s).
                    <br />
                    2. Cancellations received before 24 hours of the date of
                    service may be eligible to receive a 20% refund of booking
                    amount.
                    <br />
                    3. Cancellations received within 24 hours from the service
                    date will not be eligible for a refund under any condition.
                    <br />
                    4. Cancellation request will be only accepted via e-mail.
                    <br />
                    5. If the service(s) was provided as a part of a promotional
                    offer, loyalty scheme or any type of benefits, there will be
                    refund only to the extent of the actual money paid by the
                    registrant.
                    <br />
                    6. All refund requests must be made by the registrant only
                    from the same email which was used at the time of signing up
                    that is stored with the Company. No refund request will be
                    entertained if it is from a different email id.
                    <br />
                    7. Refund requests must include the payment transaction
                    details and other details as specified from time to time
                    along with the and reason for cancellation.
                    <br />
                    8. Refunds will be credited back to the original credit
                    card/ bank account/ other medium used for payment within 15
                    days from the day of cancellation request was received.
                    <br />
                    9. If a transaction was made fraudulently then the Company
                    reserves the rights to cancel any of the services and issue
                    a refund to the source from which the payment was made.
                    <br />
                    10. In case of pandit booking, if the allotted pandit
                    reaches the venue on time but customer decides not to have
                    the puja performed (due to some reasons) , there will be no
                    refund provided.
                    <br />
                    If the customer found product in damaged/defective/broken
                    condition, customer may apply for replacement or refund. For
                    this customer may mail at <a href="info.pujyapanditg@gmail.com.">
                        info.pujyapanditg@gmail.com.
                      </a>
                      within two
                    working days of delivery of product. No refund shall be
                    entertained after expiry of two days from the date of
                    delivery of product. All the products must be returned in
                    the original condition they were received in along with any
                    bills and labels. Customer need to register dispute by
                    sending email to <a href="info.pujyapanditg@gmail.com.">
                        info.pujyapanditg@gmail.com.
                      </a>
                       with images of
                    broken/defective/damaged products within 2 days of delivery.
                    Disputes registered after two days of delivery may not be
                    entertained by sellers. In case of double payment made by
                    mistake against the single order, one payment will be
                    refunded. All refunds will be made after deducting the
                    transaction charges levied by the bank and / or the payment
                    gateway, the cost of shipping or courier charges (back and
                    forth), customs duty and / or any other charges that may
                    have been borne by Pujyapanditg.com in processing and / or
                    delivering the service, as applicable. However, no recovery
                    of above mentioned shall be made if refund is due to
                    damaged/defective/ broken product.
                  </p>
                  <h3>Shipping</h3>
                  <p>
                    Pujyapanditg offer paid shipping services and this shipping
                    shall be done by a third-party logistics company. We may
                    change the third-party logistics company at our will without
                    any notification. If You prefer the product to be shipped by
                    logistics company of Your choice, it should be agreed and
                    paid for, during the transaction and not after placing the
                    order.
                  </p>
                  <p>
                    Delivery could take a little longer for remote locations and
                    shall depend on the third-party logistic company’s policies.
                    Orders are dispatched within 7 working days after necessary
                    time is taken to prepare the order. The Company cannot be
                    held liable if in a case there is a delay in dispatching the
                    order due to an unforeseen circumstance. Delivery shall be
                    based on the type of product that is being purchased.
                    Delivery of all orders will be duly done to the address as
                    mentioned by you at the time of placing the order. Products
                    are not shipped on Sundays, public holidays, or during
                    non-working hours.
                  </p>
                  <p>
                    Users are requested to enter the correct billing address and
                    contact information as it appears on Your bank or credit
                    card statement. Transactions may not go through if the
                    information entered does not match exactly what your bank or
                    credit card provider has on file. Some transactions may also
                    be held for further verification by Our customer experience
                    team. Make sure to also enter the correct shipping address
                    (Street number and name, City, State, and Pin code) as We
                    are not liable for packages that are sent to the wrong
                    address when the address information is not entered
                    correctly.
                  </p>
                  <p>
                    If in a situation, the order has not been delivered to you
                    and has been returned to Us due to Your unavailability, You
                    would be liable to pay the charges for re-delivering the
                    product to You. The Company/ Website does not track the
                    shipment or follow up with the third-party logistics
                    company, We have partnered with, on the status of the
                    shipment.
                  </p>
                  <h3>SERVICE</h3>
                  <p>
                    SERVICE means the PujyaPanditg.com website operated by
                    Pujyapanditg private limited. What we collect We may collect
                    the following information: Name Contact information
                    including email address Demographic information such as
                    address, postcode, preferences and interests Other
                    information relevant to customer surveys and/or offers
                    Personal Data like date of birth, time of birth, place of
                    birth, horoscope What we do with the information we gather
                    We require this information to understand your needs and
                    provide you with a better service, and for the following
                    reasons: Internal record keeping. We may use the information
                    to improve our products and services. We may periodically
                    send promotional emails, sms, whatsapp or other
                    communication apps about new products, special offers or
                    other information which we think you may find interesting
                    using the email a ddress or mobile number which you have
                    provided. From time to time, we may also use your
                    information to contact you for market research purposes. We
                    may contact you by email, phone, fax or mail. We may use the
                    information to customise the website according to your
                    interests. For AI and Machine Learning algorithms to enhance
                    your experience and improve prediction model
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WrapContainer>
    </div>
  );
};

export default Refund;
