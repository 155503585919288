import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Core components
import PoojaCards from "../../Cards/PoojaCards";
import SliderWrapper from "../../Slider";
import {data1,golden,diamond} from "./data"
import KundaliCard from "./kundaliCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Dataservices from "../../../services/requestApi";

const AllPooja = ({ data, slider,title,totalResults, fetchMoreData, trendingSize }) => {
  const [session,setSession]=useState([]);
  const [epooja,setEpooja]=useState([]);
const [page, setPage] = useState(1)
  const [totalTrendings, setTotalTrendings] = useState(0)
  
  useEffect(() => {
    const getData = async (e) => {
      try {
        const trendingRes = await Dataservices.GetTrendingPooja(page,trendingSize);
        setSession(trendingRes.data.data);
          setTotalTrendings(trendingRes.data.totalResults)
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    // console.log('all pooja',data)
  }, [])

  const fetchMoreTrendingData = async () => {
    try {
      const trendingRes = await Dataservices.GetTrendingPooja(page+1,trendingSize);
        setPage(page+1)
        setSession(session.concat(trendingRes.data.data));
        setTotalTrendings(trendingRes.data.totalResults);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    // console.log('all pooja',data)
    if(data.length)
    {
      let sessionData=[];
      data.forEach(item => {
        if(item.session )
        {
          sessionData.push(item);
        }
      });
      if(sessionData.length)
      {
        setSession(sessionData);
      }
    }
  
  }, [data])
  useEffect(() => {
    console.log('e-pooja',data)
    if(data.length)
    {
      let epoojaData=[];
      data.forEach(item => {
        if(item.epooja )
        {
          epoojaData.push(item);
        }
      });
      if(epoojaData.length)
      {
        setEpooja(epoojaData);
      }
    }
  
  }, [data])
  useEffect(() => {
  console.log('slider cehck',slider)
  }, [])
  
  return(
  <div className="all_product" style={{backgroundColor:'#f0f0f07d'}}>
    <Container>
      {/* //Seasonal pooja slider  */}
      <Row>
        <Col lg="12">
          <div className="heading">
          <h1>
              {title ? (
                <>
                  {/* <span>{title}</span> */}
                </>
              ) : (
                <>
                  <Link to={"/all-pooja"}>
                  <span style={{ color:'black' }}>Pooja&nbsp;and</span><span> Anusthanam</span></Link>
                </>
              )}
            </h1>
          </div>
          <div>
            <h3>
              <span style={{fontSize:'1.5rem',fontWeight:'600',fontFamily:'"Alegreya Sans", sans-serif'}}>Trending Pooja</span>
            </h3>
          </div>
        </Col>
          
        {slider ? (
          <Col lg="12">
            <SliderWrapper
              type="single"
              slides={4}
              row={1}
              spacebetween={30}
              colors="#c74a2d"
              mobile="2"
              tablet="2"
              desktop="4"
            >
              {session&&session.map((item) => (
                <SwiperSlide key={item._id}>
                  <PoojaCards data={item} />
                </SwiperSlide>
              ))}
            </SliderWrapper>
          </Col>
        ) : (
          <InfiniteScroll style={{ overflow: 'unset' }}
          dataLength={session.length}
          next={fetchMoreTrendingData}
          hasMore={session.length !== totalTrendings}
          scrollThreshold={0.6}
          loader={
          <div className="container d-flex align-items-center justify-content-center my-5">
          <Spinner />
            </div>}>
            <Col lg="12" className="row">
            {session.map((item) => (
              <Col lg="3" key={item._id}>
                <PoojaCards data={item} />
              </Col>
            ))}
           </Col>
          </InfiniteScroll>
        )}
      </Row>
{/* all E-pooja */}
      {/* ALL POOJA Slider */}
      <Row> 
        <Col lg="12"> 
         <div>
            <h3>
              <span style={{fontSize:'1.5rem',fontWeight:'600',fontFamily:'"Alegreya Sans", sans-serif'}}>All Pooja</span>
            </h3>
          </div>
        </Col>
        {slider ? (
          <Col lg="12">
            <SliderWrapper
              type="single"
              slides={4}
              row={1}
              spacebetween={30}
              colors="#c74a2d"
              mobile="2"
              tablet="2"
              desktop="4"
            >
              {data&&data.map((item) => (
                  <SwiperSlide key={item._id}>
                  <PoojaCards data={item} />
                </SwiperSlide>
              ))}
            </SliderWrapper>
          </Col>
        ) : (
          <InfiniteScroll style={{ overflow: 'unset' }}
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={data.length !== totalResults}
          scrollThreshold={0.6}
          loader={
          <div className="container d-flex align-items-center justify-content-center my-5">
          <Spinner />
            </div>}>
            <Col lg="12" className="row">

            {data.map((item) => (
              <Col lg="3" key={item._id}>
                <PoojaCards data={item} />
              </Col>
            ))}
         </Col>
          </InfiniteScroll>
        )}
      </Row>
         {(window.location.pathname === '/')?(<div className="d-flex justify-content-center">
          <Link className="btn btn-amber btn-rounded px-4 text-white mb-2 mx-md-2 mb-md-0 nav-link d-inline-block" 
                    to="/all-pooja"
                    style={{fontWeight:'500'}}
                  >
                View all Our Pooja
         </Link>
      </div>):''}
      {/*<Row> 
      <Col lg="12 mt-5">
          <div className="heading">
          <h1>
              {title ? (
                <>
                  {/* <span>{title}</span> 
                </>
              ) : (
                <>
                  <Link to={"/all-pooja"}>
                    <span>E-Pooja</span></Link>
                </>
              )}
            </h1>
          </div>
        </Col>
        {slider ? (
          <Col lg="12">
            <SliderWrapper
              type="single"
              slides={4}
              row={1}
              spacebetween={30}
              colors="#c74a2d"
              mobile="2"
              tablet="2"
              desktop="4"
            >
              {data&&data.map((item) => (
              item.epooja==true &&
                <SwiperSlide key={item._id}>
                  <PoojaCards data={item} />
                </SwiperSlide>
              ))}
            </SliderWrapper>
          </Col>
        ) : (
          <>
            {data.map((item) => (
              <Col lg="3" key={item._id}>
                <PoojaCards data={item} />
              </Col>
            ))}
          </>
        )}
      </Row>
         {(window.location.pathname === '/')?(<div className="d-flex justify-content-center">
          <Link className="btn btn-amber btn-rounded px-4 text-white mb-2 mx-md-2 mb-md-0 nav-link d-inline-block" 
                    to="/EpoojaBook"
                    style={{fontWeight:'500'}}
                  >
                View all Our E-Pooja
         </Link>
      </div>):''} */}
      
    </Container>
  </div>)
};
export default AllPooja;