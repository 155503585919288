// import { text } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import '../../assets/scss/Contact.css'

 const Headimage = (props) => {
  return (
    <div>
         <section className="contact-us flex-center">
          <div className="overlay " />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="title">{props.title} </p>
                <p className="text">
                  {props.text}
                </p>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}
export default Headimage

