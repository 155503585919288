import React, { useEffect, useState } from "react";
import Headimage from "../../components/HeadImage/Headimage";
import WrapContainer from "../../components/container";
import HeaderBreadcrumb from "../../components/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory } from "react-router-dom";
import "./Kundali.css";
import DataService from "../../services/requestApi";
import moment from 'moment'
import "@lottiefiles/lottie-player";
import whatsappLogo from '../../assets/img/whatsapp.png';

// import queryString from "query-string";


const AdvancedPanchang = (props) => {
    const [panchang, setPanchang] = useState("");
    const [chaughadiyaMuhurta,setChaughadiyaMuhurta] = useState("");
    const [horaMuhurta, setHoraMuhurta] = useState("");
        const [panchangDate, setPanchangDate] = useState(new Date());
    const history = useHistory();
    const updatePanchang = (isNext) => {
        if(isNext) setPanchangDate(moment(panchangDate).add(1,'days'));
        else setPanchangDate(moment(panchangDate).subtract(1,'days'));
    }
    const getPanchang = async () => {
        const response = await DataService.getPanchang(moment(panchangDate).format('YYYY-MM-DD'));
        if (response.data.resCode === 200) {
            setPanchang( response.data.data);
            setChaughadiyaMuhurta( response.data.chaughadiyaMuhurta);
            setHoraMuhurta(response.data.horaMuhurta);
        }
        else {
            history.goBack();
        }
    }

    useEffect(() => {
        getPanchang();
    }, [panchangDate])
    return (
        panchang && chaughadiyaMuhurta && horaMuhurta && <>
            <WrapContainer>
                <HeaderBreadcrumb title="Panchang" />
                <Headimage title="Panchang" />
                {/*<div className="container-fluid desc ">
                    <div className="card im">
                        <div className="card-body">
                            <p>
                                Pujya Pandit G Kundali | Janam Kundali Online - जन्म कुंडली
                                Janam Kundli also known as Janampatri or Birth chart or Kundli
                                Chart or Natal Chart, depicts an individual’s life based on the
                                12 houses. Kundali, Vedic charts, graphical representation of
                                positions of houses and planets are prepared based on the date,
                                time and place of birth of an individual.
                            </p>
                        </div>
                    </div>
                </div>*/}
                <div className="container">
                <div className="row p-1">
                        <button type="button" class="btn-flip" data-front="⟵" data-back={moment(panchangDate).subtract(1,'days').format('DD MMMM')} onClick={()=>updatePanchang(false)}></button> 
                        <div className="col" style={{ border: "1px solid #e9963e", color: "#e9963e"}}>
                            <h4 className="text-amber text-center m-0 mt-1">
                            {moment(panchangDate).format('DD MMMM')}
                            </h4>
                            </div>
                        <button type="button" class="btn-flip" data-front="⟶" data-back={moment(panchangDate).add(1,'days').format('DD MMMM')} onClick={()=>updatePanchang(true)}></button> 
                    </div>
                    {/* description */}
                    <div className="row mb-5">
                        <div className="card w-100  border-0">
                            <div className="card-body">
                                <div className="row flex-column border">
                                    <div className="col panchang_first_card p-3">
                                        <div className="row">
                                            <div className="col-lg-6 d-flex">
                                                <div className="card border-0 col-6 p-2 flex-row justify-content-center align-items-center" style={{ background: 'rgba(256,256,256,0.4)' }}>
                                                    <h1 className="text-white mb-0 date_element">{moment(panchangDate).format('DD')}</h1>
                                                    <div className="d-flex flex-column mx-2 justify-content-center">
                                                        <h6 className="text-white mb-0">{moment(panchangDate).format('dddd')}</h6>
                                                        <h6 className="text-white mb-0">{moment(panchangDate).format('MMMM')}</h6>
                                                        <h6 className="text-white mb-0">{moment(panchangDate).format('YYYY')}</h6>
                                                    </div>
                                                </div>
                                                <div className="col-6 p-2 d-flex flex-column justify-content-center align-items-center" >
                                                    <lottie-player src="https://assets6.lottiefiles.com/temp/lf20_KQd5BP.json" background="transparent" speed="2.5" style={{ width: '120px', height: '120px' }} loop autoPlay />
                                                    <h6 className="text-white">{panchang.ayana}</h6>
                                                    <span className="text-white" style={{ fontSize: "0.7rem", opacity: '0.7' }}>{panchang.tithi.details.special}</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 py-4 p-md-auto">
                                                <h4 className="text-white"><i class="fa fa-snowflake-o" aria-hidden="true"></i> {panchang.hindu_maah.amanta}</h4>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                        <h3 className="text-white mb-0"><i class="ri-sun-line"></i></h3>
                                                        <h6 className="text-white mb-0">{panchang.sunrise}</h6>
                                                        <span className="text-white text-center" style={{ fontSize: "13px" }}>(Vedic-{panchang.vedic_sunrise})</span>
                                                        <span className="text-white" style={{ fontSize: "0.7rem", opacity: '0.7' }}>Sunrise</span>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                        <h3 className="text-white mb-0"><i class="ri-sun-foggy-fill"></i></h3>
                                                        <h6 className="text-white text-center mb-0">{panchang.sunset}</h6>
                                                        <span className="text-white text-center" style={{ fontSize: "13px" }}>(Vedic-{panchang.vedic_sunset})</span>
                                                        <span className="text-white" style={{ fontSize: "0.7rem", opacity: '0.7' }}>Sunset</span>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                        <h3 className="text-white mb-0"><i class="ri-moon-clear-line"></i></h3>
                                                        <h6 className="text-white ">{panchang.moonrise}</h6>
                                                        <span className="text-white" style={{ fontSize: "0.7rem", opacity: '0.7' }}>Moonrise</span>
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                        <h3 className="text-white mb-0"><i class="ri-moon-foggy-fill"></i></h3>
                                                        <h6 className="text-white ">{panchang.moonset}</h6>
                                                        <span className="text-white" style={{ fontSize: "0.7rem", opacity: '0.7' }}>Moonset</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ico-card">
                                            <i class="fa fa-empire" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    {/*<div className="festival-alert p-2">
                                        <span className="" style={{ fontSize: '1rem' }}>Today's Festivals </span>
                                        <h5 className="w-auto animate-charcter" >()</h5>
                                    </div>*/}
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                            Tithi
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Tithi Name: </span> <span>{panchang.tithi.details.tithi_name}</span></td>
                                                            <td><span className="text-secondary">Tithi Number: </span> <span>{panchang.tithi.details.tithi_number}</span></td>
                                                            <td><span className="text-secondary">Special Tithi: </span> <span>{panchang.tithi.details.special}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Deity: </span> <span>{panchang.tithi.details.deity}</span></td>
                                                            <td><span className="text-secondary">End time (HH:MM:SS): </span> <span>{panchang.tithi.end_time.hour+':'+panchang.tithi.end_time.minute+':'+panchang.tithi.end_time.second}</span></td>
                                                           {/* <td><span className="text-secondary">End time (In Millisecond): </span> <span>{panchang.tithi.end_time_ms}</span></td>*/}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><span className="text-secondary">Summary: </span> <span>{panchang.tithi.details.summary}</span></td>
                                                        </tr>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="card border-0">
                                        <div className="card-header">
                                            Nak Shool
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table>
                                                    <tr>
                                                        <td><span className="text-secondary">Direction: </span> <span>{panchang.nak_shool.direction}</span></td>
                                                        <td><span className="text-secondary">Remedies: </span> <span>{panchang.nak_shool.remedies}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>*/}
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                            Nakshatra
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Nak Name: </span> <span>{panchang.nakshatra.details.nak_name}</span></td>
                                                            <td><span className="text-secondary">Nak Number: </span> <span>{panchang.nakshatra.details.nak_number}</span></td>
                                                            <td><span className="text-secondary">Special: </span> <span>{panchang.nakshatra.details.special}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Deity: </span> <span>{panchang.nakshatra.details.deity}</span></td>
                                                            <td><span className="text-secondary">End time (HH:MM:SS): </span> <span>{panchang.nakshatra.end_time.hour+':'+panchang.nakshatra.end_time.minute+':'+panchang.nakshatra.end_time.second}</span></td>
                                                            <td><span className="text-secondary">Ruler: </span> <span>{panchang.nakshatra.details.ruler}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><span className="text-secondary">Summary: </span> <span>{panchang.nakshatra.details.summary}</span></td>
                                                        </tr>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                            Yog
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Yog Name: </span> <span>{panchang.yog.details.yog_name}</span></td>
                                                            <td><span className="text-secondary">Yog Number: </span> <span>{panchang.yog.details.yog_number}</span></td>
                                                            <td><span className="text-secondary">Special: </span> <span>{panchang.yog.details.special}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">End time (HH:MM:SS): </span> <span>{panchang.yog.end_time.hour+':'+panchang.yog.end_time.minute+':'+panchang.yog.end_time.second}</span></td>
                                                            {/*<td><span className="text-secondary">End time (In Millisecond): </span> <span>{panchang.yog.end_time_ms}</span></td>*/}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><span className="text-secondary">Meaning: </span> <span>{panchang.yog.details.meaning}</span></td>
                                                        </tr>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                            Karan
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Karan Name: </span> <span>{panchang.karan.details.karan_name}</span></td>
                                                            <td><span className="text-secondary">Karan Number: </span> <span>{panchang.karan.details.karan_number}</span></td>
                                                            <td><span className="text-secondary">Special: </span> <span>{panchang.karan.details.special}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Deity: </span> <span>{panchang.karan.details.deity}</span></td>
                                                            <td><span className="text-secondary">End time (HH:MM:SS): </span> <span>{panchang.karan.end_time.hour+':'+panchang.karan.end_time.minute+':'+panchang.karan.end_time.second}</span></td>
                                                           {/* <td><span className="text-secondary">End time (In Millisecond): </span> <span>{panchang.karan.end_time_ms}</span></td>*/}
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                            Hindu Maah
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Amanta: </span> <span>{panchang.hindu_maah.amanta}</span></td>
                                                            <td><span className="text-secondary">Purnimanta: </span> <span>{panchang.hindu_maah.purnimanta}</span></td>
                                                           {/* <td><span className="text-secondary">Adhik Status: </span> <span>{panchang.hindu_maah.adhik_status}</span></td>*/}
                                                            <td><span className="text-secondary">Paksha/पक्ष: </span> <span>{panchang.paksha}</span></td>
                                                            </tr><tr><td><span className="text-secondary">Sun Sign/सूर्य राशि: </span> <span>{panchang.sun_sign}</span></td>
                                                            <td><span className="text-secondary">Moon Sign/चंद्र राशि: </span> <span>{panchang.moon_sign}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#9acd32",color:"#fffafa"}}>
                                        Abhijit Muhurta
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Start: </span> <span>{panchang.abhijit_muhurta.start}</span></td>
                                                            <td><span className="text-secondary">End: </span> <span>{panchang.abhijit_muhurta.end}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#ffa07a",color:"#fffafa"}}>
                                        GuliKaal
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Start: </span> <span>{panchang.guliKaal.start}</span></td>
                                                            <td><span className="text-secondary">End: </span> <span>{panchang.guliKaal.end}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#b22222",color:"#fffafa"}}>
                                        Rahukaal
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Start: </span> <span>{panchang.rahukaal.start}</span></td>
                                                            <td><span className="text-secondary">End: </span> <span>{panchang.rahukaal.end}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#000000",color:"#fffafa"}}>
                                        Yamghant Kaal
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Start: </span> <span>{panchang.yamghant_kaal.start}</span></td>
                                                            <td><span className="text-secondary">End: </span> <span>{panchang.yamghant_kaal.end}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                        Other Info
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Disha Shool: </span> <span>{panchang.disha_shool}</span></td>
                                                            <td><span className="text-secondary">Disha Shool Remedies: </span> <span>{panchang.disha_shool_remedies}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Moon Nivas: </span> <span>{panchang.moon_nivas}</span></td>
                                                        
                                                            <td><span className="text-secondary">Panchang Yog: </span> <span>{panchang.panchang_yog}</span></td>
                                                        </tr>
                                                       
                                                        <tr>  <td><span className="text-secondary">Shaka Samvat: </span> <span>{panchang.shaka_samvat}</span></td>
                                                       
                                                            <td><span className="text-secondary">Shaka Samvat Name: </span> <span>{panchang.shaka_samvat_name}</span></td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Vikram Samvat: </span> <span>{panchang.vikram_samvat}</span></td>
                                                            <td><span className="text-secondary">Vikram Samvat Name: </span> <span>{panchang.vkram_samvat_name}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Ritu: </span> <span>{panchang.ritu}</span></td>
                                                            <td><span className="text-secondary">Ayana: </span> 
                                                            <span>{panchang.ayana}</span></td>
                                                           {/* <td><span className="text-secondary">Nak Shool: </span> 
                                                            <span>{panchang.nak_shool.nak_shool}</span></td>*/}
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                  <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                        Chaughadiya Muhurta
                                        </div>
                                        <div className="card-body">
                                           <div className="table-responsive"><span className="card border-0 mb-1 p-2" style={{width:"100%", height:"40%" , background: "linear-gradient(to right, #b993d6, #8ca6db)",color:"#fffafa"}}>DAY</span>
                                                <table class="table">
                                                        {chaughadiyaMuhurta.chaughadiya.day.map((chaughadiya,key)=>(
                                                        <tr key={key}>
                                                            <td><span className="text-secondary">Time: </span> <span>{chaughadiya.time}</span></td>
                                                            <td><span className="text-secondary">Muhurta: </span> <span>{chaughadiya.muhurta}</span></td>
                                                        </tr>))}
                                                </table>
                                            </div>
                                            <div className="table-responsive"><span className="card border-0 mb-1 p-2" style={{width:"100%", height:"40%" , background: "linear-gradient(to right, #b993d6, #8ca6db)",color:"#fffafa"}}>NIGHT</span>
                                                <table class="table">
                                                        {chaughadiyaMuhurta.chaughadiya.night.map((chaughadiya,key)=>(
                                                        <tr key={key}>
                                                            <td><span className="text-secondary">Time: </span> <span>{chaughadiya.time}</span></td>
                                                            <td><span className="text-secondary">Muhurta: </span> <span>{chaughadiya.muhurta}</span></td>
                                                        </tr>))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "linear-gradient(to right, #c21500, #ffc500)",color:"#fffafa"}}>
                                        Hora Muhurta
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive"><span className="card border-0 mb-1 p-2" style={{width:"100%", height:"40%" , background: "linear-gradient(to right, #b993d6, #8ca6db)",color:"#fffafa"}}>DAY</span>
                                                <table class="table">
                                                        {horaMuhurta.hora.day.map((hora,key)=>(
                                                        <tr key={key}>
                                                            <td><span className="text-secondary">Time: </span> <span>{hora.time}</span></td>
                                                            <td><span className="text-secondary">Hora: </span> <span>{hora.hora}</span></td>
                                                        </tr>))}
                                                </table>
                                            </div>
                                            <div className="table-responsive"><span className="card border-0 mb-1 p-2" style={{width:"100%", height:"40%" , background: "linear-gradient(to right, #b993d6, #8ca6db)",color:"#fffafa"}}>NIGHT</span>
                                                <table class="table">
                                                        {horaMuhurta.hora.night.map((hora,key)=>(
                                                        <tr key={key}>
                                                            <td><span className="text-secondary">Time: </span> <span>{hora.time}</span></td>
                                                            <td><span className="text-secondary">Hora: </span> <span>{hora.hora}</span></td>
                                                        </tr>))}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="card border-0" style={{width:"100%", height:"40%"}}>
                                        <div className="card-header" style={{background: "radial-gradient( circle 581.9px at 10% 20%,  rgba(224,116,10,1) 0%, rgba(230,244,245,1) 85.5% )",color:"#fffafa"}}>
                                             <h6 style={{textAlign:"center"}}>Click Here to Ask Our PujyaPanditG <a href="https://wa.me/9610800108" target="_blank">
                                            <img src={whatsappLogo} alt="whatsapp" width={50} />
                    </a></h6>
                                        </div>
                       </div>
                    </div>
 
                </div>
                <div className="container-fluid desc ">
                    <div className="card im">
                        <div className="card-body">
                            <p>
                                
                                Hindu Panchang, also known as the Hindu calendar, is a system of timekeeping and astrological calculations used in Hinduism. It is based on the cycles of the moon and the sun, and includes information about lunar phases, planetary movements, auspicious times for important events, and religious festivals.
                                <br /> <br />
                                The Hindu Panchang is widely used in India and other Hindu communities around the world, and its importance in Hindu culture reflects the significant role that astrology and auspicious timing play in daily life.
                              
                               
                            </p>

                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </WrapContainer>
        </>
    );
};
export default AdvancedPanchang;
