import React, { useState } from "react";
import Headimage from "../../components/HeadImage/Headimage";
import WrapContainer from "../../components/container";
import HeaderBreadcrumb from "../../components/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "./Kundali.css";
// import DataService from "../../services/requestApi";
// import queryString from "query-string";

const data = [
//   {
//     id: 1,
//     title: "Silver",
//     Ctitle: "Silver package contains these facilities",
//     listData: [
//       "basic astrological details",
//       "Lagna & Moon chart ",
//       "Avakahada Chakra",
//       " Vimsottari dasha advance ",
//       "Pdf Of Kundli will be send via Mail ",
//     ],
//     Price: "Free",
//     mrp: "500",
//     Image: require("../../assets/img/Artboard 2.png"),
//     headImage: require("../../assets/img/Silver.png"),
//   },
  {
    id: 3,
    title: "Vedic Kundali",
    Ctitle: "Providing these Facilities in Kundali",
    listData: [
      "Advance Kundali & Lagna Chart",
        "Ashtakvarga",
        "Horoscope Charts",
        "Mangalik & Kaalsarp Dosha",
        "Suggestions and Remedies",
        "Life Reports (Prediction)",
        "Job & carrier analysis ",
        "Vimshottari Dasha",
        "Shani Sade Saati Dasha",
        "PDF & Whatsapp Support",
    ],
    description:
      '"basic astrological details","Lagna & Moon chart ", "Avakahada Chakra"," Vimsottari dasha advance ", "Kaal sarpa yoga analysis ","   Manglik Dosa calculations", " Job and carrier analysis ","Pdf Of Kundli will be send via Mail ", "Whatsapp support "',
    Price: "99",
    mrp: "1100",
    coupon: true,
    Image: require("../../assets/img/Artboard 3.png"),
    headImage: require("../../assets/img/gold.png"),
  },
//   {
//     id: 3,
//     title: "Diamond",
//     Ctitle: "Diamond package contains these facilities",
//     listData: [
//       "Premium reports and prediction Basic astrological details ",
//       "basic astrological details",
//       "Lagna & Moon chart ",
//       "Avakahada Chakra",
//       " Vimsottari dasha advance ",
//       "Kaal sarpa yoga analysis ",
//       "Manglik Dosa calculations",
//       "Job and carrier analysis ",
//       "Love marriage reports",
//       "Matching predictions",
//       "Bhava chart and chalit charts",
//       "Handmade ( हस्तलिखित) Kundli will be send via courier and Pdf at Email ",
//       " Call & Whatsapp support ",
//     ],
//     Price: "1100",
//     mrp: "2100",
//     coupon: true,
//     Image: require("../../assets/img/Artboard 1.png"),
//     headImage: require("../../assets/img/Diamond.png"),
//   },
];

const KundaliCard = (props) => {
     const token = sessionStorage.getItem("Authtoken");
  return (
    <>
      <WrapContainer>
        <HeaderBreadcrumb title="kundali" />
        <Headimage title="Astrology" />
       
        <div className="container">
          {/* description */}

          {data.map((item) => {
            return (
              <div className="card kun mt-3 mb-3">
                <div className="card-header d-flex">
                  <h3
                    style={{
                      fontSize: "4rem",
                      color: "#e9963e",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {item.title}
                  </h3>
                  <img
                    style={{ height: "50px", width: "50px" }}
                    src={item.headImage}
                    alt="kundali"
                    className="img-fluid mt-md-3 m-2"
                  />
                </div>
                <div className="Card-body col-md-12 kundaliCard ">
                  <div className="col-md-4 ">
                    <div className="image-container">
                      <img
                        src={item.Image}
                        alt="kundali"
                        className="img-fluid"
                      />
                    </div>
                    <div className="ml-5 px-5">
                      <h3 style={{ color: "#e9963e" }}>
                        ₹ {item.Price}
                        <span className="text-muted small">
                          {" "}
                          ₹<del> {item.mrp} </del>
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-6 ml-auto">
                    <div className="content">
                      <h3 className="mt-3 d-flex" style={{ gap: "10px" }}>
                        <img
                          style={{ height: "50px" }}
                          src={require("../../assets/img/flag.png")}
                          alt="kundali"
                          className="img-fluid"
                        />
                        <span className=" text">{item.Ctitle}</span>
                      </h3>
                      <ul>
                        {item.listData &&
                          item.listData.map((item, index) => (
                            <li>
                              <span className="text-bold">{item}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
               <div className="col-md-12 card-footer">
                  {token ? (
                    <Link to={{pathname:"/kundali", state:{ title :item.title,price:item.Price, giveCoupon: item.coupon} }}>
                  <div
                    className="btn btn-block ml-auto"
                    style={{ backgroundColor: "#e9963e", color: "#fff" }}
                  >
                    Click here to Get the Kundali
                  </div>
                  </Link>
                  ) : (
                    <Link to="/login">
                  <div
                    className="btn btn-block ml-auto"
                    style={{ backgroundColor: "#e9963e", color: "#fff" }}
                  >
                    Click here to Get the Kundali
                  </div>
                  </Link>
                  )}

                </div>
              </div>
            );
          })}
        </div>
        <div className="container-fluid desc ">
          <div className="card im">
          <div className="card-body">
              <p>
                What Is Kundli?
                <br />
                Kundali is an astrological chart prepared on the basis of the
                exact date of birth and time, and place of birth of an
                individual. This chart determines the positioning of various
                signs, planets, the Sun and the Moon. Kundli date of birth and
                time also reveals the astrological aspects and significant
                information of a newborn child. Done by an expert astrologer,
                Kundli creation is not a simple task. The astrologer will
                determine your Kundli through the local time and place of birth
                to calculate the rising sign and ascending status of the
                individual, or native. The chart provides an insight into a
                person’s personality, his/her present and the future. A Kundli
                prediction report consists of detailed Kundali analysis that can
                foretell the good and bad times, or phases, for the native to
                act accordingly. 
                <br/>
                Kundli matching can also be done to find the
                best match for marriage.
                <br/>
                 Apart from this, with the help of Janam
                kundali , one may understand the planetary transits in different
                signs over the day, month and year. The positions of the planets
                in your Kundali showcase various events and possibilities. Thus,
                by looking at the Janma kundli and the planetary view therein,
                an astrologer predicts the future of a native. This is done
                using time tested principles of Vedic Astrology. 
                <br/>
                Online Kundali-
                Its Benefits Nowadays, creating kundli online and free Janam
                Kundali analysis is a common and very popular means of getting
                future predictions. An online Kundali report proves very
                reliable and helpful when prepared by learned and experienced
                astrologers, who can predict your future and traits accurately.
                <br/>
                Online Kundali making can be beneficial in many ways:
                Pujyapanditg With the help of a Kundli, an astrologer can
                predict your future in the most accurate way. According to the
                positioning of planets in your Kundli, you can pick the most
                suitable career choice, one which is in harmony with your
                personality. It can give you detailed information about your
                personality traits, relationships, marriage, career, finances
                and other aspects of life. 
                <br/>
                It can help find your lucky gemstone,
                lucky colours and lucky numbers that aid your success and
                happiness in various aspects of life. A Kundli not only gives
                information about your future, but can also help find remedies
                to decrease the depth of the problems.
                <br/>
                 Kundli highlights the
                doshas such as Mangal Dosha and Kaal Sarp Dosh, so that these
                doshas can be resolved with the 
                Dosha Nivaran Puja. 
                <br/>
                A Kundli
                also foretells the favorable and unfavorable timing in life. You
                can find your strengths and weaknesses to help become a better
                person. 
                <br/>
                A Natal Chart showcases and warns you about the diseases
                and adversities that are likely to affect you in the future.
                Check your Kundli online for Free on <a href="https://pujyapanditg.com/ " >Pujyapanditg.com</a>
              </p>
                
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </WrapContainer>
    </>
  );
};
export default KundaliCard;
