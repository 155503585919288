import React, { useEffect, useState,useCallback } from "react";

// Core components
import HeaderBreadcrumb from "../components/BreadCrumb";
import WrapContainer from "../components/container";

import Headimage from '../components/HeadImage/Headimage';

// APi
import Dataservices from "../services/requestApi";
import VPS from './VPS';
const SanatanDharama = (props) => {
  const [, setProduct] = useState([]);
  const [pdf, setAllPDF] = useState([])
  const [pdfData, setDatapdf] = useState([])
  const [mounted, setMounted] = useState(true);
  const token = sessionStorage.getItem("Authtoken");
  const getAllPDF = useCallback(async (e) => {
    try {
      const res = await Dataservices.getAllPdf();
      // console.log(res.data.data);
      const filterproduct = res.data.data.filter((product) => {
        return product.type==="Pooja Path";
      })
      setAllPDF(filterproduct);
      setDatapdf(res.data.data);
      
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    getAllPDF();
    const getData = async (e) => {
      try {
        if (token) {
          const productRes = await Dataservices.GetCartAll();
          if (mounted) {
            setProduct(productRes.data.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      getData();
      setMounted(false);
    };

  }, [mounted, token,getAllPDF]);
  const VedhData = [ 
    {
      "name": "Vedh",
      "pdf": "vedh.pdf",
    }
    ];
    const PoojaPathData = [
      {
        "name": "PoojaPath",
        "pdf": "poojaPath.pdf",
      }
    ];
    const StotraData = [
      {
        "name": "Stotra",
        "pdf": "stotra.pdf",
      }
    ];
  const [VPSTitle, setVPSTitle] = useState("Pooja Path");
  const [, setVPSData] = useState(PoojaPathData);
  const Filterdata = (CateData) => {
      console.log(CateData,pdfData)
      const filterproduct = pdfData.filter((product) => {
        return product.type===CateData;
      })
      setAllPDF(filterproduct);
  }
  return (
    <WrapContainer>
      <HeaderBreadcrumb title="Sanatan Dharama" />
      <Headimage title="Sanatan Dharma" />
      <ul className="nav container-fluid mt-5 ml-3" style={{
        gap: "10px",
        marginTop: "1rem",
      }}>
      <li className="nav-item">
        <button className="btn btn-lg stdh-buttons" style={{backgroundColor:"#f5f5f5",color:"#000"}} onClick={()=>{
          setVPSTitle("Vedas & Upnishd");
          setVPSData(VedhData);
          Filterdata('Vedas & Upnishd');
        }}>
          Vedas & Upnishd
        </button>
      </li>
      <li className="nav-item">
        <button className="btn btn-lg stdh-buttons" style={{backgroundColor:"#f5f5f5",color:"#000"}}
          onClick={()=>{
            setVPSTitle("Pooja Path");
            setVPSData(PoojaPathData);
          Filterdata('For Pandit Ji');
          }} autoFocus>
          For Pandit Ji
        </button>
      </li>
      <li className="nav-item">
        <button className="btn btn-lg stdh-buttons" style={{backgroundColor:"#f5f5f5",color:"#000"}} 
        onClick={()=>{
          setVPSTitle("Stotra");
          setVPSData(StotraData);
          Filterdata('Stotra');
        }}>
          Stotra
        </button>
      </li>
    </ul>
      <br />
      <VPS title={VPSTitle} data={pdf} style={{
        marginTop: "20px",
      }}/>
    </WrapContainer>
  );
};
export default SanatanDharama;