import React, { useState } from "react";
import Headimage from "../../components/HeadImage/Headimage";
import WrapContainer from "../../components/container";
import HeaderBreadcrumb from "../../components/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import DataService from "../../services/requestApi";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { Radio } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import { Col, Spinner } from "reactstrap";
import "./Kundali.css"
import verses from '../../assets/img/versus.png'
import moment from 'moment'
import Swal from 'sweetalert2'
import SVG from 'react-inlinesvg';
import whatsappLogo from '../../assets/img/whatsapp.png';
import { City }  from 'country-state-city';
import Autosuggest from 'react-autosuggest';
import theme from './theme.module.css'

const Matching = () => {

  const history = useHistory();
const cities = City.getCitiesOfCountry("IN");
  const getSuggestions = (value) => {
    console.log('check input value', value);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
  
    return inputLength === 0 ? [] : cities.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    const suggestions = getSuggestions(value);
    setCitySuggestions(suggestions);
  };
  const getSuggestionValue = (suggestion) => {
    return suggestion.name
  };
  const renderSuggestion = suggestion => (
    <div>
      {suggestion.name+' ('+suggestion.stateCode+')' }
    </div>
  );
  
  const [citySuggestions,setCitySuggestions] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  // make a post request to the server
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    try {
          const data = {
            mName,
            MDOB,
            MTOD,
            mBPlace,
            fName,
            FDOB,
            FTOD,
            fBPlace,
          };
          const res = await DataService.getMatching(queryString.stringify(data));
          console.log('check res', res.data);
          if(res.status!=200)
          history.push('/login');
          if(res.data.resCode == 200)
          {
            setIsSubmit(false);
            setShowMatching(res.data.data);
            console.log("free res 😀🤩🎉",res.data);
          }
          else {
            // react toastify danger
            setIsSubmit(false);
            // toast.error("Please Login first!", {
            //   position: 'top-center'});
              Swal.fire({
  title: 'Error!',
  text: 'Please login first!',
  icon: 'error',
  confirmButtonText: 'Okay'
}).then(()=>{
             history.push("/login");
})
          }
    } catch (e) {
      console.log(e);
    }
  };

//   male states
  const [mName, setMName] = useState("");
  const [MDOB, setMDOB] = useState("");
  const [MTOD, setMTOD] = useState("");
  const [mBPlace, setMBPlace] = useState("");

// female states
  const [fName, setFName] = useState("");
  const [FDOB, setFDOB] = useState("");
  const [FTOD, setFTOD] = useState("");
  const [fBPlace, setFBPlace] = useState("");

  const [showMatching, setShowMatching] = useState(false);
// generate id on submit
 const showDegree = (degree) => {
        let castDegree = degree.toString();
        let [ degree1, degree2 ] = castDegree.split(".");

        return degree1+'°'+degree2.slice(0, 4)
    }
  return (
    <>
      {/* kundali form  */}
      <WrapContainer>
      <ToastContainer />

        <HeaderBreadcrumb title="Matching" />
        <Headimage title="Matching" />
        {/*<div className="container-fluid desc ">
          <div className="card im">
            <div className="card-body">
              <p>
                Pujya Pandit G Kundali | Janam Kundali Online - जन्म कुंडली
                Janam Kundli also known as Janampatri or Birth chart or Kundli
                Chart or Natal Chart, depicts an individual’s life based on the
                12 houses. Kundali, Vedic charts, graphical representation of
                positions of houses and planets are prepared based on the date,
                time and place of birth of an individual.
              </p>
            </div>
          </div>
        </div>*/}
        <div className="container">
          {showMatching==false?isSubmit==false?<div className="row">
            <div className="col-md-12 mt-3">
              <div className="card border-0">
                {/* <div className="card-header">
                        <h4 className="card-title">Kundali</h4>
                    </div> */}
                    <form onSubmit={handleSubmit}>
                <div className="card-body p-3">
                    {/* male section  */}
                    <div className="card mb-4" style={{boxShadow: "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"}}>
                        <div className="card-header text-white d-flex justify-content-center align-items-center" style={{background: "linear-gradient(to right, #c21500, #ffc500)"}}>
                            <b className="text-center">Male Bio </b>
                            <h5 className="bg-danger border d-inline-block mb-0 ml-2 p-1 rounded-circle">🤵️</h5>

                        </div>
                        <div className="card-content p-2">
                            <div className="form-group">
                                <label className="control-label">Name</label>
                                <input
                                required
                                type="text"
                                className="form-control"
                                placeholder="Enter your name"
                                onChange={(e) => setMName(e.target.value)}
                                //   value={Name}
                                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Date of Birth</label>
                                <input
                                required
                                type="date"
                                className="form-control"
                                placeholder="Enter your date of birth"
                                onChange={(e) => setMDOB(e.target.value)}
                                //   value={DOB}
                                />
                            </div>
                            
                            <div className="form-group">
                                <label className="control-label">Time of Birth</label>
                                <input
                                required
                                type="time"
                                className="form-control"
                                placeholder="Enter your time of birth"
                                onChange={(e) => setMTOD(e.target.value)}
                                //   value={TOD}
                                />
                            </div>

                            <div className="form-group">
                                <label className="control-label">Place of Birth</label>
                               { /* <input
                                required
                                type="text"
                                className="form-control"
                                placeholder="Enter your place of birth"
                                onChange={(e) => setMBPlace(e.target.value)}
                                //   value={BPlace}
                                /> */}
                                   <Autosuggest
                                suggestions={citySuggestions}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={()=>setCitySuggestions([])}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                theme={theme}
                                inputProps={{ placeholder: "Enter your place of birth",value: mBPlace, onChange: (event,{newValue}) =>{setMBPlace(newValue)}}}
                              />
                            </div>
                            
                        </div>
                    </div>

                    {/* female secition  */}
                    <div className="card mb-4" style={{boxShadow: "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"}}>
                        <div className="card-header text-white d-flex justify-content-center align-items-center" style={{background: "linear-gradient(to right, #c21500, #ffc500)"}}>
                            <b className="text-center">Female Bio  </b>
                            <h5 className="bg-danger border d-inline-block mb-0 ml-2 p-1 rounded-circle">👰️</h5>
                        </div>
                        <div className="card-content p-2">
                            <div className="form-group">
                                <label className="control-label">Name</label>
                                <input
                                required
                                type="text"
                                className="form-control"
                                placeholder="Enter your name"
                                onChange={(e) => setFName(e.target.value)}
                                //   value={Name}
                                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Date of Birth</label>
                                <input
                                required
                                type="date"
                                className="form-control"
                                placeholder="Enter your date of birth"
                                onChange={(e) => setFDOB(e.target.value)}
                                //   value={DOB}
                                />
                            </div>
                            
                            <div className="form-group">
                                <label className="control-label">Time of Birth</label>
                                <input
                                required
                                type="time"
                                className="form-control"
                                placeholder="Enter your time of birth"
                                onChange={(e) => setFTOD(e.target.value)}
                                //   value={TOD}
                                />
                            </div>

                            <div className="form-group">
                                <label className="control-label">Place of Birth</label>
                                {/*  <input
                                required
                                type="text"
                                className="form-control"
                                placeholder="Enter your place of birth"
                                onChange={(e) => setFBPlace(e.target.value)}
                                //   value={BPlace}
                                /> */}
                                <Autosuggest
                                suggestions={citySuggestions}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={()=>setCitySuggestions([])}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                theme={theme}
                                inputProps={{ placeholder: "Enter your place of birth",value: fBPlace, onChange: (event,{newValue}) =>{setFBPlace(newValue)}}}
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="card-footer bg-transparent border-0">
                  <button
                    type="submit"
                    className="btn  btn-block "
                    style={{
                        backgroundColor:'#e9963e',
                        color:'#fff',
                    }}
                    >
                    Submit
                  </button>
                </div>
            </form>
              </div>
            </div>
          </div>:<div className="container d-flex align-items-center justify-content-center my-5">
                  <div>
                  <Spinner className="d-block mx-auto mb-2" />
                  <h6>Please Wait!</h6>

                  </div>
                </div>:
                <div className="row">
                  <div className="border p-3 w-100">
                    
                    <div className="card text-center">
                      <div className="all_product pt-3">
                        <div className="heading mb-3">
                          <h1 className="matching_heading"><span style={{ color: 'black',fontWeight:'450',fontSize:'30px' }}>Birth&nbsp;</span><span style={{fontSize:'30px'}}>Details</span></h1>
                        </div>
                      </div>
                      <div className="card-header text-white font-weight-bold" style={{background: "linear-gradient(to right, #c21500, #ffc500)"}}>
                        {mName} 
                        <img className="mx-3" src={verses} width={15} alt="" />
                         {fName}
                      </div>
                      <ul className="list-group list-group-flush m-0">
                        <li className="list-group-item">{moment(MDOB).format("DD/MM/YYYY") }<b className="mx-3">Birth Date</b>{moment(FDOB).format("DD/MM/YYYY")}</li>
                        <li className="list-group-item">{MTOD}<b className="mx-3">Birth Time</b>{FTOD}</li>
                        <li className="list-group-item">{mBPlace}<b className="mx-3">Birth Place</b>{fBPlace}</li>
                       <li className="list-group-item">{showMatching.matchBirth.male_astro_details.sunrise}<b className="mx-3">Sunrise</b>{showMatching.matchBirth.female_astro_details.sunrise}</li>
                       <li className="list-group-item">{showMatching.matchBirth.male_astro_details.sunset}<b className="mx-3">Sunset</b>{showMatching.matchBirth.female_astro_details.sunset}</li>
                        <li className="list-group-item">{showDegree(showMatching.matchBirth.male_astro_details.ayanamsha)}<b className="mx-3">Ayanamsha</b>{showDegree(showMatching.matchBirth.female_astro_details.ayanamsha)}</li>
                        <li className="list-group-item">{showMatching.matchBirth.male_astro_details.latitude}<b className="mx-3">latitude</b>{showMatching.matchBirth.female_astro_details.latitude}</li>
                        <li className="list-group-item">{showMatching.matchBirth.male_astro_details.longitude}<b className="mx-3">longitude</b>{showMatching.matchBirth.female_astro_details.longitude}</li>
                        <li className="list-group-item">{showMatching.matchBirth.male_astro_details.timezone}<b className="mx-3">Timezone</b>{showMatching.matchBirth.female_astro_details.timezone}</li>
                      </ul>
                    </div>
                    <div className="card text-center">
                      <div className="all_product pt-3">
                        <div className="heading mb-3">
                          <h1 className="matching_heading"><span style={{ color: 'black',fontWeight:'450',fontSize:'30px' }}>Ashtakoot&nbsp;</span><span style={{fontSize:'30px'}}>Points</span></h1>
                        </div>
                      </div>
                      <div className="card-header text-white font-weight-bold" style={{background: "linear-gradient(to right, #c21500, #ffc500)"}}>
                        {mName} 
                        <img className="mx-3" src={verses} width={15} alt="" />
                         {fName}
                      </div>
                      <ul className="list-group list-group-flush m-0">
                        <li className="list-group-item">{showMatching.ashtakootPoints.bhakut.male_koot_attribute}<b className="mx-3">Bhakut Koot Attribute</b>{showMatching.ashtakootPoints.bhakut.female_koot_attribute}</li>
                        <li className="list-group-item">{showMatching.ashtakootPoints.varna.male_koot_attribute}<b className="mx-3">Varna Koot Attribute</b>{showMatching.ashtakootPoints.varna.female_koot_attribute}</li>
                        <li className="list-group-item">{showMatching.ashtakootPoints.vashya.male_koot_attribute}<b className="mx-3">Vashya Koot Attribute</b>{showMatching.ashtakootPoints.vashya.female_koot_attribute}</li>
                        <li className="list-group-item">{showMatching.ashtakootPoints.maitri.male_koot_attribute}<b className="mx-3">maitri Koot Attribute</b>{showMatching.ashtakootPoints.maitri.female_koot_attribute}</li>
                        <li className="list-group-item">{showMatching.ashtakootPoints.gan.male_koot_attribute}<b className="mx-3">Gan Koot Attribute</b>{showMatching.ashtakootPoints.gan.female_koot_attribute}</li>
                         <li className="list-group-item">{showMatching.ashtakootPoints.nadi.male_koot_attribute}<b className="mx-3">Nadi Koot Attribute</b>{showMatching.ashtakootPoints.nadi.female_koot_attribute}</li>
                      </ul>
                    </div>
                                     <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Bhakut
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.bhakut.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.bhakut.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.bhakut.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Varna
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.varna.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.varna.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.varna.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Vashya
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.vashya.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.vashya.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.vashya.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Tara
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.tara.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.tara.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.tara.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Yoni
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.yoni.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.yoni.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.yoni.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Maitri
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.maitri.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.maitri.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.maitri.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Gan
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.gan.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.gan.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.gan.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000"}}>
                                            Nadi
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            <td><span className="text-secondary">Description: </span> <span>{showMatching.ashtakootPoints.nadi.description}</span></td>
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.nadi.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.nadi.received_points}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"	#8b0000"}}>
                                            Total
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            
                                                            <td><span className="text-secondary">Total Points: </span> <span>{showMatching.ashtakootPoints.total.total_points}</span></td>
                                                            <td><span className="text-secondary">Received Points: </span> <span>{showMatching.ashtakootPoints.total.received_points}</span></td>
                                                            <td><span className="text-secondary">Minimum Required: </span> <span>{showMatching.ashtakootPoints.total.minimum_required}</span></td>
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                   
                      <div className="card text-center">
                      <div className="all_product pt-3">
                        <div className="heading mb-3">
                          <h1 className="matching_heading"><span style={{ color: 'black',fontWeight:'450', fontSize:'30px'}}>Manglik&nbsp;</span><span style={{fontSize:'30px'}}>Report</span></h1>
                        </div>
                      </div>
                      <div className="card-header text-white font-weight-bold" style={{background: "linear-gradient(to right, #c21500, #ffc500)"}}>
                        {mName} 
                        <img className="mx-3" src={verses} width={15} alt="" />
                         {fName}
                      </div>
                     
                                    <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000",fontWeight:'650'}}>
                                            Male
                                        </div>
                                        <div className="card-body" style={{textAlign: 'left'}}>
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            
                                                            <td><span className="text-secondary">Manglik Present Rule(Based on Aspect): </span> <span>{showMatching.manglikReport.male.manglik_present_rule.based_on_aspect}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Manglik Present Rule(Based on House): </span> <span>{showMatching.manglikReport.male.manglik_present_rule.based_on_house}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Manglik Cancel Rule: </span> <span>{showMatching.manglikReport.male.manglik_cancel_rule}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Manglik Status: </span> <span>{showMatching.manglikReport.male.manglik_status}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Percentage Manglik Present: </span> <span>{showMatching.manglikReport.male.percentage_manglik_present}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Percentage Manglik After Cancellation: </span> <span>{showMatching.manglikReport.male.percentage_manglik_after_cancellation}</span></td>
                                                        </tr>
                                                         <tr> <td><span className="text-secondary">Manglik Report: </span> <span>{showMatching.manglikReport.male.manglik_report}</span></td>
                                                            {/*<td><span className="text-secondary">Present: </span> <span>{showMatching.manglikReport.male.is_pt}</span></td>*/}
                                                        </tr>
                                                        
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"#8b0000",fontWeight:'650'}}>
                                            Female
                                        </div>
                                        <div className="card-body" style={{textAlign: 'left'}}>
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            
                                                            <td><span className="text-secondary">Manglik Present Rule(Based on Aspect): </span> <span>{showMatching.manglikReport.female.manglik_present_rule.based_on_aspect}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Manglik Present Rule(Based on House): </span> <span>{showMatching.manglikReport.female.manglik_present_rule.based_on_house}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Manglik Cancel Rule: </span> <span>{showMatching.manglikReport.female.manglik_cancel_rule}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Manglik Status: </span> <span>{showMatching.manglikReport.female.manglik_status}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Percentage Manglik Present: </span> <span>{showMatching.manglikReport.female.percentage_manglik_present}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="text-secondary">Percentage Manglik After Cancellation: </span> <span>{showMatching.manglikReport.female.percentage_manglik_after_cancellation}</span></td>
                                                        </tr>
                                                         <tr> <td><span className="text-secondary">Manglik Report: </span> <span>{showMatching.manglikReport.female.manglik_report}</span></td>
                                                           {/* <td><span className="text-secondary">Present: </span> <span>{showMatching.manglikReport.female.is_present}</span></td>*/}
                                                         </tr>
                                                        
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                      <div class="card border-0">
                                        <div className="card-header text-white font-weight-bold" style={{background: "linear-gradient(to right, #c21500, #ffc500)"}}>
                        {mName} 
                        <img className="mx-3" src={verses} width={15} alt="" />
                         {fName}
                      </div>
                                        <div className="card-body" style={{textAlign: 'left'}}>
                                    <div className="table-responsive">
                                        <table className="my-3" >
                                            <caption style={{background: "#f0e68c",color:"#8b0000",fontWeight:'650'}}>लग्न कुण्डली</caption>
                                                <tr id="firstChart"> 
                                                <div className="row p-3">
                                                    <div className="col-lg-6">
                                                       {/* <span className="text-secondary d-block pl-2">लग्न कुण्डली: </span>*/}
                                                        <SVG src={showMatching.horoChartMale.svg.replace('\'', "")} />
                                                    </div>
                                                    <div className="col-lg-6">
                                                       {/* <span className="text-secondary d-block pl-2">नवमांश कुण्डली: </span>*/}
                                                        <SVG src={showMatching.horoChartFemale.svg.replace('\'', "")} />
                                                    </div>
                                                </div>
                                                </tr>
                                        </table>
                                    </div>
                                    </div>
                                    </div>
                                    
                                     <div class="card border-0">
                                        <div className="card-header" style={{background: "#f0e68c",color:"	#8b0000",fontWeight:'650',textAlign: 'left'}}>
                                            Conclusion By Pujyapanditg / पूज्य पंडित जी द्वारा गुण मिलान निष्कर्ष
                                        </div>
                                        <div className="card-body" style={{textAlign: 'left'}}>
                                            <div className="table-responsive">
                                                <table class="table">
                                                        <tr>
                                                            
                                                            <td><span className="text-secondary">Report: </span> <span>{showMatching.ashtakootPoints.conclusion.report}</span></td>
                                                           
                                                        </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-0" style={{width:"100%", height:"40%"}}>
                                        <div className="card-header" style={{background: "radial-gradient( circle 581.9px at 10% 20%,  rgba(224,116,10,1) 0%, rgba(230,244,245,1) 85.5% )",color:"#fffafa"}}>
                                             <h6>Click Here to Ask Our PujyaPanditG <a href="https://wa.me/9610800108" target="_blank">
                                            <img src={whatsappLogo} alt="whatsapp" width={50} />
                    </a></h6>
                                        </div>
                       </div>
                    </div>
                  </div>
                </div>
                }

        </div>
        <div className="container-fluid desc ">
          <div className="card im">
          <div className="card-body">
              <p>
               the matching of couples is often done through a process known as Kundli matching or horoscope matching. This involves comparing the birth charts of the bride and groom to determine their compatibility and the likelihood of a successful marriage.
                <br/><br/>
              If the Kundli matching is successful, the couple is considered to be compatible and the marriage is considered to be auspicious. However, if there are any mismatches or negative indications in the charts, it may be deemed inauspicious to proceed with the marriage. The final decision, however, is usually left to the families of the bride and groom, who may choose to follow or ignore the astrological advice.
              </p>
            </div>
          </div>
        </div>
        
      </WrapContainer>
    </>
  );
};

export default Matching;
