import React, { useEffect } from 'react'
import {  useHistory } from "react-router-dom";

// APi
import Dataservices from "../services/requestApi";
import queryString from "query-string";
import Loader from '../components/loader/Loader';

function Return() {
    let history = useHistory();
    var url_string = window.location.href; 
    var url = new URL(url_string);
    var order_id = url.searchParams.get("order_id");
    var order_token = url.searchParams.get("order_token");
    useEffect(() => {

        Dataservices.OrderResponse(queryString.stringify({ order_id, order_token })).then(async (res)=>{
          // console.log(res.data)
          if(res.data.product_detiles.length===1)
          {
            const res1 = await Dataservices.GetProductById(res.data.product_detiles[0].product_id);
            const product = res1.data.data;
            if(res.data.status==="Order Confirmed")
            {
              history.push({pathname:'/',state:{orderDetail:true,name:product.name,status:"success",order_id:res.data._id}});
            }
            else{
              history.push({pathname:'/',state:{orderDetail:true,name:product.name,status:"fail",order_id:res.data._id}});
            }
          }
          else{
            if(res.data.status==="Order Confirmed")
            {
              history.push({pathname:'/',state:{orderDetail:true,name:"Cart items",status:"success",order_id:res.data._id}});
            }
            else{
              history.push({pathname:'/',state:{orderDetail:true,name:"Cart items",status:"fail",order_id:res.data._id}});
            }
          }
          })
    }, )

    return (
      <Loader show={true} />
    )
}

export default Return