import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Button
} from "reactstrap";
import '../../assets/scss/scaleCSS.css';

// Core components
import HeaderBreadcrumb from "../../components/BreadCrumb";
import WrapContainer from "../../components/container";
// APi
import Dataservices from "../../services/requestApi";
import { Link } from "react-router-dom";
import TraceStatus from "./TraceStatus";
// import queryString from "query-string";
const AllOrder = (props) => {
  const [orderall, setAllOrder] = useState([]);
  const [productall, setProductAll] = useState([]);
  const [kundaliList, setKundaliList] = useState([])
  const [mounted, setMounted] = useState(true);
  const [orderStatus, setOrderStatus] = useState("");
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await Dataservices.GetAllPoojaOrder();
        console.log(res);
        if (mounted) {
          setAllOrder(res.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      setMounted(false);
      getData();
    };
  }, [mounted]);
  // product order 
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await Dataservices.getAllOrder();
        console.log("product order", res.data);
        if (mounted) {
          setProductAll(res.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      setMounted(false);
      getData();
    };
  }, [mounted]);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await Dataservices.getAllKundali();
        console.log("kundali list", res.data);
        if (mounted) {
          setKundaliList(res.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      setMounted(false);
      getData();
    };
  }, [mounted])

  return (
    <>
      <WrapContainer>
        <TraceStatus status={orderStatus} setOrderStatus={setOrderStatus} />
        <HeaderBreadcrumb title="My Order" />
        <div className="profile">
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="12">
                <Card className="mb-3">
                  <CardHeader>All Pooja Order</CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Puja Date</th>
                            <th scope="col">Pooja type</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Payment Id</th>
                            <th scope="col">Order Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderall &&
                            orderall.map((item, index) => (
                              <tr key={item._id}>
                                <th>{index + 1}</th>
                                <td>
                                  <Link
                                    to={`/pooja/${item.puja_id && item.puja_id._id}`}
                                    tag={Link}
                                  >
                                    {item.puja_id && item.puja_id.name}
                                  </Link>
                                </td>
                                <td>{item.puja_amount}</td>
                                <td>{moment(item.puja_date).format("ll")}</td>
                                <td>{item.puja_id && item.puja_id.epooja ? 'E-Pooja' : 'Pooja'}</td>
                                <td>{moment(item.created_at).format("ll")}</td>
                                <td>{item.payment_id}</td>
                                <td>
                                  <div className="border d-inline-flex border-success rounded p-1 font-weight-bold align-items-center pr-3" style={{}}>
                                    {item.status == "Completed" ? <i className="fa fa-check-circle text-success mx-1" aria-hidden="true"></i> : (item.status == "Cancelled" ? <i className="fa fa-times-circle text-danger mx-1" aria-hidden="true"></i> :
                                      <div className="wrapper shadow-none">
                                        <div className="circle pulse" />
                                        <div className="circle">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                          </svg>
                                        </div>
                                      </div>)}
                                    {item.status}
                                  </div>

                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mb-3">
                  <CardHeader>All Product Order</CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date</th>
                            <th scope="col">Payment Id</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productall &&
                            productall.map((item, index) => (
                              <tr key={item._id}>
                                <th>{index + 1}</th>
                                <td>
                                  {item.product_detiles[0].product_id.name}
                                </td>
                                <td>{item.amount}</td>
                                <td>{moment(item.created_at).format("ll")}</td>
                                <td>{item.payment_id}</td>
                                <td>{item.Payment_status == "ACTIVE" ? 'Payment Success' : item.Payment_status}</td>
                                <td>
                                  {['Order Delivered', 'Cancelled'].includes(item.status) ?<div className="border d-inline-flex border-success rounded p-1 font-weight-bold align-items-center pr-3">
                                    {item.status == "Order Delivered" ? <i className="fa fa-check-circle text-success mx-1" aria-hidden="true"></i> : (item.status == "Cancelled" ? <i className="fa fa-times-circle text-danger mx-1" aria-hidden="true"></i> :
                                      <div className="wrapper shadow-none">
                                        <div className="circle pulse" />
                                        <div className="circle">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                          </svg>
                                        </div>
                                      </div>)}
                                    {item.status}
                                  </div> :
                                  <button className="btn btn-amber btn-rounded px-4 text-white mb-2 mx-md-2 mb-md-0 nav-link d-inline-block"
                                    style={{ fontWeight: '500' }}
                                    onClick={() => { setOrderStatus(item.status) }}
                                  >
                                    Track Order
                                  </button>}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                <Card className="mb-3">
                  <CardHeader>All Kundali Order</CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date</th>
                            <th scope="col">Packege Name</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Kundali</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productall &&
                            kundaliList.map((item, index) => (
                              <tr key={item._id}>
                                <th>{index + 1}</th>
                                <td>
                                  {item.contact_name}
                                </td>
                                <td>{item.amount}</td>
                                <td>{moment(item.created_at).format("ll")}</td>
                                <td>{item.Packege_Name}</td>
                                <td>{item.Payment_status}</td>
                                <td>
                                  <div className="border d-inline-flex border-success rounded p-1 font-weight-bold align-items-center pr-3" style={{}}>
                                    {item.status == "Order Delivered" ? <i className="fa fa-check-circle text-success mx-1" aria-hidden="true"></i> : (item.status == "Cancelled" ? <i className="fa fa-times-circle text-danger mx-1" aria-hidden="true"></i> :
                                      <div className="wrapper shadow-none">
                                        <div className="circle pulse" />
                                        <div className="circle">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                          </svg>
                                        </div>
                                      </div>)}
                                    {item.status}
                                  </div>

                                </td>
                                <td>
                                  <Link to={`/view/kundali/${item._id}`} class="btn btn-outline-amber btn-rounded"><i class="fa fa-eye" aria-hidden="true"></i> &nbsp; View Kundali</Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </WrapContainer>
    </>
  );
};
export default AllOrder;