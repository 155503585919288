import React, { useEffect, useState } from 'react'
import { ModalBody, ModalHeader, Row } from 'reactstrap'
import { Modal } from "antd";

function TraceStatus({ status, setOrderStatus }) {
    const [steps, setSteps] = useState(0)
    const allStep = {
        'Order Confirmed': 0,
        'ACTIVE': 0,
        'Order Dispatched' : 1,
        'In Process': 1,
        'Out for Delivery' : 2,
        'Order Delivered' : 3
    }
    useEffect(() => {
        const getStep = allStep[status];
        setSteps(getStep);
    }, [status])

    useEffect(() => {
        console.log('steps', steps);
    }, [steps])
    
    return (
        <Modal
            visible={status !== ""}
            okText="Ok"
            destroyOnClose
            footer={false}
            centered
            onCancel={() => { setOrderStatus('') }}
            width="600px"
        >
            <ModalHeader>
                Trace Status
            </ModalHeader>
            <ModalBody>
                <div className='row' >
                    <div className='col-3 text-center p-0'>
                        <div className='position-relative'>
                            <div className='position-absolute w-100' style={{ border: steps >= 0 ? '1px solid #32cd32' : '', top: '50%' }}></div>
                            <img className='p-1 border rounded-circle position-relative' style={{ zIndex: '1' }} src="/order-placed.png" width="50" alt="order-placed" />
                        </div>
                        <small className='font-weight-bold' style={{whiteSpace:'nowrap'}}>Order Confirmed</small>
                    </div>
                    <div className='col-3 text-center p-0'>
                        <div className='position-relative'>
                            {steps >= 1 && <div className='position-absolute w-100' style={{ border: steps >= 1 ? '1px solid #32cd32' : '', top: '50%' }}></div>}
                            <img className='p-1 border rounded-circle position-relative' style={{ zIndex: '1' }} src="/shipping-truck.png" width="50" alt="order-placed" />
                        </div>
                        <small className='font-weight-bold'>In Transit</small>
                    </div>
                    <div className='col-3 text-center p-0'>
                        <div className='position-relative'>
                            {steps >= 2 &&<div className='position-absolute w-100' style={{ border: steps >= 2 ? '1px solid #32cd32' : '', top: '50%' }}></div>}
                            <img className='p-1 border rounded-circle position-relative' style={{ zIndex: '1' }} src="/delivery-scooter.png" width="50" alt="order-placed" />
                        </div>
                        <small className='font-weight-bold'>Out For Delivery</small>
                    </div>
                    <div className='col-3 text-center p-0'>
                        <div className='position-relative'>
                            {steps >= 3 &&<div className='position-absolute w-100' style={{ border: steps >= 3 ? '1px solid #32cd32' : '', top: '50%' }}></div>}
                            <img className='p-1 border rounded-circle position-relative' style={{ zIndex: '1' }} src="/delivered-ico.png" width="50" alt="order-placed" />
                        </div>
                        <small className='font-weight-bold'>Delivered</small>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default TraceStatus