import React, { useEffect } from 'react'
import {  useHistory } from "react-router-dom";
import Loader from '../components/loader/Loader';

// APi
import Dataservices from "../services/requestApi";
import queryString from "query-string";

function PoojaReturn() {
    let history = useHistory();

    var url_string = window.location.href; 
    var url = new URL(url_string);
    var order_id = url.searchParams.get("order_id");
    var order_token = url.searchParams.get("order_token");

    useEffect(() => {

        Dataservices.BookPoojaUpdate(queryString.stringify({ order_id, order_token })).then(async (res)=>{
          // console.log(res.data)
          const res1 = await Dataservices.GetPoojaById(res.data.puja_id);
          const product = res1.data.data;
            // console.log(res1)
            if(res.data.status==="success")
            {
              history.push({pathname:'/',state:{orderDetail:true,name:product.name,status:"success",order_id:res.data._id}});
            }
            else{
              history.push({pathname:'/',state:{orderDetail:true,name:product.name,status:"fail",order_id:res.data._id}});
            }
            
          })
    }, [history, order_id, order_token]);
  return (
    <Loader show={true} />
  )
}

export default PoojaReturn