import React,{useState} from "react";
import { Container, Row, Col, Button } from "reactstrap";
import 'remixicon/fonts/remixicon.css'
import {message} from "antd";
import queryString from "query-string";
import Dataservices from "../../services/requestApi";
const Footer = (props) => {
      const [email, setEmail] = useState();
    const feedbackCreate=async (e)=>{
        e.preventDefault();
        try{
            const data={
                Email: email
            }
            const res=await Dataservices.feedbackCreate(queryString.stringify(data));
            // console.log(res)
             if (res.status=== 200) {
                    message.success(res.data.msg);
             
               }
               else{
                    message.error('something went wrong');
               }
        }
        catch(e){
            console.log('feedback create error comes',e);
        }
    }
    return (
  <div className="footer">
    <Container fluid>
      <Row>
        <Col lg="3">
          <div className="footer_wrap">
            <div className="footer_logo">
              <img
                alt="logo"
                src={require("../../assets/img/logo.png")}
                width="200"
              />
            </div>
          </div>
          <div className="footer_wrap">
            <div className="footer_head">
              <h2>Join Newsletters</h2>
            </div>
            <div className="newsletter_form">
            <form onSubmit={(e)=>feedbackCreate(e)}>
              <div className="content_form">
                <input
                  type="email"
                  name="EMAIL"
                  placeholder="Email address"
                  required
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  className="form-control"
                />
                <div className="form_action">
                  <Button type="submit" color="amber">
                    <i className="fas fa-paper-plane" />
                  </Button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </Col>
        <Col lg="3">
          <div className="footer_wrap">
            <div className="footer_head">
              <h2 style={{ visibility:'hidden' }}>Our Products</h2>
            </div>
            <div className="footer_links">
              <ul>
                <li>
                  <a href="/">
                    <i className="lnr lnr-chevron-right" /> Home{" "}
                  </a>
                </li>
                
              <li>
                  <a href="/all-product">
                    <i className="lnr lnr-chevron-right" /> Products
                  </a>
                </li> 
                <li>
                  <a href="/all-pooja">
                    <i className="lnr lnr-chevron-right" /> Pooja Booking
                  </a>
                </li>
                <li>
                  <a href="/all-article">
                    <i className="lnr lnr-chevron-right" /> Articles
                  </a>
                </li>
               
                <li>
                  <a href="/STDH">
                    <i className="lnr lnr-chevron-right" /> Sanatan Dharma
                  </a>
                </li>
                <li>
                  <a href="/KundaliCard">
                    <i className="lnr lnr-chevron-right" /> Kundali
                  </a>
                </li>
                <li>
                  <a href="/EpoojaBook">
                    <i className="lnr lnr-chevron-right" /> Epooja Services
                  </a>
                </li>
                <li>
                  <a href="/register-as-pandit">
                    <i className="lnr lnr-chevron-right" /> Pandit Registration
                  </a>
                </li>
               
              </ul>
            </div>
          </div>
        </Col>
        <Col lg="3">
          <div className="footer_wrap">
            <div className="footer_head">
              <h2 style={{ visibility:'hidden' }}>Our Products</h2>
            </div>
            <div className="footer_links">
              <ul>
               <li>
                  <a href="/about">
                    <i className="lnr lnr-chevron-right" /> About Us
                  </a>
                </li>
                 <li>
                  <a href="/ContactUS">
                    <i className="lnr lnr-chevron-right" /> Contact us
                  </a>
                </li>
                <li>
                  <a href="/PrivacyPolicy">
                    <i className="lnr lnr-chevron-right" /> Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/termsandconditions">
                    <i className="lnr lnr-chevron-right" /> Terms and Conditions
                  </a>
                </li>
                <li>
                  <a href="/Refund">
                    <i className="lnr lnr-chevron-right" /> Refund/Cancellation Policy
                  </a>
                </li>
                 <li>
                  <a href="/ShippingPolicy">
                    <i className="lnr lnr-chevron-right" /> Shipping Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col lg="3">
          <div className="footer_wrap">
            <div className="footer_head">
              <h2>Contact</h2>
            </div>
            <div className="footer_contact">
              <ul>
                <li>
                  <div className="icons">
                    <i className="fas fa-phone-alt" />
                  </div>
                  <div className="box_content">
                    <h3>Phone Number</h3>
                    <p>
                      <a href="tel:+919610800108">+91-9610800108</a>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icons">
                    <i className="fas fa-envelope-open" />
                  </div>
                  <div className="box_content">
                    <h3>Email Address</h3>
                    <p>
                      <a href="mailto:info@pujyapanditg.com">
                      info@pujyapanditg.com
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icons">
                    <i className="fas fa-map-marker-alt" style={{width: "80px", paddingLeft: "19px",fontSize: "18px"}} />
                  </div>
                  <div className="box_content">
                    <h3>Address</h3>
                    <p>
                    <a href="https://www.google.co.in/maps/@26.9077393,75.7757399,13.13z"> Jaipur,Rajasthan,India </a>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="icons">
                    <a className="ri-instagram-fill" style={{fontSize: "20px", color: "white"}} href="https://instagram.com/pujya_pandit_g?igshid=YmMyMTA2M2Y="></a>
                  </div>
                  <div className="icons">
                  <a className="ri-twitter-fill" style={{fontSize: "20px", color: "white"}} href="https://twitter.com/pujyapandit?s=08"></a>
                  </div>
                  <div className="icons">
                  <a className="ri-facebook-circle-fill" style={{fontSize: "20px", color: "white"}} href="https://www.facebook.com/PujyapanditG"></a>
              
                  </div>
                  <div className="icons">
                  <a className="ri-youtube-fill" style={{fontSize: "20px", color: "white"}} href="https://youtube.com/channel/UCtiWv_IS_PTm68htmn8ge5g"></a>
             
                  </div>
                 
                </li>
                
              </ul>
            </div>
          </div>
        </Col>
        <Col lg="12">
          <div className="copyright">
          <p>Copyright ©️ 2022 Ppg Astrology Services Pvt. Ltd. All Right Reserved.</p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);}
export default Footer;
