import React, { useEffect } from 'react'
import {
  Document, Page, Text, View, StyleSheet, Image, Svg, Polygon, Rect,
  Line,
  Polyline,
  Path,
  Circle,
  Ellipse,
  Tspan,
  G,
  Stop,
  Defs,
  Font,
  ClipPath,
  LinearGradient,
  RadialGradient
} from '@react-pdf/renderer';
import Logo from "../../assets/img/logo.png";
import headerBG from "../../assets/img/HeadImg.jpeg";
import moment from 'moment';
import SVG from 'react-inlinesvg';
import { parse as svgparse } from "svg-parser";
import hindi from './NotoSans-Regular.ttf';

function GetKundali({ kundali }) {
  // Create styles
  Font.register(
    { family: 'Noto Sans Hindi', src: hindi }
  );

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    card: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      wordWrap: "break-word",
      backgroundColor: "#fff",
      backgroundClip: "border-box",
      border: "1px solid rgba(0,0,0,.125)",
      borderRadius: "4px",
      width: '595px'

    },
    card_body: { flex: "1 1 auto", minHeight: "1px", padding: "10px" },
    row: { position: "relative", border: "1px solid #dee2e6" },
    colDiv: {
      margin: "5px",
      width: "561px",
      display: "flex",
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderBottomColor: "#dee2e6",
      borderBottomWidth: 1,
      alignItems: 'center',
      border: "0.5px solid #dee2e6"
    },
    card_header: {
      height: "170px",
      position: "relative",
      width: '569px'
    },
    headerBG: {
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: "1",
      width: "571px",
      height: "170px",
    },
    header_img: {
      position: "absolute",
      top: "13px",
      left: "13px",
      width: "130px",
      zIndex: "9",
    },
    headerHeading: {
      fontSize: "20px",
      fontStyle: "italic",
      color: "white",
      position: "absolute",
      left: "50%",
      top: "70%",
      transform: "translate(-50%, -50%)"
    },
    caption: {
      fontWeight: "bold",
      width: "561px",
      color: "#8b0000",
      fontSize: "7px",
      textTransform: "uppercase",
      backgroundColor: "#f0e68c",
      paddingLeft: "8px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontFamily: 'Noto Sans Hindi'
    },
    tr: {
      borderBottom: "0.5px solid #dee2e6",
      width: "561px",
      color: "black",
      fontSize: "7px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    cell: {
      borderRight: "0.5px solid #dee2e6",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    label: {
      padding: "4px",
      color: "#6c757d",
      textTransform: "capitalize",
      fontFamily: 'Noto Sans Hindi'
    },
    field: {
      padding: "4px",
      fontFamily: 'Noto Sans Hindi'
    },
    ch: {
      padding: "4px",
      textTransform: "capitalize",
      fontWeight: "bold",
      fontFamily: 'Noto Sans Hindi'
    },
    pageNumber: {
      color: "grey",
      fontSize: "12px",
      position: "absolute",
      bottom: "30",
      left: "50%",
      right: "50%",
      textAlign: "center",
    },
    svgDim1: {
      transformOrigin: "left top",
      transform: "scale(0.7)",
      fontFamily: 'Noto Sans Hindi'
    },
    svgDim2: {
      transformOrigin: "right top",
      transform: "scale(0.7)",
      fontFamily: 'Noto Sans Hindi'
    }
  });


  const showDegree = (degree) => {
    let castDegree = degree.toString();
    let [degree1, degree2] = castDegree.split(".");

    return degree1 + '°' + degree2.slice(0, 4)
  }


  useEffect(() => {
    console.log('get kundali porp s si ', kundali)
  }, [])

  const convertStylesStringToObject = (stringStyles) => {
    let styles =
      typeof stringStyles === "string" && stringStyles !== undefined
        ? stringStyles
          .replaceAll("&quot;", "'")
          .split(";")
          .reduce((acc, style) => {
            const colonPosition = style.indexOf(":");

            if (colonPosition === -1) {
              return acc;
            }

            const camelCaseProperty = style
              .substr(0, colonPosition)
              .trim()
              .replace(/^-ms-/, "ms-")
              .replace(/-./g, (c) => c.substr(1).toUpperCase()),
              value = style.substr(colonPosition + 1).trim();
            let isSvgStyle = [
              "color",
              "dominantBaseline",
              "fill",
              "fillOpacity",
              "fillRule",
              "opacity",
              "stroke",
              "strokeWidth",
              "strokeOpacity",
              "strokeLinecap",
              "strokeDasharray",
              "transform",
              "textAnchor",
              "visibility"
            ].includes(camelCaseProperty);
            return isSvgStyle && value
              ? { ...acc, [camelCaseProperty]: value }
              : acc;
          }, {})
        : {};
    console.log(styles);
    return styles;
  };

  const svgToJsx = (obj, index, svgDim) => {
    let name = obj.type === "element" ? obj.tagName : obj.type;
    let props = { key: index + name };

    if (obj.properties !== undefined) {
      if (obj.properties.style !== undefined) {
        props.style = convertStylesStringToObject(obj.properties.style);
      }
      props = { ...obj.properties, ...props };
    }
    let children =
      obj.children !== undefined ? (
        obj.children.map((c, i) => {
          return svgToJsx(c, index + "-" + i, svgDim);
        })
      ) : (
        <></>
      );
    if (obj.type === "text") {
      return obj.value;
    }
    if (name === "tspan") {
      let y = props.y ?? 0 + props.dy ?? 0;
      let x = props.x ?? 0 + props.dx ?? 0;
      console.log("tspan", children, y);
      return children.length > 0 ? (
        <Tspan x={x} y={y} key={props.key}>
          {children}
        </Tspan>
      ) : (
        <></>
      );
    }
    if (name === "text") {
      return (
        <Text
          x={props.x ?? 0 + props.dx ?? 0}
          y={props.y ?? 0 + props.dy ?? 0}
          key={props.key}
        >
          {children}
        </Text>
      );
    }
    if (name === "svg") {
      return <Svg {...props} style={svgDim} >{children}</Svg>;
    }
    if (name === "path") {
      return <Path {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Path>;
    }
    if (name === "line") {
      return <Line {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Line>;
    }
    if (name === "polyline") {
      return <Polyline {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Polyline>;
    }
    if (name === "polygon") {
      return <Polygon {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Polygon>;
    }
    if (name === "rect") {
      return <Rect {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Rect>;
    }
    if (name === "circle") {
      return <Circle {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Circle>;
    }
    if (name === "ellipse") {
      return <Ellipse {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Ellipse>;
    }
    if (name === "g") {
      return <G {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</G>;
    }
    if (name === "stop") {
      return <Stop {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</Stop>;
    }
    if (name === "defs") {
      return (
        <>
          {/*<Defs {...props}>
        {obj.children !== undefined
          ? obj.children.map((c, i) => {
              return <></>;// svgToJsx(c, index+"-"+i);
            })
          : undefined}
          </Defs>*/}
        </>
      );
    }
    if (name === "clipPath") {
      return <ClipPath {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</ClipPath>;
    }
    if (name === "linearGradient") {
      return <LinearGradient {...props} style={{ fontFamily: 'Noto Sans Hindi' }}>{children}</LinearGradient>;
    }
    if (name === "radialGradient") {
      return <RadialGradient {...props} style={{ fontFamily: 'Noto Sans Hindi' }} >{children}</RadialGradient>;
    }
  };

  const CreateSvg = ({ data, svgDim }) => {
    return svgToJsx(svgparse(data.replaceAll("px", "pt")).children[0], 0, svgDim);
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.card}>
          <View style={styles.card_body}>
            <View style={styles.row}>
              <View style={styles.card_header}>
                <Image src={headerBG} style={styles.headerBG} />
                <View>
                  <Image src={Logo} style={styles.header_img} />
                </View>
                  <Text style={styles.headerHeading}>Vedic Kundali</Text>
              </View>
              {/* birth details */}
              <View style={styles.colDiv}>
  <Text style={styles.caption}>MAIN DETAILS/मुख्य विवरण</Text>
  <View style={styles.tr}>
    <View style={{ ...styles.cell, borderRight: "none" }}>
      <Text style={styles.label}>name:</Text>
      <Text style={styles.field}>{kundali.contact_name}</Text>
    </View>
  </View>
  <View style={styles.tr} >
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>birth date:</Text>
      <Text style={styles.field}>{moment(kundali.Date_of_Birth).format('L')}</Text>
    </View>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>birth time:</Text>
      <Text style={styles.field}>{kundali.Time_of_Birth}</Text>
    </View>
    <View style={{ ...styles.cell, borderRight: "none" }}>
      <Text style={styles.label}>time zone:</Text>
      <Text style={styles.field}>{kundali.kundali.birthDetails.timezone}</Text>
    </View>
  </View>
  <View style={styles.tr}>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>birth place:</Text>
      <Text style={styles.field}>{kundali.BirthPlace}</Text>
    </View>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>latitude:</Text>
      <Text style={styles.field}>{showDegree(kundali.kundali.birthDetails.latitude)}</Text>
    </View>
    <View style={{ ...styles.cell, borderRight: "none" }}>
      <Text style={styles.label}>longitude:</Text>
      <Text style={styles.field}>{showDegree(kundali.kundali.birthDetails.longitude)}</Text>
    </View>
    
  </View>
  <View style={styles.tr}>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>Ayanamsha Degree:</Text>
      <Text style={styles.field}>{showDegree(kundali.kundali.birthDetails.ayanamsha)}</Text>
    </View>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>Ritu:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.ritu}</Text>
    </View>
    <View style={{ ...styles.cell, borderRight: "none" }}>
      <Text style={styles.label}>Day:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.day}</Text>
    </View>
    
  </View>
  <View style={styles.tr}>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>Paksha:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.paksha}</Text>
    </View>
    <View style={{ ...styles.cell, width: "33.33%"}}>
      <Text style={styles.label}>Tithi Name:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.tithi.details.tithi_name}</Text>
    </View>
    <View style={{ ...styles.cell, borderRight: "none" }}>
      <Text style={styles.label}>Tithi Number:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.tithi.details.tithi_number}</Text>
    </View>
  </View>
  <View style={styles.tr}>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>Special Tithi:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.tithi.details.special}</Text>
    </View>
    <View style={{ ...styles.cell, width: "33.33%" }}>
      <Text style={styles.label}>Vikram Samvat:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.vikram_samvat}</Text>
    </View>
    <View style={{ ...styles.cell, borderRight: "none" }}>
      <Text style={styles.label}>Vikram Samvat Name:</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.vkram_samvat_name}</Text>
    </View>
  </View>
  <View style={styles.tr}>
    <View style={{ ...styles.cell, width: "25%" }}>
      <Text style={styles.label}>Tithi End Time(HH:MM:SS):</Text>
      <Text
        style={styles.field}>{kundali.kundali.advancedPanchang.tithi.end_time.hour+':'+kundali.kundali.advancedPanchang.tithi.end_time.minute+':'+kundali.kundali.advancedPanchang.tithi.end_time.second}</Text>
    </View>
    <View style={{ ...styles.cell, width: "25%" }}>
      <Text style={styles.label}>Hindu Maah (Adhik Status):</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.hindu_maah.adhik_status?'Yes':'No'}</Text>
    </View>
    <View style={{ ...styles.cell, width: "25%" }}>
      <Text style={styles.label}>Hindu Maah (Purnimanta):</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.hindu_maah.purnimanta}</Text>
    </View>
    <View style={{ ...styles.cell, borderRight: "none" }}>
      <Text style={styles.label}>Hindu Maah (Amanta):</Text>
      <Text style={styles.field}>{kundali.kundali.advancedPanchang.hindu_maah.amanta}</Text>
    </View>
  </View>
</View>
              {/* astro details */}
              <View style={styles.colDiv}>
                <Text style={styles.caption}>ASTRO DETAILS/अवकहड़ा चक्र</Text>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "70%" }} >
                    <Text style={styles.label}>This section provides your basic astrological details along with panchang descriptions at the time of your birth.</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "15%" }} >
                    <Text style={styles.label}>Ascendant:</Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.ascendant}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Sign: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.sign}</Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Sign Lord: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.SignLord}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Naksahtra: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Naksahtra}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Naksahtra Lord: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.NaksahtraLord}</Text>
                  </View>
                 
                </View>
                <View style={styles.tr} >
                 <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Charan: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Charan}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Karan: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Karan}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Yog: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Yog}</Text>
                  </View>
                </View>
                 <View style={styles.tr} >
                  
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Varna: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Varna}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Vashya: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Vashya}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Yoni: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Yoni}</Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Gan: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Gan}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Nadi: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.Nadi}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Yunja: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.yunja}</Text>
                  </View>
                </View>
                 <View style={{ ...styles.tr, borderBottom: "none" }} >
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Tatva: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.tatva}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "33.33%" }} >
                    <Text style={styles.label}>Name Alphabet: </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.name_alphabet}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Paya (Nak Based): </Text>
                    <Text style={styles.field}>{kundali.kundali.astroDetails.paya}</Text>
                  </View>
                </View>
              </View>
              {/* ghat chakra */}
              <View style={styles.colDiv}>
                <Text style={styles.caption}>GHAT CHAKRA/घात चक्र</Text>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.label}>Day: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.day}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.label}>Karan: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.karan}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.label}>Month: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.month}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>moon: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.moon}</Text>
                  </View>
                </View>
                <View style={{ ...styles.tr, borderBottom: "none" }} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.label}>Nakshatra: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.nakshatra}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.label}>Pahar: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.pahar}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.label}>Tithi: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.tithi}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Yog: </Text>
                    <Text style={styles.field}>{kundali.kundali.ghatChakra.yog}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.colDiv} >
                <Text style={styles.caption} >KUNDALI CHART/कुंडली</Text>
                <View style={{ ...styles.tr, position: "relative", borderBottom: "none" }} >
                  <View style={{ ...styles.cell, height: "240px", width: "100%", borderRight: "none" }} >
                    <Text style={{ ...styles.label, width: "100%" }}>लग्न कुण्डली: </Text>
                    <CreateSvg data={kundali.kundali.horoChartImageD1.svg.replace('\'', "")} svgDim={styles.svgDim1} />
                  </View>
                  <View style={{ ...styles.cell, height: "240px", width: "100%", borderRight: "none", position: "absolute", top: "0", right: "0", display: "flex", justifyContent: "flex-end" }} >
                    <Text style={{ ...styles.label, width: "100%", textAlign: "right" }}>नवमांश कुण्डली: </Text>
                    <CreateSvg data={kundali.kundali.horoChartImageD9.svg.replace('\'', "")} svgDim={styles.svgDim2} />
                  </View>
                </View>
              </View>
              <View style={styles.colDiv} wrap={false} >
                <Text style={styles.caption}>PLANETS REPORT 1/ग्रह विवेचन 1</Text>
                {kundali.kundali.horoChartD1.map((chart, index, array) => {
                  return <View style={{ ...styles.tr, borderBottom: array.length == index + 1 ? "none" : "0.5px solid #dee2e6" }} >
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Sign Name: </Text>
                        {chart.sign_name}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "10%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Sign: </Text>
                        {chart.sign}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "30%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Planet: </Text>
                        {chart.planet.join()}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Planet Small: </Text>
                        {chart.planet_small.join()}</Text>
                    </View>
                    <View style={{ ...styles.cell, borderRight: "none" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Planet Degree: </Text>
                        {chart.planet_degree.join()}
                      </Text>
                    </View>
                  </View>
                })}
              </View>
              <View style={styles.colDiv}>
                <Text style={styles.caption}>PLANET REPORT 2/ग्रह विवेचन 2</Text>
                {kundali.kundali.horoChartD9.map((chart, index, array) => {
                  return <View style={{ ...styles.tr, borderBottom: array.length == index + 1 ? "none" : "0.5px solid #dee2e6" }} >
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Sign Name: </Text>
                        {chart.sign_name}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "10%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Sign: </Text>
                        {chart.sign}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "30%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Planet: </Text>
                        {chart.planet.join()}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Planet Small: </Text>
                        {chart.planet_small.join()}</Text>
                    </View>
                    <View style={{ ...styles.cell, borderRight: "none" }} >
                      <Text style={styles.field}>
                        <Text style={styles.label}>Planet Degree: </Text>
                        {chart.planet_degree.join()}
                      </Text>
                    </View>
                  </View>
                })}
              </View>
              {/* current vdaasha */}
              <View style={styles.colDiv}>
                <Text style={styles.caption}>CURRENT VDASHA/वर्तमान विंशोतरी दशा</Text>
                {Object.keys(kundali.kundali.currentVdasha).map((key, index) => {
                  return <View style={styles.tr} key={key} >
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.ch}>{key}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.label}>planet: </Text>
                      <Text style={styles.field}>{kundali.kundali.currentVdasha[key].planet}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.label}>planet id: </Text>
                      <Text style={styles.field}>{kundali.kundali.currentVdasha[key].planet_id}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "20%" }} >
                      <Text style={styles.label}>start: </Text>
                      <Text style={styles.field}>{kundali.kundali.currentVdasha[key].start}</Text>
                    </View>
                    <View style={{ ...styles.cell, borderRight: "none" }} >
                      <Text style={styles.label}>end: </Text>
                      <Text style={styles.field}>{kundali.kundali.currentVdasha[key].end}</Text>
                    </View>
                  </View>
                })}
              </View>
               <View style={styles.colDiv}>
                <Text style={styles.caption}>MAJOR VDASHA/ विंशोतरी महादशा</Text>
                {kundali.kundali.majorVdasha.map((Vdasha, key) => {
                  return <View style={styles.tr} key={key} >
                    <View style={{ ...styles.cell, width: "33.33%" }} >
                      <Text style={styles.label}>Planet: </Text>
                      <Text style={styles.field}>{Vdasha.planet}</Text>
                    </View>
                    <View style={{ ...styles.cell, width: "33.33%" }} >
                      <Text style={styles.label}>Start: </Text>
                      <Text style={styles.field}>{Vdasha.start}</Text>
                    </View>
                    <View style={{ ...styles.cell, borderRight: "none" }} >
                      <Text style={styles.label}>End: </Text>
                      <Text style={styles.field}>{Vdasha.end}</Text>
                    </View>
                  </View>
                })}
              </View>
              {kundali.kundali.planets != null && <View style={styles.colDiv} wrap={false}>
                <Text style={styles.caption}>PLANETARY POSITION/ग्रह स्थति</Text>
                {kundali.kundali.planets.map((planet)=>(
                  <>
                <View style={{ ...styles.tr, marginLeft: "5px",marginRight: "5px" }} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.ch}>{planet.name} </Text>
                  </View>
                </View>
                <View style={{ ...styles.tr, marginLeft: "5px", marginRight: "5px" }} >
                  <View style={{ ...styles.cell, width: "16.66%",borderLeft: "0.5px solid #dee2e6" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Nakshatra: </Text>
                      {planet.nakshatra}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Nakshatra Lord: </Text>
                      {planet.nakshatraLord}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}> 
                      <Text style={styles.label}>Nakshatra Pad: </Text>
                      {planet.nakshatra_pad}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>House: </Text>
                      {planet.house}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>IsRetro: </Text>
                      {planet.isRetro=='true'?'Yes':'No'}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.70%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>is Planet Set: </Text>
                      {planet.is_planet_set?'Yes':'No'}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tr, marginLeft: "5px",marginRight: "5px", marginBottom: "5px" }} >
                  <View style={{ ...styles.cell, width: "16.66%",borderLeft: "0.5px solid #dee2e6" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>NormDegree: </Text>
                      {planet.normDegree.toFixed(4)}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>FullDegree: </Text>
                      {planet.fullDegree.toFixed(4)}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}> 
                      <Text style={styles.label}>Planet Awastha: </Text>
                      {planet.planet_awastha}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Sign: </Text>
                      {planet.sign}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.66%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>SignLord: </Text>
                      {planet.signLord}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "16.70%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Speed: </Text>
                      {planet.speed.toFixed(4)}
                    </Text>
                  </View>
                </View>
                </>
                ))}
              </View>}
              {kundali.kundali.manglik != null && <View style={styles.colDiv}>
                <Text style={styles.caption}>MANGLIK DETAILS/मंगल दोष</Text>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "12%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Is Present: </Text>
                      {kundali.kundali.manglik.is_present ? 'Yes' : 'No'}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "18%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Manglik Status: </Text>
                      {kundali.kundali.manglik.manglik_status}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "20%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Is Mars Manglik Cancelled: </Text>
                      {kundali.kundali.manglik.is_mars_manglik_cancelled ? 'Yes' : 'No'}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "28%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Percentage Manglik After Cancellation: </Text>
                      {kundali.kundali.manglik.percentage_manglik_after_cancellation}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Percentage Manglik Present: </Text>
                      {kundali.kundali.manglik.percentage_manglik_present}</Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Manglik Report: </Text>
                      {kundali.kundali.manglik.manglik_report.replaceAll("<p>", "").replaceAll("</p>", "")}</Text>
                  </View>
                </View>
                {(kundali.kundali.manglik.manglik_present_rule.based_on_aspect.length || kundali.kundali.manglik.manglik_present_rule.based_on_house) && <View style={styles.tr} >
                  <View style={{ ...styles.cell, borderRight: "none", width: "100%" }} >
                    <Text style={styles.label}>Manglik Present Rule: </Text>
                    <View style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      {kundali.kundali.manglik.manglik_present_rule.based_on_aspect.length && <View style={{ display: "inline-block", width: "50%" }} >
                        <Text style={styles.ch}>Based On Aspect</Text>
                        {kundali.kundali.manglik.manglik_present_rule.based_on_aspect.map((item) => (
                          <Text style={{ ...styles.field, marginLeft: "16px", display: "block", padding: "0px" }}> <Text style={{ fontSize: "12px" }}>•</Text> {item}</Text>
                        ))}
                      </View>}
                      {kundali.kundali.manglik.manglik_present_rule.based_on_house.length && <View style={{ display: "inline-block", width: "50%" }} >
                        <Text style={styles.ch}>Based On House</Text>
                        {kundali.kundali.manglik.manglik_present_rule.based_on_house.map((item) => (
                          <Text style={{ ...styles.field, marginLeft: "16px", display: "block", padding: "0px" }}> <Text style={{ fontSize: "12px" }}>•</Text> {item}</Text>
                        ))}
                      </View>}
                    </View>
                  </View>
                </View>}
                <View style={{ ...styles.tr, borderBottom: "none" }} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Manglik Cancel Rule: </Text>
                      {kundali.kundali.manglik.manglik_cancel_rule.map((item) => (
                        <Text style={{ ...styles.field, marginLeft: "16px", display: "block", padding: "0px" }}> <Text style={{ fontSize: "12px" }}>•</Text> {item}</Text>
                      ))}
                    </Text>
                  </View>
                </View>
              </View>}
              
              {kundali.kundali.kalsarpaDetails != null && <View style={styles.colDiv}>
                <Text style={styles.caption}>KALSARPA DETAILS/कालसर्प दोष</Text>
                {kundali.kundali.kalsarpaDetails.present == false ?
                  <View style={{ ...styles.tr, borderBottom: "none" }} >
                    <View style={{ ...styles.cell, borderRight: "none" }} >
                      <Text style={styles.field}>{kundali.kundali.kalsarpaDetails.one_line}</Text>
                    </View>
                  </View>
                  :
                  <>
                    <View style={styles.tr} >
                      <View style={{ ...styles.cell, width: "33.33%" }} >
                        <Text style={styles.field}>
                          <Text style={styles.label}>Name: </Text>
                          {kundali.kundali.kalsarpaDetails.name}</Text>
                      </View>
                      <View style={{ ...styles.cell, width: "33.33%" }} >
                        <Text style={styles.field}>
                          <Text style={styles.label}>Type: </Text>
                          {kundali.kundali.kalsarpaDetails.type}</Text>
                      </View>
                      <View style={{ ...styles.cell, width: "33.33%", borderRight: "none" }} >
                        <Text style={styles.field}>
                          <Text style={styles.label}>House Id: </Text>
                          {kundali.kundali.kalsarpaDetails.report.house_id}</Text>
                      </View>
                    </View>
                    <View style={styles.tr} >
                      <View style={{ ...styles.cell, borderRight: "none" }} >
                        <Text style={styles.field}>
                          <Text style={styles.label}>Short Desc: </Text>
                          {kundali.kundali.kalsarpaDetails.one_line.replaceAll("<p>", "").replaceAll("</p>", "")}</Text>
                      </View>
                    </View>
                    <View style={{ ...styles.tr, borderBottom: "none" }} >
                      <View style={{ ...styles.cell, borderRight: "none" }} >
                        <Text style={styles.field}>
                          <Text style={styles.label}>Report: </Text>
                          {kundali.kundali.kalsarpaDetails.report.report.replaceAll("<p>", "").replaceAll("</p>", "")}</Text>
                      </View>
                    </View>
                  </>
                }
              </View>}
              {kundali.kundali.pitraDoshaReport != null && <View style={styles.colDiv}>
                <Text style={styles.caption}>PITRA DOSHA REPORT/पितृ दोष</Text>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>What is Pitra Dosha: </Text>
                      {kundali.kundali.pitraDoshaReport.what_is_pitri_dosha.replaceAll("<p>", "").replaceAll("</p>", "")}
                    </Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Conclusion: </Text>
                      {kundali.kundali.pitraDoshaReport.conclusion.replaceAll("<p>", "").replaceAll("</p>", "")}
                    </Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, borderRight: "none", width: "100%" }} >
                    <View style={{ width: "100%",padding: "4px", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      <View style={{ display: "inline-block", width: "50%" }} >
                        <Text style={{ ...styles.label,paddingLeft: "4px" }}>Rules Matched:</Text>
                        {kundali.kundali.pitraDoshaReport.rules_matched.map((item) => (
                          <Text style={{ ...styles.field, marginLeft: "16px", display: "block", padding: "0px" }}> <Text style={{ fontSize: "12px" }}>•</Text> {item}</Text>
                        ))}
                      </View>
                      <View style={{ display: "inline-block", width: "50%" }} >
                        <Text style={styles.field}>
                          <Text style={styles.label}>Is Pitra Dosha Present:</Text>
                          {kundali.kundali.pitraDoshaReport.is_pitri_dosha_present?'Yes':'No'}
                          </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ ...styles.tr, borderBottom: "none" }} >
                  <View style={{ ...styles.cell, borderRight: "none", width: "100%" }} >
                    <View style={{ width: "100%",padding: "4px", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      <View style={{ display: "inline-block", width: "50%" }} >
                        <Text style={{ ...styles.label,paddingLeft: "4px" }}>Remedies:</Text>
                        {kundali.kundali.pitraDoshaReport.remedies!=null&&kundali.kundali.pitraDoshaReport.remedies.map((item) => (
                          <Text style={{ ...styles.field, marginLeft: "16px", display: "block", padding: "0px" }}> <Text style={{ fontSize: "12px" }}>•</Text> {item}</Text>
                        ))}
                      </View>
                      <View style={{ display: "inline-block", width: "50%" }} >
                        <Text style={styles.label}>Effects:</Text>
                        {kundali.kundali.pitraDoshaReport.Effects!=null&&kundali.kundali.pitraDoshaReport.Effects.map((item) => (
                          <Text style={{ ...styles.field, marginLeft: "16px", display: "block", padding: "0px" }}> <Text style={{ fontSize: "12px" }}>•</Text> {item}</Text>
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
              </View>}
              {kundali.kundali.sadhesatiCurrentStatus != null && <View style={styles.colDiv}>
                <Text style={styles.caption}>SADHESATI CURRENT STATUS/शनि साढ़ेसाती</Text>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>What is Sadhesati: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.what_is_sadhesati.replaceAll("<p>", "").replaceAll("</p>", "")}
                    </Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Sadhesati Status: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.sadhesati_status?'Present':'Not Present'}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Saturn Sign: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.saturn_sign}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Moon Sign: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.moon_sign}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Consideration Date: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.consideration_date}
                    </Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Is Saturn Retrograde: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.is_saturn_retrograde?'Yes':'No'}</Text>
                  </View>
                  <View style={{ ...styles.cell,width: "75%", borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Is Undergoing Sadhesati: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.is_undergoing_sadhesati}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tr, borderBottom: "none" }} >
                  <View style={{ ...styles.cell, width: "60%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Sadhesati Phase: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.sadhesati_phase}</Text>
                  </View>
                  <View style={{ ...styles.cell,width: "20%", borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Start Date: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.start_date}
                    </Text>
                  </View>
                  <View style={{ ...styles.cell,width: "20%", borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>End Date: </Text>
                      {kundali.kundali.sadhesatiCurrentStatus.end_date}
                    </Text>
                  </View>
                </View>
              </View>}
              
              {kundali.kundali.numeroTable != null && <View style={styles.colDiv}>
                <Text style={styles.caption}>NUMERO TABLE/अंक ज्योतिष</Text>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Name: </Text>
                      {kundali.kundali.numeroTable.name}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Name Number: </Text>
                      {kundali.kundali.numeroTable.name_number}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Date: </Text>
                      {kundali.kundali.numeroTable.date}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Destiny Number: </Text>
                      {kundali.kundali.numeroTable.destiny_number}
                    </Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Evil Number: </Text>
                      {kundali.kundali.numeroTable.evil_num}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Fav Color: </Text>
                      {kundali.kundali.numeroTable.fav_color}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Fav Day: </Text>
                      {kundali.kundali.numeroTable.fav_day}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Fav God: </Text>
                      {kundali.kundali.numeroTable.fav_god}
                    </Text>
                  </View>
                </View>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Fav Mantra: </Text>
                      {kundali.kundali.numeroTable.fav_mantra}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Fav Metal: </Text>
                      {kundali.kundali.numeroTable.fav_metal}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Fav Stone: </Text>
                      {kundali.kundali.numeroTable.fav_stone}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Fav Sub Stone: </Text>
                      {kundali.kundali.numeroTable.fav_substone}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tr, borderBottom: "none" }} >
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Friendly Num: </Text>
                      {kundali.kundali.numeroTable.friendly_num}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Neutral Num: </Text>
                      {kundali.kundali.numeroTable.neutral_num}</Text>
                  </View>
                  <View style={{ ...styles.cell, width: "25%" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Radical Num: </Text>
                      {kundali.kundali.numeroTable.radical_num}</Text>
                  </View>
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>Radical Ruler: </Text>
                      {kundali.kundali.numeroTable.radical_ruler}
                    </Text>
                  </View>
                </View>
              </View>}
              {/* general ascendant report */}
              <View style={styles.colDiv}>
                <Text style={styles.caption}>GENERAL ASCENDANT REPORT/लग्न फलादेश</Text>
                <View style={styles.tr} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.label}>Ascendant: </Text>
                    <Text style={styles.field}>{kundali.kundali.generalAscendantReport.asc_report.ascendant}</Text>
                  </View>
                </View>
                <View style={{ ...styles.tr, borderBottom: "none" }} >
                  <View style={{ ...styles.cell, borderRight: "none" }} >
                    <Text style={styles.field}>
                      <Text style={styles.label}>report: </Text>
                      {kundali.kundali.generalAscendantReport.asc_report.report.replaceAll("<p>", "").replaceAll("</p>", "")}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.colDiv}>
                <Text style={styles.caption}>GENERAL HOUSE REPORT/ ग्रह फलादेश</Text>
                {Object.keys(kundali.kundali.generalHouseReport).map((key, index, array) => {
                  return <>
                    <View style={styles.tr} >
                      <View style={{ ...styles.cell, borderRight: "none" }} >
                        <Text style={styles.label}>planet: </Text>
                        <Text style={styles.field}>{kundali.kundali.generalHouseReport[key].planet}</Text>
                      </View>
                    </View>
                    <View style={{ ...styles.tr, borderBottom: array.length == index + 1 ? "none" : "0.5px solid #dee2e6" }} >
                      <View style={{ ...styles.cell, borderRight: "none" }} >
                        <Text style={styles.field}>
                          <Text style={styles.label}>house report: </Text>
                          {kundali.kundali.generalHouseReport[key].house_report.replaceAll("<p>", "").replaceAll("</p>", "")}
                        </Text>
                      </View>
                    </View>
                  </>
                })}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default GetKundali