import React, { useEffect, useState } from "react";
// history
import { useHistory } from "react-router-dom";
import { Avatar } from "antd";
import { NavLink as NavLinkRRD } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from "reactstrap";
import "./Searchbar.css";
// APi
import Dataservices from "../../services/requestApi";
// Logo
import Logo from "../../assets/img/logo.png";
import Highlighter from "react-highlight-words";
import { BASEURL } from "../../services/http-common";

const items = [
  {
    id: 1,
    name:'Pooja',
    link:'/all-pooja'
  },
  {
    id: 2,
    name:'Product',
    link:'/all-product'
  },
  {
    id: 3,
    name:'Article',
    link:'/all-article'
  },
  {
    id: 4,
    name:'cloths',
    link:'/all-product'
  },
  {
    id: 5,
    name:"seasonal poojs",
    link:'/all-pooja'
  },
  {
    id: 6,
    name:"Ganesh pooja",
    link:'/all-pooja'
  },
  {
    id: 7,
    name:'kurta',
    link:'/all-product'
  },
  {
    id: 8,
    name:'Rudhraksh',
    link:'/all-product'
  },
  {
    id: 9,
    name:'dhoti',
    link:'/all-product'
  },
  {
    id: 10,
    name:'maala',
    link:'/all-product'
  }
]
const Header = ({ userdata }) => {
  const [Open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [wishCount, setWishCount] = useState(0);
  const [MatchSearch, setMatchSearch] = useState([]);
  
  const user = userdata && userdata;
  const token = sessionStorage.getItem("Authtoken");
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  // console.log(user)
  useEffect(() => {
    const getData = async () => {
      try {
        if (token) {
          const [wishRes, Cartres] = await Promise.all([
            Dataservices.GetWishlistAll(),
            Dataservices.GetCartAll(),
          ]);
          if (mounted) {
            console.log(wishRes.data);
            setWishCount(wishRes.data.data.length);
            setCartCount(Cartres.data.data.length);  
            // setProduct(productRes.data.data);
            // setPooja(poojaRes.data.data);
            // setArticle(articlesRes.data.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      getData();
      setMounted(false);
    };
  }, [mounted, token]);
  
//   const search = () => {
//     setOpen(!Open);
//     // id style
//     const searchbar = document.getElementById("searchii");
//     if (searchbar.style.display === "none") {
//       searchbar.style.display = "block";
//     }
//     else  {
//       // filter search with value in product, pooja, articles
//       if(searchValue.length > 0){
//         history.push(`/search/${searchValue}`);
//       }
//       searchbar.style.display = "none";
//     }
//   };

const search = () => {
    setOpen(!Open);
    // id style
    const searchbarMobile = document.getElementById("searchii1");
    const searchbar = document.getElementById("searchii");
    // check port
    const width = window.innerWidth;
    if(width<=768){
      if(searchbarMobile.style.display==="none"){
        searchbarMobile.style.display="block";
        searchbar.style.display="none";
      }
      else{
        if(searchValue.length > 0){
          history.push(`/search/${searchValue}`);
        }
        searchbarMobile.style.display="none";
        searchbar.style.display = "none";
      }
    }
    else {
      if (searchbar.style.display === "none") {
        searchbar.style.display = "block";
        searchbarMobile.style.display = "none";
      }
      else  {
        // filter search with value in product, pooja, articles
        if(searchValue.length > 0){
          history.push(`/search/${searchValue}`);
        }
        searchbar.style.display = "none";
        searchbarMobile.style.display = "none";
      }
    }
  };
  
  
  
  // setMatchSearch(items.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
  
  const matchSearch =async (value) => {
    setSearchValue(value);
    if(value!='')
    {
      const result = await Dataservices.globalSearch(value);
      console.log('check result is ',result);
      setMatchSearch(result.data);
    }
  }
  return (
    <div className="Header">
      {/* {console.log(product,pooja,articles)} */}
      {/* <div className="top_bar">
        <>
          <Row>
            <Col lg="6"></Col>
            <Col lg="6">
              <ul className="top_menu">
                <li>
                  <a href="tel:+918385842313">
                    <i className="lnr lnr-phone-handset" />
                    +91-8385842313
                  </a>
                </li>
                <li>
                  <a href="mailto:info@pujyapanditg.com">
                    <i className="lnr lnr-envelope" /> info@pujyapanditg.com
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </>
      </div> */}
      <Navbar color="light" expand="xl" light>
        <NavbarBrand to="/" tag={NavLinkRRD}>
          <img
            src={Logo}
            style={{
              width: "150px",
              position: "absolute",
              top: "5px",
              zIndex: "9"
            }}
            alt="Logo"
          />
        </NavbarBrand>
        <div className="d-flex" style={{ zIndex:'9' }}>
        <div style={{ display: "flex", marginLeft: "8rem",position:'relative' }}>
        <input type="text"   style={{
            display: 'none',
            height: '40px',
            width: '7.1rem',
            padding: '16px',
            marginBottom: '0px'}} 
            id="searchii1" 
                onChange={(e)=>{
                  setSearchValue(e.target.value)
                  matchSearch(e.target.value)
                  console.log('wow confirm is',e.target.value);
                }
                }
              />
               <div className="container shadow d-sm-block d-md-none " style={searchValue.length > 0 ? {display:'block',position:'absolute',width: '13rem',top:'2.1rem'} : {display:'none'}}>
                <div className="row">
                    <div className="input-group">
                        <div className="search-result" style={{
                          display: "absolute",
                          zIndex: "10000",
                          top: "0",
                          left: "0",
                          width: "100%",
                          maxHeight: "60vh",
                          backgroundColor: "white",
                          overflow:"auto"
                        }}>
                          {MatchSearch.map((item, index) => {
                            return (
                              <div className="container p-2" key={index}>
                                <img src={BASEURL.ENDPOINT_URL+item.img} width="45" alt={item.title} />
                                <button onClick={()=>{
                                  history.push(item.link);
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  outline: "none",
                                  cursor: "pointer",
                                  padding: "0",
                                  margin: "0",
                                  fontSize: "12px",
                                  color: "black",
                                  fontWeight: "bold",
                                  textDecoration: "none",
                                }
                                }><Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={[searchValue]}
                                highlightStyle={{ fontWeight:700,background:'white',color:'#e9963e',textDecoration:'underline' }}
                                autoEscape={true}
                                textToHighlight={item.title}
                              /></button>
                              </div>
                            );
                          }
                          )}
                        </div>
                    </div>
                </div>
              </div>
        <button onClick={()=> search()} style={{
                border: 'none',
                background: 'none'
        }}
        className="d-lg-none d-xl-none d-md-block d-sm-block d-xs-block">
        <i className="fas fa-search" style={{
          fontSize: '1.65rem',
        }}></i> </button>
        </div>
        <NavbarToggler onClick={() => setIsOpen(!isOpen)} /> </div>
        <Collapse navbar isOpen={isOpen}>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink to="/" exact tag={NavLinkRRD} activeClassName="active">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/about"
                exact
                tag={NavLinkRRD}
                activeClassName="active"
              >
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/all-product"
                tag={NavLinkRRD}
                activeClassName="active"
              >
                Our Products
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/all-pooja"
                tag={NavLinkRRD}
                activeClassName="active"
              >
                Pooja
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/all-article"
                tag={NavLinkRRD}
                activeClassName="active"
              >
                Article
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                to="/STDH"
                tag={NavLinkRRD}
                activeClassName="active"
              >
                Sanatan Dharma
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                to="/EpoojaBook"
                tag={NavLinkRRD}
                activeClassName="active"
              >
                E-pooja
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                to="/astrology"
                tag={NavLinkRRD}
                activeClassName="active"
              >
                Astrology
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/register-as-pandit"
                exact
                tag={NavLinkRRD}
                activeClassName="active"
              >
                Pandit Registration
              </NavLink>
            </NavItem>
            <NavItem style={{ display: "none" }} id="searchii">
              <input type="text" placeholder="Search" style={{height:'12px',marginBottom:'3px'}}
                onChange={(e)=>{
                  setSearchValue(e.target.value)
                  matchSearch(e.target.value)
                }
                }
                // recommendation for search
                onKeyPress={(e)=>{
                  if(e.key === 'Enter'){
                    history.push(`/search/${searchValue}`);
                  }
                }
                }
              />
              <div className="container" style={searchValue.length > 0 ? {display:'block',position:'absolute',width: '25rem'} : {display:'none'}}>
                <div className="row">
                  <div className="col-md-12 col-sm-12 recent-search">
                    <div className="input-group">
                        <div className="search-result" style={{
                          display: "absolute",
                          zIndex: "10000",
                          top: "0",
                          left: "0",
                          width: "100%",
                          maxHeight: "75vh",
                          backgroundColor: "white",
                          overflow:"auto"
                        }}>
                          {MatchSearch.map((item, index) => {
                            return (
                              <div className="container justify-content-start" key={index}>
                                <img src={BASEURL.ENDPOINT_URL+item.img} width="45" alt={item.title} />
                                <h6 className="ml-2" onClick={()=>{
                                  history.push(item.link);
                                }}
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  outline: "none",
                                  cursor: "pointer",
                                  padding: "0",
                                  margin: "0",
                                  // fontSize: "12px",
                                  color: "black",
                                  fontWeight: "bold",
                                  textDecoration: "none",
                                }
                                }><Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={[searchValue]}
                                highlightStyle={{ fontWeight:700,background:'white',color:'#e9963e',textDecoration:'underline' }}
                                autoEscape={true}
                                textToHighlight={item.title}
                              /></h6>
                              </div>
                            );
                          }
                          )}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </NavItem>  
            <NavItem>
              <button onClick={()=> search()} style={{
                border: 'none',
                background: 'none'
              }}> <i className="fas fa-search"></i> </button>
            </NavItem>
            
            {!user ? (
              <>
                <NavItem>
                  <NavLink
                    to="/login"
                    tag={NavLinkRRD}
                    className="btn btn-amber btn-rounded px-4 text-white mb-2 mx-md-2 mb-md-0"
                  >
                    &nbsp;&nbsp;Login&nbsp;
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/register"
                    tag={NavLinkRRD}
                    className="btn btn-outline-amber btn-rounded px-4"
                  >
                    Register
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem className="wishlist">
              <NavLink to="/wishlist" tag={NavLinkRRD} activeClassName="active">
                <span className="lnr lnr-heart"></span>
                <span className="count">{wishCount}</span>wishlist
              </NavLink>
            </NavItem>
            <NavItem className="cart">
              <NavLink to="/cart" tag={NavLinkRRD} activeClassName="active">
                <span className="lnr lnr-cart"></span>
                <span className="count">{cartCount}</span>cart
              </NavLink>
            </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    <Avatar
                      src={require("../../assets/img/abt.png")}
                      size={30}
                    />{" "}
                    {user.name}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      to="/profile"
                      className="m-0"
                      tag={NavLinkRRD}
                    >
                      Profile
                    </DropdownItem>
                    <DropdownItem to="/orders" className="m-0" tag={NavLinkRRD}>
                      Orders
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      href="/"
                      className="m-0"
                      onClick={() => sessionStorage.removeItem("Authtoken")}
                    >
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )}
         
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};
export default Header;