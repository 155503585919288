import React, { useState } from "react";
import Headimage from "../../components/HeadImage/Headimage";
import WrapContainer from "../../components/container";
import HeaderBreadcrumb from "../../components/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import DataService from "../../services/requestApi";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { Radio } from "antd";
import "./Kundali.css"
import { ToastContainer, toast } from 'react-toastify';
import { Spinner } from "reactstrap";
import Autosuggest from 'react-autosuggest';
import { City } from 'country-state-city';
import theme from './theme.module.css'



const Kundali = () => {

  const location = useLocation()
  const history = useHistory();

  const title = location.state;


  const title1 = title.title;
  const price = title.price;
  //   const isCouponApplied = title.isCouponApplied;
  const isCouponGiven = title.giveCoupon;
  const [isSubmit, setIsSubmit] = useState(false);
  const [citySuggestions, setCitySuggestions] = useState([]);


  const [coupon, setCoupon] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const validateCoupon = (KHATUSHYAMJI) => {
    (coupon === process.env.REACT_APP_COUPAN_CODE) ? setIsCouponApplied(1) : setIsCouponApplied(-1);
  }
  const cities = City.getCitiesOfCountry("IN");
  const getSuggestions = (value) => {
    console.log('check input value', value);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : cities.filter(lang =>
      lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    const suggestions = getSuggestions(value);
    setCitySuggestions(suggestions);
  };
  const getSuggestionValue = (suggestion) => {
    return suggestion.name
  };
  const renderSuggestion = suggestion => (
    <div>
      {suggestion.name + ' (' + suggestion.stateCode + ')'}
    </div>
  );
  // make a post request to the server
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);

    const data = {
      Name,
      DOB,
      TOD,
      BPlace,
      Mobile,
      Gender,
      title1,
      price,
      isCouponApplied,
      Comments,
      Address,
      Email,
      language,
    };
    try {
      console.log("data", data);
      if (data.Name !== "" && data.DOB !== "" && data.TOD !== "" && data.BPlace !== "" && data.Mobile !== "" && data.Gender !== "" && data.language !== "") {

        if (validateMobile(Mobile)) {
          const res = await DataService.Kundali(queryString.stringify(data));
          if (res.data.resCode == 200) {
            setIsSubmit(false);
            if (price === "Free" || isCouponApplied == true) {
              // console.log("free",price);
              alert("Order Placed Successfully, For Queries Please Message at WhatsApp - 9610800108");
              history.push(`/view/kundali/${res.data.Data._id}`)
            }
            else {
              console.log('check rs', res.data);
              // window.location.href = res.data.link;
              let checkoutOptions = {
                paymentSessionId: res.data.link,
                redirectTarget: "_self" //optional ( _self, _blank, or _top)
              }

              if (typeof window !== 'undefined' && window.Cashfree) {
                const cashfree = window.Cashfree({
                  mode: "production" //or production
                });
                cashfree.checkout(checkoutOptions)
              }
            }
          }
          else {
            setIsSubmit(false);
            // react toastify danger
            toast.error(res.data.msg, {
              position: 'top-center'
            });
          }

        }
      }
      else {
        setIsSubmit(false);
        alert("Please fill all the fields");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [Gender, setGender] = useState("");
  const [Name, setName] = useState("");
  const [DOB, setDOB] = useState("");
  const [TOD, setTOD] = useState("");
  const [BPlace, setBPlace] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Comments, setComments] = useState("");
  const [Address, setAddress] = useState("");
  const [Email, setEmail] = useState("");
  const [language, setLanguage] = useState("");

  // generate id on submit

  // mobile number validation
  const validateMobile = (Mobile) => {
    // alert(Mobile);
    if (Mobile.length === 10) {
      setMobile(Mobile);
      return true;
    } else {
      setIsSubmit(false);
      alert("Please enter valid mobile number");
      return false;
    }
  }
  return (
    <>
      {/* kundali form  */}
      <WrapContainer>
        <ToastContainer />
        <HeaderBreadcrumb title="kundali" />
        <Headimage title="Kundali" />
        {/* <div className="container-fluid desc ">
          <div className="card im">
            <div className="card-body">
              <p>
                Pujya Pandit G Kundali | Janam Kundali Online - जन्म कुंडली
                Janam Kundli also known as Janampatri or Birth chart or Kundli
                Chart or Natal Chart, depicts an individual’s life based on the
                12 houses. Kundali, Vedic charts, graphical representation of
                positions of houses and planets are prepared based on the date,
                time and place of birth of an individual.
              </p>
            </div>
          </div>
        </div>*/}
        <div className="container">
          {isSubmit == false ? <div className="row">
            <div className="col-md-12 mt-3">
              <div className="card">
                {/* <div className="card-header">
                        <h4 className="card-title">Kundali</h4>
                    </div> */}
                <div className="card-body">
                  <div className="card-content">
                    <form >
                      <div className="form-group">
                        <label className="control-label">Name</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Full Name"
                          onChange={(e) => setName(e.target.value)}
                        //   value={Name}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Date of Birth</label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          placeholder="Enter your Date of Birth"
                          onChange={(e) => setDOB(e.target.value)}
                        //   value={DOB}
                        />
                      </div>
                      {/* address */}
                      {/*  <div className="form-group">
                        <label className="control-label">Address</label>
                        <input
                          
                          type="text"
                          className="form-control"
                          placeholder="Enter your address"
                          onChange={(e) => setAddress(e.target.value)}
                          />
                      </div> 
                      <div className="form-group">
                        <label className="control-label">E-mail</label>
                        <input
                          
                          type="text"
                          className="form-control"
                          placeholder="Enter your address"
                          onChange={(e) => setEmail(e.target.value)}
                          />
                      </div>
                      {/* gender */}
                      <div className="form-group">
                        <label>Gender</label>
                        <div onChange={(e) => { setGender(e.target.value) }} className="m-2">
                          <input type="radio" value="Male" name="gender" /> Male
                          <input className="ml-3" type="radio" value="Female" name="gender" /> Female
                          <input className="ml-3" type="radio" value="Other" name="gender" /> Other
                        </div>

                      </div>

                      <div className="form-group">
                        <label className="control-label">Time of Birth</label>
                        <input
                          required
                          type="time"
                          className="form-control"
                          placeholder="Enter your Time of Birth"
                          onChange={(e) => setTOD(e.target.value)}
                        //   value={TOD}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Place of Birth</label>
                        {/* <input
                          required
                          type="text"
                          className="form-control"
                          placeholder="Enter your Place of Birth"
                          onChange={(e) => setBPlace(e.target.value)}
                        //   value={BPlace}
                        /> */}
                        <Autosuggest
                          suggestions={citySuggestions}
                          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={() => setCitySuggestions([])}
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={renderSuggestion}
                          theme={theme}
                          inputProps={{ placeholder: "Select preferred city", value: BPlace, onChange: (event, { newValue }) => { setBPlace(newValue) } }}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Mobile Number</label>
                        {/* validation on mobile number */}
                        <input
                          required
                          type="text"
                          className="form-control"
                          placeholder="Enter your Mobile Number"
                          onChange={(e) => setMobile(e.target.value)}
                          value={Mobile}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Choose Language</label>
                        {/* validation on mobile number */}
                        <select class="form-control" name="" id="" onChange={(e) => setLanguage(e.target.value)}>
                          <option default>Select language</option>
                          <option value="en">English</option>
                          <option value="hi">Hindi</option>
                        </select>
                      </div>
                      {/* <div className="form-group">
                        <label className="control-label">Query</label>
                        <textarea
                          
                          className="form-control"
                          rows="5"
                          placeholder="Enter your query"
                          onChange={(e) => setComments(e.target.value)}
                          value={Comments}
                        />
                      </div>*/}
                      {isCouponGiven && <div className="form-group">
                        <label className="control-label">Add a Discount Coupon (optional)</label>

                        <div className="mt-3">
                          <input type="text" name onChange={(e) => setCoupon(e.target.value)} value={coupon} id="voucher" className="form-control fw-bold mb-0" placeholder="Enter coupon code" />
                          {isCouponApplied && <small className={`text-${isCouponApplied == 1 ? 'success' : 'danger'} mt-3`}>{isCouponApplied == 1 ? `Hooray!🎉 You Saved 100% Amount` : 'Invalid Coupon!😵 Try Again'}</small>}
                        </div>
                        <button type="button" className="btn btn-primary btn-sm mt-3" onClick={validateCoupon}>Apply</button>
                      </div>}
                    </form>
                  </div>
                </div>

                <div className="card-footer">
                  <button
                    type="button"
                    className="btn  btn-block "
                    style={{
                      backgroundColor: '#e9963e',
                      color: '#fff',
                      fontSize: 25,
                    }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Show Kundali
                  </button>
                </div>
              </div>
            </div>
          </div> : <div className="container d-flex align-items-center justify-content-center my-5">
            <div>
              <Spinner className="d-block mx-auto mb-2" />
              <h6>Please Wait!<br />Kundali is Creating</h6>

            </div>
          </div>}

        </div>
        <div className="container-fluid desc ">
          <div className="card im">
            <div className="card-body">
              <p>
                Pujya Pandit G Kundali | Janam Kundali Online - जन्म कुंडली
                Janam Kundli also known as Janampatri or Birth chart or Kundali
                Chart or Natal Chart, depicts an individual’s life based on the
                12 houses. Kundali, Vedic charts, graphical representation of
                positions of houses and planets are prepared based on the date,
                time and place of birth of an individual.

              </p>

            </div>
          </div>
        </div>

      </WrapContainer>
    </>
  );
};

export default Kundali;
